import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

const EmbeddedForm = () => {
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState("");
	const [isComplete, setIsComplete] = useState(false);
	const [discountCode, setDiscountCode] = useState(null);
	console.log(discountCode);

	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let data = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData;
		} else if (packageType === "Economic") {
			return state.econData;
		} else {
			return state.demandData;
		}
	});

	let orderID = data.orderID;

	useEffect(() => {
		fetch(
			`${
				process.env.REACT_APP_STAGE === "production"
					? process.env.REACT_APP_SERVER_URL_PROD
					: process.env.REACT_APP_SERVER_URL_DEV
			}/getOrdersTableByID?orderID=${orderID}`
		)
			.then((res) => res.json())
			.then((data) => {
				if (data) {
					setDiscountCode(JSON.parse(data[0].discountCode));
				} else {
					console.error("unexpected data format: ", data);
				}
			});
	}, []);

	useEffect(() => {
		fetch(
			`${
				process.env.REACT_APP_STAGE === "production"
					? process.env.REACT_APP_SERVER_URL_PROD
					: process.env.REACT_APP_SERVER_URL_DEV
			}/stripeConfig`
		).then(async (res) => {
			const { publishableKey } = await res.json();
			setStripePromise(loadStripe(publishableKey));
			// console.log(publishableKey);
		});
	}, []);

	useEffect(() => {
		fetch(
			`${
				process.env.REACT_APP_STAGE === "production"
					? process.env.REACT_APP_SERVER_URL_PROD
					: process.env.REACT_APP_SERVER_URL_DEV
			}/create-checkout-session`,
			{
				method: "POST",
				mode: "cors",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					discountCode: discountCode ? discountCode.value : discountCode,
					packageType: packageType,
					packageLevel: packageLevel,
				}),
			}
		)
			.then((res) => res.json())
			.then((data) => {
				setClientSecret(data.clientSecret);
			});
	}, [discountCode]);

	const handleComplete = () => setIsComplete(true);

	const options = {
		clientSecret: clientSecret,
		onComplete: handleComplete,
	};

	return (
		<div id="checkout">
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</div>
	);
};

export default EmbeddedForm;
