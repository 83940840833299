import Home from "../pages/Home";
import Performance from "../pages/Performance";
import Economic from "../pages/Economic";
import Demand from "../pages/Demand";

export const Pages = {
    Home,
    Performance,
    Economic,
    Demand,
};
