import SelectOEM from "../components/commonPerfEcon/SelectOEM";
import SelectOEM2 from "../components/commonPerfEcon/SelectOEM2";
import AircraftEngine from "../components/commonPerfEcon/AircraftEngine";
import AircraftEngine2 from "../components/commonPerfEcon/AircraftEngine2";
import SelectedAircraft from "../components/commonPerfEcon/SelectedAircraft";
import RoutesAndDistances from "../components/commonPerfEcon/RoutesAndDistances";
import RoutesAndDistances2 from "../components/commonPerfEcon/RoutesAndDistances2";
import CustomConfigWeights from "../components/commonPerfEcon/CustomConfigWeights";
import CustomConfigWeights2 from "../components/commonPerfEcon/CustomConfigWeights2";
import EnvironmentalConditions from "../components/commonPerfEcon/EnvironmentalConditions";
import AirportConditions from "../components/commonPerfEcon/AirportConditions";
import CustomAirportConditions from "../components/commonPerfEcon/CustomAirportConditions";
import CustomAirportConditions2 from "../components/commonPerfEcon/CustomAirportConditions2";
import CustomAirportConditions3 from "../components/commonPerfEcon/CustomAirportConditions3";
import CostAssumptions from "../components/economic/CostAssumptions";
import FixedOperatingCost from "../components/economic/FixedOperatingCost";
import FixedOperatingCost2 from "../components/economic/FixedOperatingCost2";
import LeaseRate from "../components/economic/LeaseRate";
import LeaseRate2 from "../components/economic/LeaseRate2";
import FinanceAssumptions from "../components/economic/FinanceAssumptions";
import FinanceAssumptions2 from "../components/economic/FinanceAssumptions2";
import CashOperatingCosts from "../components/economic/CashOperatingCosts";
import CrewLoading from "../components/economic/CrewLoading";
import CrewLoading2 from "../components/economic/CrewLoading2";
import AirportAndNavigationCharges from "../components/economic/AirportAndNavigationCharges";
import CustomAirportNavigationCharges from "../components/economic/CustomAirportNavigationCharges";
import CustomAirportNavigationCharges2 from "../components/economic/CustomAirportNavigationCharges2";
import InputReview from "../components/commonAll/InputReview";
import InputReviewEcon from "../components/economic/InputReviewEcon";
import LogoUpload from "../components/commonAll/LogoUpload";
import Submission from "../components/commonAll/Submission";
import Thanks from "../components/commonAll/Thanks";

export const EconomicForm = {
    SelectOEM,
    SelectOEM2,
    AircraftEngine,
    AircraftEngine2,
    SelectedAircraft,
    RoutesAndDistances,
    RoutesAndDistances2,
    CustomConfigWeights,
    CustomConfigWeights2,
    EnvironmentalConditions,
    AirportConditions,
    CustomAirportConditions,
    CustomAirportConditions2,
    CustomAirportConditions3,
    CostAssumptions,
    FixedOperatingCost,
    FixedOperatingCost2,
    LeaseRate,
    LeaseRate2,
    FinanceAssumptions,
    FinanceAssumptions2,
    CashOperatingCosts,
    CrewLoading,
    CrewLoading2,
    AirportAndNavigationCharges,
    CustomAirportNavigationCharges,
    CustomAirportNavigationCharges2,
    InputReview,
    InputReviewEcon,
    LogoUpload,
    Submission,
    Thanks,
};
