import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/utility.scss";
import { List, AutoSizer } from "react-virtualized";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

/*

    ------- DESCRIPTION -------
    default buttons, used in the package selection component and the cancel button

    ------- PROPS LIST -------
    - text                string          examples: "Basic" or "Cancel"
    - marginBool          boolean         x-axis margin
    - colorRed            boolean         hover:backgroundColor
    - link                string          navigate to                          
    - onClickCommand      function        function executed on click           

    ------- EXAMPLE -------
    <UtilityClass.Button
        text="Basic"
        marginBool={true}
        colorRed={false}
        link="Performance"
        onClickCommand={() => dispatch(chosePerformance())}
    ></UtilityClass.Button>

*/
const Button = ({ text = "", noLink, link, onClickCommand, width, icon }) => {
	return (
		// <button
		//     type="button"
		//     className='items-center text-sm font-medium text-center rounded-sm border transition-all duration-200 custom-back-button'
		//     // className={`select-none text-black hover:text-white border border-black ${
		//     //     colorRed
		//     //         ? "hover:bg-red-800 hover:border-red-800"
		//     //         : "hover:bg-black"
		//     // } font-medium rounded-lg text-sm text-center ${
		//     //     marginBool ? "mx-20" : ""
		//     // } my-1 shadow-lg transition-all duration-300 hover:-translate-y-0.5`}
		//     onClick={onClickCommand}
		// >
		//     {link ? (
		//         <Link to={link} className="block px-5 py-2.5">
		//         {text}
		//         </Link>
		//     ) : (
		//         <div className="block px-5 py-2.5">
		//             {text}
		//         </div>
		//     )}

		// </button>

		<button
			type="button"
			className={`w-${
				width ? width : "fit"
			} items-center text-sm text-white font-medium text-center rounded-sm border transition-all duration-200 custom-next-button`}
			onClick={onClickCommand}
		>
			<div className="flex px-2.5 py-2.5 justify-between align-middle">
				<div className={`text && "pr-2"`}>{text}</div>
				{icon === "refresh" ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						stroke="currentColor"
						className="w-4 h-4 fill-none my-auto stroke-2"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
						/>
					</svg>
				) : icon === "cancel" ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						stroke="currentColor"
						className="w-4 h-4 my-auto stroke-2 fill-none"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				) : icon === "back" ? (
					<svg
						className="w-4 h-4 fill-current my-auto rotate-180"
						style={{
							color: "white",
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
					</svg>
				) : (
					<svg
						className="w-4 h-4 fill-current my-auto"
						style={{
							color: "white",
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
					</svg>
				)}
			</div>
		</button>
	);
};

/*

    ------- DESCRIPTION -------
    displayed on the first page in the perf and econ packages, user chooses which OEM they want (radio buttons in squares displayed as a grid)

    ------- PROPS LIST -------
    - value            function                  pass the state to the parent component (to save the user choice)
    - title            string                    title on top of all the choices
    - OEM              string list               text displayed in the squares
    - link             string                    navigate to                                                           

    ------- EXAMPLE -------
    const [oem, setOEM] = useState();            // to save the choice in a state in the parent component
    const chooseOEM = (type) => {                // function called in the prop value
        setOEM(type);
    };
    <UtilityClass.OEMSelection
        title="Select OEM"
        OEM={["Airbus", "Boeing", "MHI RJ"]}
        link={""}
        value={chooseOEM}
    ></UtilityClass.OEMSelection>

*/
const OEMSelection = ({
	title = "Default",
	OEM = ["Default", "Default"],
	link,
	defaultValue,
	value,
}) => {
	const [radioClicked, setRadioClicked] = useState(defaultValue);
	return (
		<div className="select-none flex-col justify-center items-center w-auto h-auto mt-10">
			<h1 className="text-2xl font-semibold text-black text-center">{title}</h1>
			<div className="grid lg:grid-cols-3 md:grid-cols-3 my-5 gap-2 lg:max-w-2xl lg:mx-auto md:w-10/12 w-6/12 mx-auto">
				{OEM.map((name, index) => {
					return (
						<button
							key={`OEM-Select-${name}`}
							className="bg-white border rounded h-32 custom-OEM-selection-button"
							style={{ borderColor: "#A50005" }}
							onClick={() => {
								setRadioClicked(name);
								value(name);
							}}
						>
							<Link to={link} className="block p-4">
								<h1 className="text-xl font-semibold text-black">{name}</h1>
								<input
									id={index}
									type="radio"
									value="radio"
									name="list-radio"
									className="w-auto mt-10 justify-center w-5 h-5 cursor-pointer appearance-none custom-OEM-selection-radio"
									style={{
										backgroundColor: `${
											radioClicked === name ? "#a50005" : "#ffffff"
										}`,
										borderRadius: "50%",
										border: "0.2rem solid #ffffff",
										boxShadow: `0 0 0 1px ${
											radioClicked === name ? "#a50005" : "#E9E9E9"
										}`,
									}}
									onClick={() => {
										setRadioClicked(name);
									}}
								/>
							</Link>
						</button>
					);
				})}
			</div>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    displayed at the bottom of each page, except the home page and the first page in the forms
    used to navigate betwee the pages of the forms

    ------- PROPS LIST -------
    - nextButtonText      string          text displayed inside the button
    - nextLink            string          navigate to
    - backButtonBool      boolean         whether the back button is present
    - backLink            string          navigate to
    - dispatchInputs   
    - validation            boolean         check whether the page needs validation 
    - validationData      boolean         check whether there is data associated with the validation  

    ------- EXAMPLE -------
    <UtilityClass.BackNextButtons
        nextButtonText="NEXT"
        nextLink={"/forms/"}
        backButtonBool={true}
        backLink={"/forms/"}
        dispatchInputs={dispatchFunction}
    ></UtilityClass.BackNextButtons>

*/
const BackNextButtons = ({
	nextButtonText = "",
	nextLink,
	validation = false,
	backButtonBool = true,
	backLink,
	dispatchInputs,
	validationButton = false,
	validationData = false,
	defaultValue = "",
	value,
	hideTextBox = false,
}) => {
	const [effect, setEffect] = useState(false);
	const navigate = useNavigate();

	const [body, bodyody] = useState("");
	const [modal, setModal] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		bodyody(document.getElementsByTagName("BODY")[0]); // Select body tag because of disable scroll when modal is active
		setModal(document.getElementById("modal-validate")); // modal
		// setFooter(document.getElementsByTagName("footer")[0]); // Select footer
	}, [body, modal]);

	const confirmCancelModal = (e) => {
		e.preventDefault();
		modal.style.display = "none"; // Hide modal
		body.style.overflow = "auto"; // Active scroll on body
		// footer.style.display = "flex"; // Show footer
	};

	const handleBack = () => {
		try {
			dispatchInputs();
		} catch {}
		navigate(-1);
	};

	const handleForward = () => {
		if (validationData) {
			if (validation[0]) {
				try {
					dispatchInputs();
				} catch {}
				navigate(nextLink);
			} else {
				if (validation[1].length === 0) {
					modal.style.display = "flex"; // Show modal
					body.style.overflow = "hidden"; // Disable scroll on body
					setErrorMessage("Please ensure all entered rows are complete.");
					// alert(`Please ensure all entered rows are completed.`);
				} else {
					// alert(
					//     `The following values were detected as invalid IATA codes: ${validation[1]}. \n\nPlease ensure they are in the correct format before continuing.`
					// );
					modal.style.display = "flex"; // Show modal
					body.style.overflow = "hidden"; // Disable scroll on body
					setErrorMessage(
						`The following values were detected as invalid IATA codes: ${validation[1]}. \nPlease ensure they are in the correct format before continuing.`
					);
				}

				setEffect(true);
			}
		} else {
			if (validation) {
				// this is here to catch the typeError inbetween renders
				try {
					dispatchInputs();
				} catch {}
				navigate(nextLink);
			} else {
				if (hideTextBox) {
					setEffect(true);
				} else {
					modal.style.display = "flex"; // Show modal
					body.style.overflow = "hidden"; // Disable scroll on body
					setErrorMessage("Please ensure all inputs have been completed.");
				}
			}
		}
	};

	return (
		<>
			{validationButton &&
				(console.log(defaultValue),
				(
					<div
						className={`${effect && "shake"} mt-7 flex justify-center`}
						onAnimationEnd={() => setEffect(false)}
					>
						<UtilityClass.Checkbox
							text="I have validated the accuracy of all assumptions and selections."
							defaultValue={defaultValue}
							value={value}
							validation={true}
							required={true}
						/>
					</div>
				))}

			<div
				className={`select-none ${
					validationButton ? "mt-0" : "mt-7"
				} flex gap-1 justify-center`}
			>
				<div
					id="modal-validate"
					className="hidden items-center justify-center h-screen w-screen fixed top-0 left-0 bg-black/50 cursor-default z-50"
				>
					<div className="bg-white max-w-md px-6 py-4 w-full rounded-md">
						<div className="p-3">
							<div className="select-none whitespace-pre-wrap font-semibold pb-2">
								{errorMessage}
							</div>
						</div>
						<div className="p-3 flex flex-col gap-2">
							<UtilityClass.Button
								text="BACK"
								width={"full"}
								noLink
								icon={"back"}
								colorRed={true}
								marginBool={true}
								onClickCommand={(e) => {
									confirmCancelModal(e);
								}}
							/>
						</div>
					</div>
				</div>

				{backButtonBool && (
					<button
						type="button"
						className="items-center text-sm font-medium text-center rounded-sm border transition-all duration-200 custom-back-button flex px-2.5 py-2.5"
						onClick={handleBack}
					>
						{/* <Link to={backLink} className="flex px-2.5 py-2.5"> */}
						<svg
							className="w-4 h-4 fill-current"
							style={{
								color: "#A50005",
							}}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
						</svg>
						{/* </Link> */}
					</button>
				)}
				<button
					type="button"
					className="w-80 items-center text-sm text-white font-medium text-center rounded-sm border transition-all duration-200 custom-next-button"
					onClick={handleForward}
				>
					<div
						//to={nextLink}
						to={validation ? nextLink : ""}
						className="flex px-2.5 py-2.5 justify-between align-middle"
					>
						{nextButtonText}
						<svg
							className="w-4 h-4 fill-current my-auto"
							style={{
								color: "white",
							}}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
						</svg>
					</div>
				</button>
			</div>
		</>
	);
};

/*

    ------- DESCRIPTION -------
    dropdown used either alone or in a table

    ------- PROPS LIST -------
    - keyIn            string                   when rendered multiple times (in a loop), each needs to have a unique key
    - titleBool        boolean                  whether or not you want a title on top of the dropdown
    - title            string                   title on top of the dropdown menu
    - internalTitle    string                   initial text displayed inside the dropdown box, disabled option
    - options          string list              different options in the dropdown menu
    - defaultVals      string                   used to either give default values at first or to load chosen values
    - disabledBool     boolean                  whether the input is disabled or not
    - showTable        boolean                  whether or not it shows the "DefaultTable" component between the title and the dropdown menu
    - headerList       string list              prop for the "DefaultTable" component, text displayed in the header
    - rows             string 2D list           prop for the "DefaultTable" component, text displayed in the rows of the table
    - width            string                   to have dynamic width depending on the format and page
    - isSelected       boolean                  
    - selectedVal      
    - row              number
    - col              number
    - value            function                 pass the state to the parent component (to save the user choice)

    ------- EXAMPLE -------
    const [acType, setACType] = useState();     // to save the choice in a state in the parent component
    const chooseAircraft = (type) => {          // function called in the prop value
        setACType(type);
    };
    <UtilityClass.DropdownMenu
        titleBool={true}
        title="Select aircraft, engine combination to see default characteristics"
        internalTitle="Please select and aircraft type"
        options={["A318-100", "A319-100", "A320-100", "A321-100"]}
        showTable={acType !== undefined}
        headerList={[
            "ACType",
            "Engine",
            "Seats",
            "MTOW (KG/Lbs)",
            "MLW (KG/Lbs)",
            "OWE (KG/Lbs)",
            "Max Payload (KG/Lbs)",
        ]}
        rows={[
            [
                "A318-100",
                "CFM56-5",
                "110",
                "68000 / 149915",
                "57500 / 126766",
                "39700 / 87524",
                "14800 / 32629",
                ,
            ]]}
        width="auto"
        value={chooseAircraft}
    ></UtilityClass.DropdownMenu>

*/
const DropdownMenu = ({
	keyIn = "",
	titleBool = false,
	title = "",
	internalTitle = "",
	options = ["", ""],
	defaultVals = "",
	disabledBool = false,
	showTable = false,
	headerList = ["", ""],
	rows = [["", ""]],
	width = 48,
	isSelected = false,
	selectedVal = undefined,
	row,
	col,
	value,
}) => {
	const handleChange = (e) => {
		value(e.target.value, [row, col]);
	};
	return (
		<div
			id={keyIn}
			key={keyIn}
			data-type={"select"}
			className={`flex flex-col items-center select-none mx-0.5 ${keyIn}`}
		>
			{titleBool && (
				<div className="text-center mt-4 mb-6 ">
					<h1 className="text-lg font-medium">{title}</h1>
				</div>
			)}
			{showTable && (
				<motion.div
					key={rows}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{
						type: "spring",
						stiffness: 100,
						duration: 0.1,
						ease: "ease",
					}}
				>
					<UtilityClass.DefaultTable headerList={headerList} rows={rows} />
				</motion.div>
			)}
			<select
				data-variable={keyIn}
				className={`custom-dropdown cursor-pointer outline-none w-${width} bg-${
					disabledBool[1] ? "gray-100" : "white"
				} py-1 px-2 pr-8`}
				onChange={handleChange}
				disabled={disabledBool[0]}
				defaultValue={
					isSelected
						? selectedVal
						: typeof defaultVals === "string"
						? defaultVals
						: `${internalTitle}`
				}
			>
				<option
					key="dropdown-value-disabled"
					value={`${internalTitle}`}
					disabled
				>
					{internalTitle}
				</option>
				{options.map((option, index) =>
					isSelected ? (
						<>
							<option
								key={`dropdown-value-${selectedVal}`}
								defaultValue={selectedVal}
							>
								{option}
							</option>
						</>
					) : (
						<option key={`dropdown-value-${option}`} defaultValue={option}>
							{option}
						</option>
					)
				)}
			</select>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    radio buttons, choices that the user will need to choose one from

    ------- PROPS LIST -------
    - keyIn            string             key of component (used for loops, or when multiple ones are used on same page)
    - title            string             title on top of the radio buttons
    - text             string list        different options, text next to the radio buttons
    - subTextBool      boolean            whether or not there should be subtext under the text (color: gray)
    - subText          string list        subtext list corresponding to the "text" prop above
    - defaultValue     string             default value for the input
    - row              boolean            display the radio buttons as a list (on top of each other), or next to each other (in a single row)
    - width            string             used to specify width of component
    - margin           string             used to specify width of component
    - value            function           pass the state to the parent component (to save the user choice)

    ------- EXAMPLE -------
    const [custom, setCustom] = useState();   // to save the choice in a state in the parent component
    const chooseCustom = (type) => {          // function called in the prop value
        setCustom(type);
    };
    <UtilityClass.RadioButtons
        title="Would you like to provide custom values?"
        text={["No", "Yes"]}
        subTextBool={true}
        subText={["Use default values.", "I will provide custom values."]}
        row={true}
        value={chooseCustom}
    ></UtilityClass.RadioButtons>

*/
const RadioButtons = ({
	keyIn = "",
	title = "",
	text = ["", ""],
	subTextBool = false,
	subText = "",
	defaultValue = "",
	row = false,
	width = "full",
	margin = "auto",
	value,
	payload = false,
	horizontal = false,
}) => {
	const [radioClicked, setRadioClicked] = useState(defaultValue);
	useEffect(() => value(radioClicked));
	return (
		<div
			className={`justify-center select-none text-center my-4 px-10 sm:mx-auto sm:px-4 md:mx-auto md:px-10 lg:mx-auto lg:px-16`}
		>
			<h3 className="mb-1 font-semibold text-lg text-black text-center">
				{title}
			</h3>
			<div className="flex justify-center">
				<div
					className={`${horizontal ? "w-16" : payload ? "w-full" : "w-1/2"}`}
				>
					<ul
						className={`mx-auto text-sm font-medium text-black ${
							row ? "flex justify-center" : "inline-block"
						}`}
					>
						{text.map((text, i) => {
							return (
								<li
									key={i}
									className={`my-2 ${row ? `w-auto mx-${margin}` : ""}`}
								>
									<div
										className="flex items-center px-3"
										onClick={() => setRadioClicked(text)}
									>
										<input
											id={`${keyIn}-${i}`}
											type="radio"
											value="radio"
											name="list-radio"
											className="w-5 h-5 cursor-pointer appearance-none"
											style={{
												backgroundColor: `${
													radioClicked === text ? "#a50005" : "#ffffff"
												}`,
												borderRadius: "50%",
												border: "0.2rem solid #ffffff",
												boxShadow: "0 0 0 1px #a50005",
											}}
										/>
										<div className="w-auto pl-2 text-base text-justify cursor-pointer custom-hover-color">
											<label
												htmlFor={`${keyIn}-${i}`}
												className="font-normal cursor-pointer duration-300 transition-all"
												style={{
													color: `${
														radioClicked === text ? "#a50005" : "black"
													}`,
												}}
											>
												{text}
											</label>
											{subTextBool && (
												<p
													className="text-xs font-normal cursor-pointer duration-300 transition-all"
													style={{
														color: `${
															radioClicked === text ? "#a50005" : "lightgray"
														}`,
													}}
												>
													{subText[i]}
												</p>
											)}
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    text to be customised (color, size, weight, margin)

    ------- PROPS LIST -------
    - content             string          text, if there should be line breaks --> add "\n" where the break should be
    - textSize            string          options: small, large, subtitle, title (default** will be the base size)
    - fontWeight          string          options: thin, medium, semibold, bold (default** will be the normal weight)
    - color               string          options: red, gray (default** will black)
    - bottomMargin        boolean         whether or not there should be a larger bottom margin
    
    **default: not filling in that prop

    ------- EXAMPLE -------
    <UtilityClass.CustomText
        textSize="small"
        fontWeight="medium"
        bottomMargin={true}
        color="grey"
        content={
        "For each airport, you have options to:\n- select globally applied assumptions\nand\n- provide custom overrides for select locations"
        }
    ></UtilityClass.CustomText>

*/
const CustomText = ({
	content = "",
	textSize = "",
	fontWeight = "",
	color = "",
	bottomMargin = false,
	alignment = "center",
	noMargin = false,
	topMargin = false,
	border = false,
	borderLeft = false,
	borderRight = false,
	borderTop = false,
	borderBottom = false,
}) => {
	return (
		<div
			className={`${
				(border && "border-color") ||
				(borderLeft && "border-left") ||
				(borderRight && "border-right") ||
				(borderTop && "border-Top") ||
				(borderBottom && "border-bottom")
			} select-none text-${alignment} w-auto ${noMargin ? "my-0" : "my-4"} 
            ${topMargin ? "mt-4" : ""}
            ${bottomMargin ? "mb-10" : ""} ${
				textSize === "small"
					? "text-sm > *"
					: textSize === "large"
					? "text-lg > *"
					: textSize === "subtitle"
					? "text-2xl > *"
					: textSize === "title"
					? "text-3xl > *"
					: "text-base > *"
			} ${
				fontWeight === "thin"
					? "font-light > *"
					: fontWeight === "medium"
					? "font-medium > *"
					: fontWeight === "semibold"
					? "font-semibold > *"
					: fontWeight === "bold"
					? "font-bold > *"
					: "font-normal > *"
			}`}
			style={{
				color: `${
					color === "red" ? "#A50005" : color === "grey" ? "grey" : "black"
				}`,
			}}
		>
			{content.split("\n").map((str, i) => (
				<p key={i}>{str}</p>
			))}
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    table with red header and no inputs, just to display

    ------- PROPS LIST -------
    - headerList       string list        text displayed in the header
    - row              string list        text displayed in the row of the table

    ------- EXAMPLE -------
    <UtilityClass.DefaultTable
        headerList={[
        "ACType",
        "Engine",
        "Seats",
        "MTOW (KG/Lbs)",
        "MLW (KG/Lbs)",
        "OWE (KG/Lbs)",
        "Max Payload (KG/Lbs)",
        ]}
        row={[
        "A318-100",
        "CFM56-5",
        "110",
        "68000 / 149915",
        "57500 / 126766",
        "39700 / 87524",
        "14800 / 32629",
        ]}
    ></UtilityClass.DefaultTable>

*/
const DefaultTable = ({ headerList = ["", ""], rows = [["", ""]] }) => {
	return (
		<div className="select-none w-auto justify-center flex mx-5 text-lg mb-5">
			<table>
				<thead>
					<tr>
						{headerList.map((col, i) => {
							return (
								<th
									key={i}
									className="border p-4 text-center text-white font-semibold"
									style={{ backgroundColor: "#A50005" }}
								>
									{col.split("\n").map((str, i) => (
										<p key={i}>{str}</p>
									))}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, i) => {
						return (
							<tr key={i}>
								{row.length === 7 && row[6][0] === "checked"
									? row.slice(0, -1).map((content, j) => {
											return (
												<td
													key={j}
													style={{
														color: "rgb(165, 10, 0)",
													}}
													className="border p-4 text-center text-slate-800 font-normal"
												>
													{content}
												</td>
											);
									  })
									: row.length === 7 && headerList[0] === "Airport"
									? row.slice(0, -1).map((content, j) => {
											return (
												<td
													key={j}
													className="border p-4 text-center font-normal"
												>
													{content}
												</td>
											);
									  })
									: row.map((content, j) => {
											return (
												<td
													key={j}
													className="border p-4 text-center font-normal"
												>
													{content}
												</td>
											);
									  })}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    input box, with options to add left square and/or right square with text and label

    ------- PROPS LIST -------
    - autoComplete              array              wether there is autocomplete and the data for it 
    - keyIn                     string             when rendered multiple times (in a loop), each needs to have a unique key
    - idFor                     string             when rendered multiple times (in a loop), each needs to have a unique id (label htmlFor attribute and id of input)
    - labelBool                 boolean            whether or not there should be a label on top of the input box
    - label                     string             label on top of the input box
    - labelBottomMarginBool     boolean            whether the label has some bottom margin or not
    - labelGray                 boolean            whether the label text color is gray
    - labelRed                  boolean            whehter the label text color is red
    - redTextInLabel            boolean            whether the text in the label is red color after a line break
    - defaultValue              string             default value for the input
    - placeholder               string             placeholder text inside the input box
    - disabledBool              boolean            whether or not the input is disabled
    - rightMarginBool           boolean            whether this component has some right margin or not
    - leftSquareLabelBool       boolean            whether or not there should be a text box to the left of the input box
    - leftSquareLabelText       string             text inside the left square text box
    - rightSquareLabelBool      boolean            whether or not there should be a text box to the right of the input box
    - rightSquareLabelText      string             text inside the right square text box
    - width                     string             to specify width of component
    - capitalize                boolean            whether to auto capitalize input or not
    - value                     function           pass the state to the parent component (to save the user choice)
    - regex                     string (regex)     pass the regex string so that it can be matched inside setInputField or wherever needed

    ------- EXAMPLE -------
    const [newInput, setNewInput] = useState();
    const changeInput = (i) => {
        setNewInput(i);
    };
    <UtilityClass.DefaultInput
        defaultValue="100"
        labelBool={true}
        label="Seats"
        value={changeInput}
        disabledBool={true}
        leftSquareLabelBool={true}
        leftSquareLabelText={"USD"}
        placeholder={"Pilot Crew Cost"}
        rightSquareLabelBool={true}
        rightSquareLabelText={"per block hour"}
    ></UtilityClass.DefaultInput>

*/
const DefaultInput = ({
	rounded = false,
	isChanged = false,
	setIsChanged,
	combinedDropdown = [false, []],
	autoComplete = false,
	keyIn = "",
	idFor = "",
	labelBool = false,
	label = "",
	labelBottomMarginBool = false,
	labelGray = false,
	labelRed = false,
	redTextInLabel = false,
	defaultValue = "",
	placeholder = "",
	disabledBool = false,
	rightMarginBool = false,
	leftSquareLabelBool = false,
	leftSquareLabelText = "",
	rightSquareLabelBool = false,
	rightSquareLabelText = "",
	width = 48,
	capitalize = false,
	dateTime = false,
	toolTip = false,
	toolTipDesc = "",
	value,
	regex = null,
	isMach,
}) => {
	const [inputValue, setInputValue] = useState(defaultValue);
	const [isHidden, setIsHidden] = useState(true);
	const [filteredValues, setFilteredValues] = useState([]);

	// relevant parts for customconfigweights
	const dataType = useSelector((state) => {
		switch (state.packageType.value) {
			case "Performance":
				return "performanceData";
			case "Economic":
				return "econData";
			case "Demand":
				return "demandData";
			default:
				break;
		}
	});
	const currentLocation = document.location.href.split("/").slice(-1)[0];
	const unit = useSelector((state) =>
		currentLocation === "CustomConfigWeights"
			? state[dataType].config?.unit
			: currentLocation === "CustomConfigWeights2"
			? state[dataType].configTwo?.unit
			: false
	);

	useEffect(() => {
		if (leftSquareLabelText === "M0.") {
			if (isMach && defaultValue && typeof defaultValue === "string") {
				setInputValue(defaultValue);
				// not undefined to prevent other dropdowns from breaking
			} else if (isMach && defaultValue && typeof defaultValue === "object") {
				setInputValue(defaultValue[0]);
			} else if (isMach === false) {
				setInputValue("");
			}
		}
	}, [isMach]);

	useEffect(() => {
		value(inputValue);
	}, [inputValue, value]);

	useEffect(() => {
		if (unit) {
			setInputValue(defaultValue);
		}
	}, [unit, defaultValue]);

	useEffect(() => {
		const fetchAirports = async () => {
			if (inputValue.length !== 0 && autoComplete) {
				await fetch(
					`${
						process.env.REACT_APP_STAGE == "production"
							? process.env.REACT_APP_SERVER_URL_PROD
							: process.env.REACT_APP_SERVER_URL_DEV
					}/getAirports?location=${inputValue}`
				)
					.then((response) => response.json())
					.then((json) => {
						setFilteredValues(json);
						setIsHidden(false);
					});
			}
		};

		if (autoComplete) {
			if (filteredValues.length === 0 && inputValue.trim().length === 3) {
				setIsHidden(true);
			} else if (inputValue.length !== 0 && typeof inputValue !== "object") {
				fetchAirports();
			}
		} else if (combinedDropdown[0]) {
			setIsHidden(true);
		}
	}, [inputValue]);

	useEffect(() => {
		if (autoComplete) {
			setFilteredValues([]);
		} else if (combinedDropdown) {
			setFilteredValues(combinedDropdown[1]);
		}
	}, []);

	const inputElement = document.getElementById(keyIn);
	if (inputElement) {
		document.addEventListener("mousedown", (event) => {
			if (!inputElement.contains(event.target)) {
				setIsHidden(true);
			}
		});
	}

	const handleDefaultValue = (e) => {
		setIsHidden(true);
		setInputValue(e.target.value);
	};

	// for resetting the value of the input when a dropdown changes (e.g. region in cashoperatingcosts)
	useEffect(() => {
		if (combinedDropdown && isChanged && setIsChanged) {
			setInputValue("");
			setIsChanged(false);
		}
	}, [isChanged]);

	const decimalPlaces = () => {
		switch (inputValue.toString().split(".")[0].length) {
			case 1:
				return inputValue.toString().slice(0, 4);
			case 2:
				return inputValue.toString().slice(0, 4);
			case 3:
				return inputValue.toString().slice(0, 6);
			case 4:
				return inputValue.toString().slice(0, 6);
			default:
				return inputValue.toString().slice(0, 4);
		}
	};

	const rowRenderer = ({ index, key, style }) => {
		const handleList = (e) => {
			if (autoComplete) {
				setFilteredValues([]);
			}
			if (autoComplete) {
				setInputValue(e.currentTarget.dataset.id);
			}
		};
		return (
			<div key={key} style={style}>
				<div
					key={key + "inner"}
					onMouseDown={handleList}
					data-id={
						autoComplete
							? filteredValues[index].iata_code
							: filteredValues[index].data
					}
					className="block z-20 h-full w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
				>
					<div className="font-medium whitespace-nowrap text-sm px-2 py-1.5 border-b">
						{autoComplete
							? filteredValues[index].iata_code
							: filteredValues[index].data}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div
			id={keyIn}
			key={keyIn}
			className={`select-none flex flex-col z-10 px-0.5 w-${width} justify-center ${
				rightMarginBool ? "pr-8" : ""
			}`}
		>
			{labelBool && (
				<div className="flex flex-row items-center justify-center">
					<label
						htmlFor={`${idFor}`}
						className={`mb-2 text-lg font-semibold text-center ${
							labelGray ? "text-gray-400" : ""
						} ${labelBottomMarginBool ? "mb-9" : ""}`}
						style={{
							color: `${labelRed ? "#A50005" : "black"}`,
						}}
					>
						{label.split("\n").map((str, i) => (
							<p
								className="whitespace-nowrap"
								key={i}
								style={{
									color: `${i !== 0 && redTextInLabel && "#A50005"}`,
								}}
							>
								{str}
							</p>
						))}
					</label>
					<div className="pb-1 pl-1">
						{toolTip && <UtilityClass.Tooltip description={toolTipDesc} />}
					</div>
				</div>
			)}
			<div className="flex justify-center" key={keyIn} data-type={"input"}>
				{leftSquareLabelBool && (
					<>
						{leftSquareLabelText !== "Date" ? (
							<div
								className={`${
									disabledBool ? "bg-gray-100" : ""
								} text-center p-1 font-semibold border text-gray-400 min-w-max`}
								style={{
									borderColor: "#A50005",
									borderRight: "none",
								}}
							>
								<p>{leftSquareLabelText}</p>
							</div>
						) : (
							<div
								className={`p-2 border`}
								style={{
									borderColor: "#A50005",
									borderRight: "none",
								}}
							>
								<svg
									className="w-4 h-4 fill-current"
									style={{
										color: "#858585",
									}}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
								</svg>
							</div>
						)}
					</>
				)}
				{disabledBool ? (
					<input
						type="text"
						id={`${idFor}`}
						//onBlur={() => setIsHidden(true)}
						className={`bg-gray-100 airport-placeholder border text-black text-base w-${width} py-1 px-2 outline-none`}
						style={{ borderColor: "#A50005" }}
						placeholder={placeholder}
						value={inputValue}
						onChange={(e) => {
							setInputValue(e.target.value);
						}}
						disabled
					/>
				) : leftSquareLabelText === "Date" ? (
					<input
						type="date"
						id={`${idFor}`}
						className="bg-white date-placeholder border text-black text-base w-full py-1 px-2 outline-none"
						style={{ borderColor: "#A50005" }}
						placeholder={placeholder}
						value={inputValue}
						onChange={(e) => {
							setInputValue(e.target.value);
						}}
					/>
				) : (
					<div className="flex flex-col w-full h-full">
						<div className="w-full h-1/2">
							<div className="flex relative justify-end items-center">
								<input
									//onBlur={() => setIsHidden(true)}
									type={dateTime ? "time" : "text"}
									data-variable={keyIn}
									id={`${idFor}`}
									className={
										capitalize
											? `airport-placeholder bg-white uppercase border text-black text-base w-${width} py-1 px-2 outline-none`
											: dateTime
											? `date-placeholder bg-white border text-black text-base w-${width} py-1 px-2 outline-none`
											: `bg-white border text-black text-base w-${width} z-0 relative py-1 px-2 outline-none`
									}
									style={{ borderColor: "#A50005" }}
									placeholder={placeholder}
									value={rounded ? decimalPlaces() : inputValue} // need to add a condition for this
									onChange={(e) => {
										// console.log(regex.test(e.target.value));
										setInputValue(
											regex && !regex.test(e.target.value)
												? inputValue
												: e.target.value
										);
									}}
								/>
								{combinedDropdown[0] && combinedDropdown[1].data && (
									<select
										className={`cursor-pointer outline-none absolute mr-2 w-3`}
										onChange={handleDefaultValue}
										value={
											isChanged
												? combinedDropdown[1].header
												: inputValue === combinedDropdown[1].data[0][0]
												? inputValue
												: combinedDropdown[1].data.find(
														(e) =>
															e[0].toString().trim() ===
															inputValue.toString().trim()
												  )
												? combinedDropdown[1].data.find(
														(e) =>
															e[0].toString().trim() ===
															inputValue.toString().trim()
												  )[0]
												: combinedDropdown[1].header
										}
									>
										<option key="dropdown-value-disabled" disabled>
											{combinedDropdown[1].header}
										</option>
										{combinedDropdown[1].data.map((value, index) => {
											return (
												<option key={value + index} value={value[0]}>
													{rounded
														? `${value[0].toString().slice(0, 4)} ${value[1]}`
														: `${value[0]} ${value[1]}`}
												</option>
											);
										})}
									</select>
								)}
							</div>
						</div>
						<div className="h-1/2 w-full">
							{combinedDropdown[0] && !isHidden && (
								<div className="bg-black w-full h-full relative z-40">
									<AutoSizer disableHeight>
										{({ width }) => (
											<List
												ref="List"
												height={160}
												overscanRowCount={5}
												rowCount={filteredValues.length}
												rowHeight={32}
												rowRenderer={rowRenderer}
												style={{
													position: "absolute",
													background: "white",
													borderWidth: "1px",
													marginTop: "3px",
													paddingBottom: "1px",
													borderColor: "black",
													height: "auto",
													maxHeight: "150px",
													width: "100%",
												}}
												width={width}
											/>
										)}
									</AutoSizer>
								</div>
							)}
						</div>
					</div>
				)}
				{rightSquareLabelBool && (
					<div
						className={`${
							disabledBool ? " airport-placeholder bg-gray-100" : ""
						} text-center p-1 font-semibold border text-gray-400 min-w-max`}
						style={{ borderColor: "#A50005", borderLeft: "none" }}
					>
						<p>{rightSquareLabelText}</p>
					</div>
				)}
			</div>
			{autoComplete && filteredValues.length > 0 && !isHidden && (
				<div className="relative z-40">
					<AutoSizer disableHeight>
						{({ width }) => (
							<List
								ref="List"
								height={160}
								overscanRowCount={5}
								rowCount={filteredValues.length}
								rowHeight={32}
								rowRenderer={rowRenderer}
								style={{
									position: "absolute",
									background: "white",
									borderWidth: "1px",
									marginTop: "3px",
									paddingBottom: "1px",
									borderColor: "black",
									height: "auto",
									maxHeight: "150px",
									width: "100%",
								}}
								width={width}
							/>
						)}
					</AutoSizer>
				</div>
			)}
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    table containing rows and columns of input boxes or dropdowns

    ------- PROPS LIST -------
    - autoComplete                        array                     whether there is an autocomplete dropdown and the data for it 
    - captionBool                         boolean                   whether there is a caption with the label prop in it
    - label                               string                    label on top of the table
    - doubleFlexCaptionBool               boolean                   whether there is a double column in the label text (row flexed)            
    - doubleFlexCaptionLarge              boolean                   whether or not the caption has a large width
    - doubleFlexCaptionFirstText          string                    text of the first double column flexed caption
    - doubleFlexCaptionSecondText         string                    text of the second double column flexed caption
    - headerLabelsBool                    boolean                   prop for the "DefaultInput" component, whether there is a label for them in the first row
    - conditionalLabelsList               string list               column label content
    - redTextInLabel                      boolean                   whether or not there is a need for the redTextInLabelList prop (styling purposes)
    - redTextInLabelList                  boolean list              whether each column label has the redTextInLabel option of the DefaultInput component
    - disabledBoolList                    boolean list              whether or not each DefaultInput component is disabled or not
    - rightMarginBoolList                 boolean list              whether each DefaultInput component has some right margin or not
    - rowNum                              number                    number of rows in the table
    - colNum                              number                    number of columns in the table
    - indexBool                           boolean                   whether or not there should be an index at the begining of each row
    - indexBlack                          boolean                   whether the index color is gray or black
    - showIndex                           boolean list              whether the ith row shows the index or not
    - showIndexSkipped                    boolean                   whether there were skipped indexes and we need to show every 3 rows their indexes
    - leftRedTextBool                     boolean                   whether there is some red text at the left of the row, after
    - leftRedTextList                     string list               red text at the left of each row
    - rowBottomMarginBool                 boolean                   whether each row has some bottom margin
    - labelGray                           boolean                   prop for the "DefaultInput" component, whether the label text color is gray
    - labelRed                            boolean                   prop for the "DefaultInput" component, whether the label text color is red
    - labelBottomMarginBoolList           boolean                   prop for the "DefaultInput" component, whether the label has some bottom margin or not
    - checkboxLabelBottomMarginBool       boolean                   whether the checkboxes' label have a large margin or not
    - checkboxesBottomMarginBool          boolean                   whether the checkboxes have a large margin or not
    - checkboxSmallerBottomMarginBool     boolean                   whether the checkboxes have a small margin or not
    - checkboxText                        string list               text next to each checkbox
    - rowConditionalMarginBoolList        boolean list              whether each row has some margin after it 
    - firstRowBottom                      boolean                   should be true for checkboxes
    - addIndex                            list [boolean, number]    boolean: whether to add or not / number: how much to add
    - dropdown                            boolean                   whether the inputs are dropdowns or the default input box
    - oneColDropdown                      boolean                   whether the table has one column of dropdowns while the rest are inputs
    - indexOfDropdownCol                  string (number)           which column has dropdowns
    - stringBeforeIndexBool               boolean                   whether or not the indexes are preceded by a string
    - stringBeforeIndex                   string                    string preceding the indexes
    - indexCaptionBool                    boolean                   whether the index column has a caption or not
    - indexCaptionText                    string                    index column caption text
    - checkboxes                          boolean                   whether the input are checkboxes or the default input box
    - options                             string list               prop for the "DropdownMenu" component, different options in the dropdown menu
    - internalTitle                       string list               prop for the "DropdownMenu" component, initial text displayed inside the dropdown box
    - leftSquareLabelBool                 boolean list              whether or not there should be a text box to the left of the input box
    - leftSquareLabelText                 string list               text inside the left square text box
    - rightSquareLabelBool                boolean list              whether or not there should be a text box to the right of the input box
    - rightSquareLabelText                string list               text inside the right square text box             
    - placeholder                         string list               prop for the "DefaultInput" component, placeholder text inside the input box
    - includeDefaults                     boolean                   whether to use default values (user inputs saved in redux) or not
    - defaultVals                         list                      default values from redux (user inputs)
    - width                               string                    to set width of components in table
    - toolTip                             boolean                   whether the input has a tooltip 
    - toolTipDesc                         string                    the text displayed within the tooltip
    - capitalize                          boolean                   auto capitalize user inputs
    - value                               function                  pass the state to the parent component (to save the user choice)
    - regex                               string (regex)            pass the regex string so that it can be matched inside setInputField or wherever needed
    - multiRegex                          string (regex)            pass the regex strings through an array so that it can be matched inside setInputField or wherever needed
    
    ------- EXAMPLE -------
    const [first, setFrist] = useState();
    const firstTable = (type) => {
        setFrist(type);
    };
    const [second, setSecond] = useState();
    const secondTable = (type) => {
        setSecond(type);
    };
    <div className="flex justify-center">
        <UtilityClass.InputTable
        indexBool={true}
        rowNum={5}
        colNum={3}
        label={"IATA Airport Codes"}
        placeholder={["From", "To", "Diversion"]}
        value={firstTable}
        ></UtilityClass.InputTable>
        <UtilityClass.InputTable
        indexBool={false}
        rowNum={5}
        colNum={2}
        label="GCD+X%"
        placeholder={["Route", "Diversion"]}
        dropdown={true}
        options={[
            ["0", "1", "2", "3", "4", "5"],
            ["0", "1", "2", "3", "4", "5"],
        ]}
        internalTitle={["Route", "Diversion"]}
        value={secondTable}
        ></UtilityClass.InputTable>
    </div>

*/
const InputTable = ({
	autoComplete = false,
	autoCompleteCheck = Array(10).fill(true),
	captionBool = false,
	label = "",
	doubleFlexCaptionBool = false,
	doubleFlexCaptionLarge = false,
	doubleFlexCaptionFirstText = "",
	doubleFlexCaptionSecondText = "",
	headerLabelsBool = false,
	conditionalLabelsList = [""],
	redTextInLabel = false,
	redTextInLabelList = [false],
	disabledBoolList = [false],
	rightMarginBoolList = [false],
	rowNum = 0,
	colNum = 0,
	indexBool = false,
	indexBlack = false,
	showIndex = false,
	showIndexSkipped = false,
	leftRedTextBool = false,
	leftRedTextList = [false],
	rowBottomMarginBool = false,
	labelGray = false,
	labelRed = false,
	labelBottomMarginBoolList = [false],
	checkboxLabelBottomMarginBool = [false],
	checkboxesBottomMarginBool = false,
	checkboxSmallerBottomMarginBool = false,
	checkboxText = [""],
	rowConditionalMarginBoolList = [false],
	firstRowBottom = false,
	addIndex = [false, 0],
	dropdown = false,
	oneColDropdown = false,
	indexOfDropdownCol = "0",
	stringBeforeIndexBool = false,
	stringBeforeIndex = "",
	indexCaptionBool = false,
	indexCaptionText = "",
	checkboxes = false,
	options = [""],
	internalTitle = "",
	leftSquareLabelBool = false,
	leftSquareLabelText = "",
	rightSquareLabelBool = false,
	rightSquareLabelText = "",
	placeholder = "",
	includeDefaults = false,
	defaultVals = [],
	width = 48,
	toolTip = false,
	addRoutes = false,
	routes = [],
	toolTipDesc = "",
	capitalize = false,
	isMach = false,
	verticalLabels = "row",
	separateCheckboxes = false,
	dateTime = false,
	value,
	dropdownKey = "",
	regex = null,
	multiRegex = false,
	routesBool,
}) => {
	const defaultDropDownInputs = (j, i) => {
		let selected = false;
		let defaultSelected = "";

		defaultVals.forEach((value, index) => {
			if (value[1][0] === j && value[1][1] === i) {
				selected = true;
				defaultSelected = value[0];
			}
		});

		return (
			<UtilityClass.DropdownMenu
				options={options[i]}
				internalTitle={internalTitle[i]}
				value={value}
				defaultVals={defaultVals}
				row={j}
				col={i}
				disabledBool={disabledBoolList}
				isSelected={selected}
				selectedVal={defaultSelected}
				width={width}
			></UtilityClass.DropdownMenu>
		);
	};
	const _ = require("lodash");
	return (
		<div className="select-none flex justify-center p-2 w-auto">
			<table>
				{checkboxes ? (
					<div
						className={`text-center px-2 text-lg font-semibold text-gray-400 ${
							checkboxLabelBottomMarginBool
								? "mb-9"
								: checkboxSmallerBottomMarginBool
								? "mb-2"
								: "mb-4"
						}`}
					>
						{label.split("\n").map((str, i) => (
							<p
								key={i}
								style={{
									color: `${i !== 0 && redTextInLabel && "#A50005"}`,
								}}
							>
								{str}
							</p>
						))}
					</div>
				) : captionBool ? (
					<>
						{indexCaptionBool ? (
							<div className="flex">
								<p className="text-center text-black ml-5 mr-4 mb-2 text-lg font-semibold">
									{indexCaptionText}
								</p>
								<caption
									className={`text-black whitespace-nowrap text-lg font-semibold text-center mb-2 mx-auto`}
								>
									{label}
									{toolTip && (
										<UtilityClass.Tooltip description={toolTipDesc} />
									)}
								</caption>
							</div>
						) : (
							<caption
								className={`text-black whitespace-nowrap text-lg font-semibold text-center mb-2 ${
									indexBool ? "ml-24" : ""
								}`}
							>
								{label}
								{toolTip && <UtilityClass.Tooltip description={toolTipDesc} />}
							</caption>
						)}
					</>
				) : (
					doubleFlexCaptionBool && (
						<div
							className={`flex text-center text-lg font-semibold w-auto text-gray-400 ${
								doubleFlexCaptionLarge
									? "w-10/12 ml-auto mr-0"
									: "w-3/4 ml-auto mr-0"
							}`}
						>
							<div className="mx-auto">{doubleFlexCaptionFirstText}</div>
							<div className="mx-auto">{doubleFlexCaptionSecondText}</div>
						</div>
					)
				)}
				{_.times(rowNum, (j) => {
					return (
						<tr
							key={j}
							className={`flex ${
								rowBottomMarginBool
									? j !== 0
										? "h-36 mb-3"
										: "mb-28 pb-2.5"
									: checkboxesBottomMarginBool
									? "h-36 mb-3"
									: rowConditionalMarginBoolList[j]
									? "pb-8"
									: checkboxSmallerBottomMarginBool
									? checkboxLabelBottomMarginBool
										? "mb-1"
										: "mb-1"
									: "mb-1"
							}`}
						>
							{indexBool && routesBool && (
								<div className="flex-col whitespace-nowrap h-full w-full border border-transparent flex">
									<td
										className={`border-none h-8 w-[115%] text-center m-auto text-base font-bold ${
											indexBlack ? "text-black" : "text-gray-400"
										} ${
											j === 0 && checkboxSmallerBottomMarginBool
												? ""
												: j === 0 && !checkboxSmallerBottomMarginBool
												? "mb-1"
												: "items-center"
										}`}
									>
										{showIndex[j] ? (
											addRoutes ? (
												addIndex[0] ? (
													<div className="flex justify-between">
														<div>{j + addIndex[1] + ": "}</div>
														<div className="text-gray-600 w-full font-semibold">
															{routes[j]}
														</div>
													</div>
												) : (
													<div className="flex justify-between">
														<div>{j + 1 + ": "}</div>
														<div className="text-gray-600 w-full font-semibold">
															{routes[j]}
														</div>
													</div>
												)
											) : " " ? (
												addIndex[0] ? (
													j + addIndex[1]
												) : showIndexSkipped ? (
													Math.round((j + 1) / 3 + 1)
												) : (
													j + 1
												)
											) : addIndex[0] ? (
												j + addIndex[1]
											) : (
												" "
											)
										) : (
											" "
										)}
									</td>
								</div>
							)}
							{indexBool && !routesBool && (
								<div className="flex-col whitespace-nowrap flex cursor-default mb-[1.5px]">
									{headerLabelsBool && j === 0 && (
										<div className="mb-2 text-lg font-semibold text-center text-gray-400">
											{conditionalLabelsList[0]}
										</div>
									)}
									<td
										className={`border-none text-center m-auto text-base font-semibold ${
											indexBlack ? "text-black" : "text-gray-400"
										} ${
											j === 0 && checkboxSmallerBottomMarginBool
												? ""
												: j === 0 && !checkboxSmallerBottomMarginBool
												? "mb-1"
												: "items-center"
										} ${
											stringBeforeIndexBool
												? "whitespace-nowrap w-auto mx-1"
												: "w-14"
										}`}
									>
										{stringBeforeIndexBool ? stringBeforeIndex : ""}
										{showIndex[j]
											? addRoutes
												? addIndex[0]
													? j + addIndex[1] + ": " + routes[j]
													: j + 1 + ": " + routes[j]
												: " "
												? addIndex[0]
													? j + addIndex[1]
													: showIndexSkipped
													? Math.round((j + 1) / 3 + 1)
													: j + 1
												: addIndex[0]
												? j + addIndex[1]
												: " "
											: " "}
									</td>
								</div>
							)}
							{leftRedTextBool && (
								<div
									className={`text-right font-semibold align-middle mr-2 w-36 ${
										j === 0 ? "mt-16 pt-1.5" : "my-auto"
									}  ${rowConditionalMarginBoolList[j] ? "pb-5 pt-1" : ""}`}
									style={{ color: "#A50005" }}
								>
									{leftRedTextList[j]}
								</div>
							)}
							{_.times(colNum, (i) => {
								return (
									<div
										key={i}
										className={`justify-center mx-auto${
											checkboxes
												? firstRowBottom
													? " align-bottom mt-auto mb-1.5"
													: ""
												: ""
										}`}
									>
										{dropdown ||
										(oneColDropdown && i === Number(indexOfDropdownCol)) ? (
											includeDefaults &&
											// this is for the collection of dropdowns like on page1 perf
											typeof defaultVals === typeof [] ? (
												defaultDropDownInputs(j, i)
											) : (
												<UtilityClass.DropdownMenu
													options={options[i]}
													internalTitle={internalTitle[i]}
													value={value}
													defaultVals={defaultVals}
													disabledBool={disabledBoolList[i]}
													row={j}
													col={i}
													width={width}
													// might shit the bed with this
													keyIn={`${dropdownKey}${j}${i}`}
												></UtilityClass.DropdownMenu>
											)
										) : checkboxes ? (
											<UtilityClass.Checkbox
												text={checkboxText[i]}
												value={value}
												index={`-${j}-${i}`}
												defaultValue={
													j < defaultVals.length &&
													includeDefaults &&
													defaultVals[j][i]
												}
												indexBool={indexBool}
												verticalLabels={verticalLabels}
											></UtilityClass.Checkbox>
										) : includeDefaults ? (
											!separateCheckboxes && (
												<UtilityClass.DefaultInput
													isMach={isMach}
													autoComplete={
														autoCompleteCheck[i] == true
															? autoComplete
															: !autoComplete
													}
													labelBool={headerLabelsBool && j === 0}
													defaultValue={
														typeof defaultVals[0] === "string" ||
														defaultVals[0] === "object"
															? defaultVals[j]
															: defaultVals[j][i]
													}
													labelGray={labelGray}
													labelRed={labelRed}
													labelBottomMarginBool={labelBottomMarginBoolList[i]}
													redTextInLabel={redTextInLabelList[i + 1]}
													label={conditionalLabelsList[i + 1]}
													placeholder={placeholder[i]}
													value={value}
													rightMarginBool={rightMarginBoolList[i]}
													disabledBool={disabledBoolList[i]}
													width={width}
													leftSquareLabelBool={leftSquareLabelBool[i]}
													leftSquareLabelText={leftSquareLabelText[i]}
													rightSquareLabelBool={rightSquareLabelBool[i]}
													rightSquareLabelText={rightSquareLabelText[i]}
													capitalize={capitalize}
													keyIn={`${dropdownKey}${j}${i}`}
													dateTime={dateTime}
													regex={multiRegex ? regex[i] : regex}
												></UtilityClass.DefaultInput>
											)
										) : (
											!separateCheckboxes && (
												<UtilityClass.DefaultInput
													isMach={isMach}
													autoComplete={
														autoCompleteCheck[i] == true
															? autoComplete
															: !autoComplete
													}
													labelBool={headerLabelsBool && j === 0}
													keyIn={`${dropdownKey}${j}${i}`}
													labelGray={labelGray}
													labelRed={labelRed}
													labelBottomMarginBool={labelBottomMarginBoolList[i]}
													defaultValue={
														defaultVals.length > 0 ? defaultVals[j] : ""
													}
													redTextInLabel={redTextInLabelList[i + 1]}
													label={conditionalLabelsList[i + 1]}
													placeholder={placeholder[i]}
													value={value}
													rightMarginBool={rightMarginBoolList[i]}
													disabledBool={disabledBoolList[i]}
													width={width}
													leftSquareLabelBool={leftSquareLabelBool[i]}
													leftSquareLabelText={leftSquareLabelText[i]}
													rightSquareLabelBool={rightSquareLabelBool[i]}
													rightSquareLabelText={rightSquareLabelText[i]}
													capitalize={capitalize}
													dateTime={dateTime}
													regex={regex}
												></UtilityClass.DefaultInput>
											)
										)}
									</div>
								);
							})}
						</tr>
					);
				})}
			</table>
		</div>
	);
};

/*

    Not used?

*/
const InputTableRoutes = ({
	label = "",
	rowNum = 1,
	colNum = 1,
	indexBool = false,
	dropdown = false,
	options = [""],
	internalTitle = [""],
	placeholder = [""],
	width = 48,
	value,
}) => {
	const _ = require("lodash");
	return (
		<div className="select-none flex justify-center px-5 w-auto">
			<table>
				<caption className="text-black text-lg font-semibold text-center mb-4">
					{label}
				</caption>
				{_.times(rowNum, (j) => {
					return (
						<tr key={j} className="flex mb-2">
							{indexBool && (
								<td className="border-none text-gray-400 w-6 text-center align-middle m-auto">
									{j + 11}
								</td>
							)}
							{_.times(colNum, (i) => {
								return (
									<div key={i}>
										{dropdown ? (
											<UtilityClass.DropdownMenu
												options={options[i]}
												internalTitle={internalTitle[i]}
												value={value}
												width={width}
											></UtilityClass.DropdownMenu>
										) : (
											<UtilityClass.DefaultInput
												placeholder={placeholder[i]}
												value={value}
												width={width}
											></UtilityClass.DefaultInput>
										)}
									</div>
								);
							})}
						</tr>
					);
				})}
			</table>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    input of type range, with custom minimum, maximum, step value, and title

    ------- PROPS LIST -------
    - numID               number            must be unique
    - text                string            title on top of the range slider
    - min                 number            minimum value of the range
    - max                 number            maximum value of the range
    - step                number            step taken by the slider between values
    - unit                string            unit of the values
    - defaultValue        number            first value given on page render
    - includeDefaults     boolean           to show user input or not
    - defaultVals         string            saved value in redux (displaye to show input made by user)
    - value               function          pass the state to the parent component (to save the user choice)             

    ------- EXAMPLE -------
    const [value, setValue] = useState();
    const chooseValue = (v) => {
        setValue(v);
    };
    <UtilityClass.RangeSlider
        text="Select passenger load factor"
        min="50"
        max="95"
        step="5"
        defaultValue="75"
        unit="%"
        value={chooseValue}
    ></UtilityClass.RangeSlider>

*/
const RangeSlider = ({
	numID = "",
	text = "",
	min = 0,
	max = 100,
	step = 1,
	unit = "",
	defaultValue = 0,
	includeDefaults = false,
	defaultVals = [],
	value = "",
	noPadding = false,
	maxWidth,
	labelSize,
	toolTip = false,
	toolTipDesc = "",
}) => {
	const [rangeValue, setRangeValue] = useState(
		includeDefaults ? defaultVals : defaultValue
	);
	useEffect(() => {
		value(rangeValue);
		var rangeBullet = document.getElementById(`custom-range-bullet-${numID}`);
		var bulletPosition =
			((rangeBullet.innerHTML.slice(0, -unit.length) - min) / (max - min)) *
			100;
		var filledRange = document.getElementById(`custom-filled-range-${numID}`);
		filledRange.style.width = bulletPosition + "%";

		// if there will be large ranges, need to fix the bulletTick overhead percentage
		var bulletTick = document.getElementById(
			`custom-slider-bullet-index-${numID}`
		);
		bulletTick.style.left =
			bulletPosition === 0
				? bulletPosition + 0.2 + "%"
				: bulletPosition === 100
				? bulletPosition - 0.2 + "%"
				: bulletPosition + "%";
		bulletTick.style.translate = -bulletPosition + "%";

		rangeBullet.style.left = bulletPosition + "%";
		rangeBullet.style.translate = -bulletPosition + "%";
	});

	const updateState = (updated) => {
		setRangeValue(updated);
		setRangeValue((state) => {
			return state;
		});
	};

	return (
		<div
			className={`select-none flex justify-center w-auto my-4 
            ${maxWidth && `max-w-${maxWidth}`} 
            ${
							!noPadding &&
							"px-6 sm:mx-12 sm:px-4 md:px-10 lg:max-w-3xl lg:mx-auto lg:px-16"
						} relative`}
			style={{ flexDirection: "column" }}
		>
			<div className="flex flex-row items-center justify-center">
				<label
					htmlFor={`custom-range-line-${numID}`}
					className="block mb-5 text-lg font-semibold text-black text-center"
				>
					{text}
				</label>
				{toolTip && (
					<div className="pb-5 pl-1">
						{toolTip && <UtilityClass.Tooltip description={toolTipDesc} />}
					</div>
				)}
			</div>
			{includeDefaults ? (
				<input
					id={`custom-range-line-${numID}`}
					className="custom-range w-auto h-3 appearance-none rounded-lg relative"
					type="range"
					min={min}
					max={max}
					defaultValue={defaultVals.length > 0 ? defaultVals : defaultValue}
					step={step}
					onChange={(e) => updateState(e.target.value)}
				/>
			) : (
				<input
					id={`custom-range-line-${numID}`}
					className="custom-range w-auto h-3 appearance-none rounded-lg relative"
					type="range"
					min={min}
					max={max}
					defaultValue={defaultVals.length > 0 ? defaultVals : defaultValue}
					step={step}
					onChange={(e) => updateState(e.target.value)}
				/>
			)}
			<div
				id={`custom-filled-range-${numID}`}
				className={`-mt-3 h-3 rounded-lg relative z-7`}
				style={{ backgroundColor: "#a50005" }}
			></div>
			<div className="mt-1 w-auto flex justify-between text-sm mt-2">
				<span className="pl-1">{`${min}${unit}`}</span>
				<span className="pr-1">{`${max}${unit}`}</span>
			</div>
			<div
				id={`custom-slider-bullet-index-${numID}`}
				className="custom-slider-bullet-index relative rounded-sm h-3 w-3"
			></div>
			<span
				id={`custom-range-bullet-${numID}`}
				className="custom-range-label relative h-full w-20 p-3 rounded text-center text-white"
			>
				{`${rangeValue}${unit}`}
			</span>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    image with title

    ------- PROPS LIST -------
    - content         string          text that will appear as the title above the image
    - src             image           the source image
    - alt             string          alternative text that appears if the image is not available

    ------- EXAMPLE -------
    import SpeedScheduleImage from "../images/speed-schedule.png";
    <UtilityClass.Image
        src={SpeedScheduleImage}
        content="Speed Schedules"
        alt="Speed Schedules Image"
    ></UtilityClass.Image>

*/
const Image = ({ content = "", src = "", alt = "" }) => {
	return (
		<div className="select-none justify-center w-auto mx-auto my-2">
			<UtilityClass.CustomText
				fontWeight="bold"
				textSize="large"
				content={content}
			></UtilityClass.CustomText>
			<img src={src} alt={alt} className="mx-auto w-auto h-auto px-10" />
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    review of all the user choice through the different inputs, displayed at the end, before the submission

    ------- PROPS LIST -------
    - sections        string list, 2D         each section is seperated by the next with a bit of margin

    ------- EXAMPLE -------
    <UtilityClass.InputReview
        sections={[
        [
            ["Aircraft type", "None"],
            ["Seats", "110"],
            ["Weight Unit", "kg"],
        ],
        [
            ["MTOW", "68000"],
            ["MLW", "57500"],
            ["OWE", "39700"],
        ],
        ]}
    ></UtilityClass.InputReview>

*/
const InputReview = ({
	sections = [[""]],
	title = false,
	bottomMargin = true,
}) => {
	return (
		<div className="select-none justify-center pt-2 w-auto">
			{title && (
				<>
					<UtilityClass.CustomText
						textSize="title"
						fontWeight="semibold"
						color="red"
						content="Input Review"
					></UtilityClass.CustomText>
					<UtilityClass.CustomText
						textSize="large"
						fontWeight="semibold"
						content="Please review your inputs before submission"
					></UtilityClass.CustomText>
					<UtilityClass.Separator></UtilityClass.Separator>
				</>
			)}
			{sections.map((section, i) => {
				return (
					<div key={i} className={`${bottomMargin ? "pt-2" : "pt-0"}`}>
						{section.map((row, j) => {
							return (
								<div key={j} className="flex justify-center">
									<p className="w-6/12 text-right font-semibold mr-1">
										{row[0]}
									</p>
									<p className="w-6/12 text-left font-semibold ml-1">
										{row[1]}
									</p>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    used for the user to drop their company logo

    ------- PROPS LIST -------
    X

    ------- EXAMPLE -------
    <UtilityClass.Dropzone></UtilityClass.Dropzone>

*/

const Dropzone = ({
	selectedFile,
	setSelectedFile,
	imageURL,
	setImageURL,
	logoChoice,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = async (e) => {
		setIsLoading(true);
		if (e.target.files && e.target.files.length > 0) {
			setSelectedFile(e.target.files[0].name);
			const url = await fetch(
				`${
					process.env.REACT_APP_STAGE === "production"
						? process.env.REACT_APP_SERVER_URL_PROD_S3
						: process.env.REACT_APP_SERVER_URL_DEV
				}/getURL?type=${e.target.files[0].type.split("/")[1]}`
			).then((res) => res.json());

			await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "multipart/form-data",
				},
				body: e.target.files[0],
			});
			setImageURL(url.split("?")[0]);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		setIsLoading(false);
		setSelectedFile();
		setImageURL();
	};

	return (
		logoChoice === "Yes" && (
			<div className="select-none w-auto my-4 px-6 sm:mx-12 sm:px-4 md:mx-40 md:px-10 lg:max-w-3xl lg:mx-auto lg:px-16 transition-all">
				{selectedFile ? (
					// put the image below here
					<div className="flex gap-3 items-center justify-center">
						<div className="flex flex-row gap-1">
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="semibold"
								content={"Selected image: "}
							/>
							<UtilityClass.CustomText
								textSize="large"
								content={selectedFile}
							/>
						</div>

						<div
							onClick={handleCancel}
							className="hover:fill-red cursor-pointer"
						>
							<svg
								className="hover:fill-red-800 transition-all"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="black"
								viewBox="0 0 256 256"
							>
								<path d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
							</svg>
						</div>
					</div>
				) : (
					<UtilityClass.CustomText
						textSize="large"
						fontWeight="semibold"
						content={"Upload your logo in the Dropbox below"}
					/>
				)}
				<div className="transition">
					{isLoading ? (
						<div
							className="transition flex justify-center items-center"
							role="status"
						>
							<svg
								aria-hidden="true"
								className=" w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-black"
								viewBox="0 0 100 101"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
									fill="currentColor"
								/>
								<path
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
									fill="currentFill"
								/>
							</svg>
							<span className="sr-only">Loading...</span>
						</div>
					) : imageURL ? (
						<div className="flex ">
							<img
								className="object-contain w-full max-h-96"
								src={imageURL}
								alt={"preview"}
							/>
						</div>
					) : (
						<div className="flex items-center justify-center w-full mx-15">
							<label
								htmlFor="dropzone-file"
								className="flex flex-col items-center justify-center w-full h-25 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:border-gray-400 hover:bg-gray-50 hover:-translate-y-1 duration-300"
							>
								<div className="flex flex-col items-center justify-center pt-5 pb-6">
									<>
										<p className="mb-1 text-sm text-gray-900">
											<span className="font-semibold">
												Click to select a file
											</span>
										</p>
										<p className="text-xs text-gray-700">Accepts png, jpeg</p>
									</>
								</div>
								<input
									id="dropzone-file"
									type="file"
									onChange={handleChange}
									className="hidden"
									accept="image/png, image/jpeg"
								/>
							</label>
						</div>
					)}
				</div>
			</div>
		)
	);
};

/*

    ------- DESCRIPTION -------
    separator between different sections in a page

    ------- PROPS LIST -------
    X

    ------- EXAMPLE -------
    <UtilityClass.Separator></UtilityClass.Separator>

*/
const Separator = () => {
	return (
		<div className="select-none my-4 mx-auto lg:w-4/6 md:w-9/12 w-11/12 h-0.5 bg-gray-100"></div>
	);
};

/*

    ------- DESCRIPTION -------
    checkbox, can be required or not

    ------- PROPS LIST -------
    - text              string          displayed next to the checkbox
    - indexBool         boolean         whether to show index or not
    - index             string          index of checkbox (used in loops)
    - defaultValue      boolean         show default value or not (previous input by user, checked or not)
    - required          boolean         whether that input is required or not
    - validation        boolean         to distinguish validation checkbox used after the input review from other checkboxes
    - value             function        pass the state to the parent component (to save the user choice)

    ------- EXAMPLE -------
    const [check, setCheck] = useState();
    const checkedBool = (bool) => {
        setCheck(bool);
    };
    <UtilityClass.Checkbox
        text="I've validated the accuracy of all assumptions and selections."
        value={checkedBool}
        required={true}
    ></UtilityClass.Checkbox>

*/
const Checkbox = ({
	text = "",
	indexBool = false,
	index = "",
	defaultValue = false,
	required = false,
	validation = false,
	verticalLabels = "row",
	value,
}) => {
	const [checkboxClicked, setCheckboxClicked] = useState(defaultValue);

	useEffect(() => {
		value(checkboxClicked);
	}, [defaultValue, value, checkboxClicked]);
	return (
		<div
			className={`select-none relative flex-${verticalLabels} px-2 w-full h-full border border-transparent pt-0.5
         
                flex justify-center items-center align-middle ${
									validation
										? "my-4 px-6 sm:mx-12 sm:px-4 md:mx-40 md:px-10 lg:mx-80 lg:px-16"
										: "m-0"
								}`}
		>
			<div
				className={
					indexBool
						? "relative z-0 mt-1.5 flex justify-center cursor-pointer"
						: "relative z-0 mt-2 flex justify-center cursor-pointer"
				}
			>
				<input
					id={`custom-checkbox${index}`}
					type="checkbox"
					checked={checkboxClicked}
					defaultChecked={defaultValue}
					className="custom-checkbox h-4 w-4 mb-2 appearance-none outline-none relative cursor-pointer"
					style={{
						backgroundColor: `${checkboxClicked ? "#A50005" : "white"}`,
					}}
					onClick={() => setCheckboxClicked(!checkboxClicked)}
					required={required}
				/>
				{checkboxClicked && (
					<div>
						<svg
							className={"w-4 h-4 fill-current flex absolute inset-0 p-0.5"}
							color="white"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							onClick={() => setCheckboxClicked(!checkboxClicked)}
						>
							<path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
						</svg>
					</div>
				)}
			</div>
			<label
				htmlFor={`custom-checkbox${index}`}
				className={`${
					validation
						? "custom-checkbox-text-validation"
						: "custom-checkbox-text"
				} ml-1 mr-1 text-${
					verticalLabels === "col" ? "sm" : "base"
				} font-normal duration-200 hover:cursor-pointer`}
				style={{
					color: `${
						validation
							? checkboxClicked
								? "black"
								: "#A50005"
							: checkboxClicked
							? "#A50005"
							: "black"
					}`,
				}}
			>
				{text}
			</label>
		</div>
	);
};

/*

    ------- DESCRIPTION -------
    submission form containing contact information

    ------- PROPS LIST -------
    - firstName       function        pass the corresponding state to the parent component
    - lastName        function        pass the corresponding state to the parent component
    - company         function        pass the corresponding state to the parent component
    - phone           function        pass the corresponding state to the parent component
    - email           function        pass the corresponding state to the parent component

    ------- EXAMPLE -------
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const changeFirstName = (v) => {
        setFirstName(v);
    };
    const changeLastName = (v) => {
        setLastName(v);
    };
    const changeCompany = (v) => {
        setCompany(v);
    };
    const changePhone = (v) => {
        setPhoneNumber(v);
    };
    const changeEmail = (v) => {
        setEmailAddress(v);
    };
    var form = {
        first: firstName,
        last: lastName,
        comp: company,
        phone: phoneNumber,
        email: emailAddress,
    };
    <UtilityClass.SubmissionForm
        firstName={changeFirstName}
        lastName={changeLastName}
        company={changeCompany}
        phone={changePhone}
        email={changeEmail}
    ></UtilityClass.SubmissionForm>

*/
const SubmissionForm = (props) => {
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [company, setCompany] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [email, setEmail] = useState();
	useEffect(() => props.firstName(firstName));
	useEffect(() => props.lastName(lastName));
	useEffect(() => props.company(company));
	useEffect(() => props.phone(phoneNumber));
	useEffect(() => props.email(email));
	const validateInputs = () => {
		if (
			firstName == "" ||
			firstName == undefined ||
			lastName == "" ||
			lastName == undefined ||
			company == "" ||
			company == undefined ||
			phoneNumber == "" ||
			phoneNumber == undefined ||
			email == "" ||
			email == undefined
		) {
			return false;
		} else {
			return true;
		}
	};
	console.log(validateInputs());
	return (
		<form className="my-6 px-6 sm:mx-12 sm:px-4 md:mx-28 md:px-10 lg:max-w-2xl lg:mx-auto lg:px-16 w-1/2">
			<div className="grid gap-2 grid-cols-2">
				<div className="mb-2">
					<input
						type="text"
						id="first-name"
						className="custom-submission-input border text-black text-sm font-light outline-none w-full p-2.5"
						required
						autoFocus
						onChange={(e) => {
							setFirstName(e.target.value);
						}}
						defaultValue={props.defaultFirstName}
					/>
					<label
						id="first-name-text"
						className="custom-submission-input-text p-2.5 text-sm font-light"
					>
						First Name
					</label>
				</div>
				<div className="mb-2">
					<input
						type="text"
						id="last-name"
						className="custom-submission-input border text-black text-sm font-light outline-none w-full p-2.5"
						required
						onChange={(e) => {
							setLastName(e.target.value);
						}}
						defaultValue={props.defaultLastName}
					/>
					<label
						id="last-name-text"
						className="custom-submission-input-text p-2.5 text-sm font-light"
					>
						Last Name
					</label>
				</div>
			</div>
			<div className="mb-2">
				<input
					type="text"
					id="company"
					className="custom-submission-input border text-black text-sm font-light outline-none w-full p-2.5"
					required
					onChange={(e) => {
						setCompany(e.target.value);
					}}
					defaultValue={props.defaultCompany}
				/>
				<label
					id="company-text"
					className="custom-submission-input-text p-2.5 text-sm font-light"
				>
					Company
				</label>
			</div>
			<div className="mb-2">
				<input
					type="text"
					id="phone-number"
					className="custom-submission-input border text-black text-sm font-light outline-none w-full p-2.5"
					required
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
					defaultValue={props.defaultPhone}
				/>
				<label
					id="phone-number-text"
					className="custom-submission-input-text p-2.5 text-sm font-light"
				>
					Phone Number
				</label>
			</div>
			<div className="mb-2 flex">
				<div
					className="p-3 border"
					style={{ borderColor: "#A50005", borderRight: "none" }}
				>
					<svg
						className="w-4 h-4 fill-current"
						style={{
							color: "gray",
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
					</svg>
				</div>
				<input
					type="text"
					id="email"
					className="custom-submission-input border text-black text-sm font-light outline-none w-full p-2.5"
					required
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					defaultValue={props.defaultEmail}
				/>
				<label
					id="email-text"
					className="custom-submission-input-text custom-submission-email-input-text p-2.5 text-sm font-light"
				>
					Email Address
				</label>
			</div>
			{/* <Link to={link}> */}
			<button
				type="submit"
				className="text-white font-normal text-sm w-full rounded px-2.5 py-2.5 text-left flex justify-between"
				style={{
					backgroundColor: "#A50005",
				}}
				// onClick={
				// 	validateInputs() == true
				// 		? props.handleSubmit
				// 		: alert("error submitting!")
				// }
				onClick={props.handleSubmit}
			>
				SUBMIT
				<div className="transition">
					{props.isLoading ? (
						<div className="transition" role="status">
							<svg
								aria-hidden="true"
								className=" w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
								viewBox="0 0 100 101"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
									fill="currentColor"
								/>
								<path
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
									fill="currentFill"
								/>
							</svg>
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						<svg
							className="w-4 h-4 fill-current"
							style={{
								color: "white",
							}}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
						</svg>
					)}
				</div>
			</button>
		</form>
	);
};

/*

    ------- DESCRIPTION -------
    tooltip that shows more information upon hover of the info symbol

    ------- PROPS LIST -------
    - description       string        the text within the tooltip 

    ------- EXAMPLE ----------
    <UtilityClass.InputTable
        rowNum={10}
        colNum={2}
        captionBool={true}
        label="GCD+X%"
        placeholder={["Route", "Diversion"]}
        dropdown={true}
        options={[
            [
                "0",
                "1",
            ],
            [
                "0",
                "1",
            ],
        ]}
        internalTitle={["Route", "Diversion"]}
        value={secondTable}
        includeDefaults={defaultGCD}
        defaultVals={gcdInputs}
        toolTip={true}
        toolTipDesc={"This is the definition of GCD."}
    />
*/

const Tooltip = ({ description = "" }) => {
	return (
		<div className="relative z-10 pl-1 inline-flex flex-col align-top">
			<div className="group relative cursor-pointer">
				<button className="rounded items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="12"
						fill="currentColor"
						className="bi bi-info-circle"
						viewBox="0 0 16 16"
					>
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
					</svg>
				</button>
				<div className="tooltip-background w-40 max-w-80 whitespace-pre-wrap h-auto text-xs pointer-events-none absolute px-1 py-2 rounded-md opacity-0 transition-opacity group-hover:opacity-100">
					<div className="flex justify-center w-full pl-1 pr-0.5">
						<p className="break-normal text-left text-white font-medium">
							{description}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const OrderTable = ({
	data = [],
	current = false,
	header = undefined,
	selected,
	setSelected,
	bottomMargin = false,
	orderPackage = undefined,
	orderType = undefined,
	setSecondarySelected,
}) => {
	const handleClick = (
		currentPackage,
		currentID,
		currentPackageType,
		status
	) => {
		if (current) {
			setSecondarySelected({
				id: 0,
				orderNumber: 0,
				package: "Default",
				packageType: "Default",
				status: "Default",
			});
		}
		if (
			(currentPackage === orderPackage && currentPackageType === orderType) ||
			current
		) {
			setSelected({
				...selected,
				id: currentID,
				package: currentPackage,
				packageType: currentPackageType,
				status: status,
			});
		}
	};

	return (
		<div
			className={`flex w-full flex-col ${
				bottomMargin && "mb-6"
			} justify-center`}
		>
			{/* <div className={'w-full'}>
                    <UtilityClass.CustomText
                                textSize="medium"
                                color="black"
                                fontWeight="bold"
                                content={
                                    'Current Order'
                                }
                                alignment="left"
                    /> 
                </div> */}
			<table className="table-auto w-full h-full">
				<thead className="text-left">
					<tr>
						<th className={"display-none focus:outline-none"} />
						<th className={"!border-none !bg-white"} colSpan={4}>
							{header}
						</th>
					</tr>
					<tr className={"text-white"}>
						<th />
						<th className={"p-2 border border-[#a50005] bg-[#a50005]"}>
							Order #
						</th>
						<th className={"p-2 border border-[#a50005] bg-[#a50005]"}>
							Package
						</th>
						<th className={"p-2 border border-[#a50005] bg-[#a50005]"}>
							Package Type
						</th>
						<th className={"p-2 border border-[#a50005] bg-[#a50005]"}>
							Date Created
						</th>
					</tr>
				</thead>
				{current ? (
					<tbody>
						{data.map((value, index) => {
							return (
								<tr
									className={`cursor-pointer
                                    ${
																			selected !== value.uuid &&
																			"hover:bg-gray-100 transition duration-200"
																		}`}
									key={index}
									onClick={() =>
										handleClick(
											value.package,
											value.id,
											value.packageType,
											value.status
										)
									}
								>
									<td
										className={` bg-white font-bold cursor-default transition duration-200 pr-1.5
                                            ${
																							selected.id === value.id
																								? "text-black"
																								: "text-white"
																						}`}
									>
										&rarr;
									</td>
									<td
										className={`p-2 border ${
											selected === value.uuid && "border-gray-300 bg-gray-200"
										}`}
									>
										<div className="flex flex-row gap-3 items-center justify-start">
											<div className="font-bold">{value.uuid}</div>
											<div className="text-sm">(#{value.shopifyid})</div>
										</div>
									</td>
									<td
										className={`p-2 border ${
											selected === value.uuid && "border-gray-300 bg-gray-200"
										}`}
									>
										{value.package}
									</td>
									<td
										className={`p-2 border ${
											selected === value.uuid && "border-gray-300 bg-gray-200"
										}`}
									>
										{value.packageType}
									</td>
									<td
										className={`p-2 border ${
											selected === value.uuid && "border-gray-300 bg-gray-200"
										}`}
									>
										{value.date.split("T")[0]}
									</td>
								</tr>
							);
						})}
					</tbody>
				) : (
					<tbody>
						{data
							.filter(
								(e) => e.package === orderPackage && e.packageType === orderType
							)
							.map((value, index) => {
								return (
									<tr
										className={`cursor-pointer
                                    ${
																			selected !== value.uuid &&
																			"hover:bg-gray-100 transition duration-200"
																		}`}
										key={index}
										onClick={() =>
											handleClick(
												value.package,
												value.id,
												value.packageType,
												value.status
											)
										}
									>
										<td
											className={` bg-white font-bold cursor-default transition duration-200 pr-1.5
                                            ${
																							selected.id === value.id
																								? "text-black"
																								: "text-white"
																						}`}
										>
											&rarr;
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											<div className="flex flex-row gap-3 items-center justify-start">
												<div className="font-bold">{value.uuid}</div>
												<div className="text-sm">(#{value.shopifyid})</div>
											</div>
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.package}
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.packageType}
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.date.split("T")[0]}
										</td>
									</tr>
								);
							})}
						{data
							.filter(
								(e) => e.package !== orderPackage || e.packageType !== orderType
							)
							.map((value, index) => {
								return (
									<tr
										className={`cursor-pointer
                                        text-gray-300 cursor-not-allowed select-none
                                    ${
																			selected !== value.uuid &&
																			"hover:bg-gray-100 transition duration-200"
																		}`}
										key={index}
										onClick={() =>
											handleClick(
												value.package,
												value.id,
												value.packageType,
												value.status
											)
										}
									>
										<td
											className={` bg-white font-bold cursor-default transition duration-200 pr-1.5
                                            ${
																							selected.id === value.id
																								? "text-black"
																								: "text-white"
																						}`}
										>
											&rarr;
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											<div className="flex flex-row gap-3 items-center justify-start">
												<div className="font-bold">{value.uuid}</div>
												<div className="text-sm">(#{value.shopifyid})</div>
											</div>
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.package}
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.packageType}
										</td>
										<td
											className={`p-2 border ${
												selected === value.uuid && "border-gray-300 bg-gray-200"
											}`}
										>
											{value.date.split("T")[0]}
										</td>
									</tr>
								);
							})}
					</tbody>
				)}
			</table>
		</div>
	);
};

export const UtilityClass = {
	Button,
	OEMSelection,
	BackNextButtons,
	DropdownMenu,
	CustomText,
	OrderTable,
	RadioButtons,
	RangeSlider,
	DefaultTable,
	DefaultInput,
	InputTable,
	InputTableRoutes,
	Image,
	InputReview,
	Dropzone,
	Separator,
	Checkbox,
	SubmissionForm,
	Tooltip,
};
