import { DemandForm } from "../../constants/DemandForm";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import _, { cloneDeep } from "lodash";
import { useRef, useEffect, useState } from "react";

const InputReviewDemand = () => {
	const packageLevel = useSelector((state) => state.packageLevel.value);
	const airline = useSelector((state) => state.demandData.airline);
	const scheduledStartDate = useSelector(
		(state) => state.demandData.scheduledStartDate
	);
	const markets = useSelector((state) => state.demandData.markets);
	const schedule = useSelector((state) => state.demandData.schedule);

	const generateRows = () => {
		var rows = [];
		const indices = [];

		// get the indices with all blank vals ('__')
		const allEqual = (arr) => arr.every((val) => val === arr[0]);
		schedule[0].forEach((value, index) => {
			if (allEqual(value)) {
				indices.push(index);
			}
		});

		// filter out the values at those indices
		let filteredMarkets = cloneDeep(markets);
		let filteredSched = cloneDeep(schedule);
		indices.forEach((value, index) => {
			if (index > 0) {
				filteredSched[0].splice(value - index, 1);
				filteredSched[1].splice(value - index, 1);
				filteredSched[2].splice(value * 3 - index * 3, 3);
				filteredMarkets.splice(value - index, 1);
			} else {
				filteredSched[0].splice(value, 1);
				filteredSched[1].splice(value, 1);
				filteredSched[2].splice(value * 3, 3);
				filteredMarkets.splice(value, 1);
			}
		});

		let dateArr = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
		if (filteredSched[1][0]) {
			filteredSched[1].forEach((outerValue, outerIndex) => {
				outerValue.forEach((value, index) => {
					// if (value === "checked") {
					if (value === true) {
						outerValue[index] = dateArr[index];
					}
				});
			});
		}

		for (let i = 0; i < filteredMarkets.length; i++) {
			if (packageLevel === "Basic") {
				rows[i] = [
					`${filteredMarkets[i][0]} - ${filteredMarkets[i][1]}`,
					`${filteredMarkets[i][2]}`,
					`${filteredMarkets[i][3]}`,
					`${filteredSched[0][i][2]}`,
					`${_.times(7, (j) => filteredSched[1][i][j])
						.toString()
						.split(",")
						.join("")}`,
					`${filteredSched[2][3 * i][0]} - ${filteredSched[2][3 * i][1]}`,
					`${filteredSched[2][3 * i][2]} - ${filteredSched[2][3 * i][3]}`,
					`${filteredSched[2][3 * i + 1][0]} - ${
						filteredSched[2][3 * i + 1][1]
					}`,
					`${filteredSched[2][3 * i + 1][2]} - ${
						filteredSched[2][3 * i + 1][3]
					}`,
					`${filteredSched[2][3 * i + 2][0]} - ${
						filteredSched[2][3 * i + 2][1]
					}`,
					`${filteredSched[2][3 * i + 2][2]} - ${
						filteredSched[2][3 * i + 2][3]
					}`,
				];
			} else {
				rows[i] = [
					`${filteredMarkets[i][0]} - ${filteredMarkets[i][1]}`,
					`${filteredMarkets[i][2]}`,
					`${filteredMarkets[i][3]}`,
					`${filteredMarkets[i][4]}`,
				];
			}
		}
		return rows;
	};

	const inputTable = [
		[
			["Airline", airline],
			["Scheduled Start Date", scheduledStartDate],
		],
	];

	const elementRef = useRef(null);
	const [htmlString, setHtmlString] = useState("");

	useEffect(() => {
		if (elementRef.current) {
			const htmlContent = elementRef.current.innerHTML;
			setHtmlString(htmlContent);
		}
	}, []);
	useEffect(() => {
		console.log(htmlString);
	}, [htmlString]);

	return (
		<div ref={elementRef}>
			<DemandForm.InputReview
				inputs={inputTable}
				nextLink={`/forms/Demand/LogoUpload`}
				additionalComponents={
					<>
						<UtilityClass.CustomText
							bottomMargin={true}
							textSize="large"
							fontWeight="semibold"
							content="Markets Summary"
						/>
						{packageLevel === "Basic" ? (
							<UtilityClass.DefaultTable
								headerList={[
									"Markets",
									"Aircraft\nType",
									"Aircraft\nCapacity",
									"Partner\nAirlines",
									"Operating\nDays",
									"Rotation 1\nOutbound\nDep-Arr",
									"Rotation 1\nInbound\nDep-Arr",
									"Rotation 2\nOutbound\nDep-Arr",
									"Rotation 2\nInbound\nDep-Arr",
									"Rotation 3\nOutbound\nDep-Arr",
									"Rotation 3\nInbound\nDep-Arr",
								]}
								rows={generateRows()}
							></UtilityClass.DefaultTable>
						) : (
							<UtilityClass.DefaultTable
								headerList={[
									"Markets",
									"Aircraft\nType",
									"Aircraft\nCapacity",
									"Partner\nAirlines",
								]}
								rows={generateRows()}
							></UtilityClass.DefaultTable>
						)}
					</>
				}
			/>
		</div>
	);
};

export default InputReviewDemand;
