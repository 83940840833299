import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { chooseSecondPerfWeight } from "../../reducers/performanceDataSlice";
import { chooseSecondEconWeight } from "../../reducers/econDataSlice";
import { AircraftInfo } from "../../assets/aircraftInfo";
import { motion } from "framer-motion";

const CustomConfigWeights2 = () => {
    let packageType = useSelector((state) => state.packageType.value);
    let packageLevel = useSelector((state) => state.packageLevel.value);
    let econData = useSelector((state) => state.econData);
    let perfData = useSelector((state) => state.performanceData);

    let data;
    let acType2 = "";
    if (packageType === "Performance") {
        data = perfData;
        acType2 = data.acType2;
    } else {
        data = econData;
        acType2 = econData.acType2;
    }
    let weightData = data.configTwo;

    const matchInfo = () => {
        let match = "";
        let OEMNames = Object.keys(AircraftInfo);
        OEMNames.forEach((name) => {
            AircraftInfo[name].forEach((value) => {
                if (acType2 === value.acID) {
                    match = value;
                }
            });
        });
        return match;
    };

    const info = matchInfo();

    let weightInputs = {
        seats: info["Seats"],
        mtow: info["MTOW (KG/Lbs)"],
        mlw: info["MLW (KG/Lbs)"],
        owe: info["OWE (KG/Lbs)"],
        unit: data.config.unit,
    };

    const [seatsInput, setSeatsInput] = useState();
    const changeSeatsInput = (i) => {
        setSeatsInput(i);
    };
    const [mtowInput, setMtowInput] = useState();
    const changeMtowInput = (i) => {
        setMtowInput(i);
    };

    const [mlwInput, setMlwInput] = useState();
    const changeMlwInput = (i) => {
        setMlwInput(i);
    };

    const [oweInput, setOweInput] = useState();
    const changeOweInput = (i) => {
        setOweInput(i);
    };

    const [custom, setCustom] = useState(
        weightData.unit ? weightData.unit : data.config.unit
    ); // to save the choice in a state in the parent component
    const chooseCustom = (type) => {
        setCustom(type);
    };

    const conversion = (num, iunit) => {
        if (iunit === "Kg") {
            return (num / 2.2046).toFixed(0);
        } else if (iunit === "lbs") {
            return (num * 2.2046).toFixed(0);
        } else {
            return num;
        }
    };

    const convertUnits = (unit) => {
        weightInputs.seats = weightData.seats;
        weightInputs.mtow = parseFloat(conversion(mtowInput, unit));
        weightInputs.mlw = parseFloat(conversion(mlwInput, unit));
        weightInputs.owe = parseFloat(conversion(oweInput, unit));
        weightInputs.unit = custom;
        if (packageType === "Performance") {
            dispatch(chooseSecondPerfWeight(weightInputs));
        } else if (packageType === "Economic") {
            dispatch(chooseSecondEconWeight(weightInputs));
        }
    };

    useEffect(() => {
        if (custom) {
            convertUnits(custom);
        }
    }, [custom]);

    useEffect(() => {
        initDispatch();
    }, []);

    const dispatch = useDispatch();

    const initDispatch = () => {
        let initInputs = {
            seats: weightData.seats,
            mtow: weightData.mtow,
            mlw: weightData.mlw,
            owe: weightData.owe,
            unit: custom,
        };
        if (packageType === "Performance") {
            dispatch(chooseSecondPerfWeight(initInputs));
        } else if (packageType === "Economic") {
            dispatch(chooseSecondEconWeight(initInputs));
        }
    };

    const dispatchInputs = () => {
        if (seatsInput === undefined || seatsInput === "") {
            weightInputs.seats = data.configTwo.seats;
        } else {
            weightInputs.seats = seatsInput;
        }
        if (mlwInput === undefined || mlwInput === "") {
            weightInputs.mlw = data.configTwo.mlw;
        } else {
            weightInputs.mlw = mlwInput;
        }
        if (mtowInput === undefined || mtowInput === "") {
            weightInputs.mtow = data.configTwo.mtow;
        } else {
            weightInputs.mtow = mtowInput;
        }
        if (oweInput === undefined || oweInput === "") {
            weightInputs.owe = data.configTwo.owe;
        } else {
            weightInputs.owe = oweInput;
        }
        weightInputs.unit = custom;
        if (packageType === "Performance") {
            dispatch(chooseSecondPerfWeight(weightInputs));
        } else if (packageType === "Economic") {
            dispatch(chooseSecondEconWeight(weightInputs));
        }
    };

    const validateContinue = () => {
        let validated = false;
        if (
            seatsInput?.toString().length > 0 &&
            mtowInput?.toString().length > 0 &&
            mlwInput?.toString().length > 0 &&
            oweInput?.toString().length > 0
        ) {
            validated = true;
        } else {
            validated = false;
        }
        return validated;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
        >
            <div className="lg:w-6/12 md:w-10/12 w-6/12 mx-auto">
                {packageLevel === "Basic" ? (
                    <UtilityClass.CustomText
                        textSize="subtitle"
                        fontWeight="semibold"
                        color="black"
                        content={"Custom configuration and weights"}
                    />
                ) : (
                    <UtilityClass.CustomText
                        textSize="subtitle"
                        fontWeight="semibold"
                        color="black"
                        content={
                            "Custom configuration and weights for aircraft #2"
                        }
                    />
                )}
                <UtilityClass.Separator />
                <UtilityClass.CustomText
                    textSize="large"
                    fontWeight="semibold"
                    color="black"
                    content={`Aircraft type: ${acType2}`}
                />
                <UtilityClass.Separator />
                <UtilityClass.CustomText
                    textSize="small"
                    fontWeight="medium"
                    color="grey"
                    content={
                        "Fields below are auto-filled with default values, if required, override with custom values"
                    }
                />
            </div>
            <div className="w-6/12 flex justify-center flex-col mx-auto">
                {/* Seats */}
                <div className="max-w-xs mx-auto">
                    <UtilityClass.DefaultInput
                        defaultValue={data.configTwo.seats}
                        labelBool={true}
                        label="Seats"
                        placeholder={"Number of Seats"}
                        value={changeSeatsInput}
                        width="auto"
                        regex={/^(?:[1-9]\d*|)$/}
                    />
                </div>
            </div>
            <div className="flex justify-center">
                <UtilityClass.RadioButtons
                    title="Select weight unit"
                    text={["Kg", "lbs"]}
                    row={true}
                    defaultValue={
                        data.configTwo.unit
                            ? data.configTwo.unit
                            : data.config.unit
                    }
                    value={chooseCustom}
                    width="2/12"
                />
            </div>
            <div className="w-6/12 flex justify-center flex-col mx-auto">
                {/* MTOW */}
                <div className="max-w-xs mx-auto">
                    <UtilityClass.DefaultInput
                        defaultValue={data.configTwo.mtow}
                        labelBool={true}
                        label="MTOW"
                        value={changeMtowInput}
                        width="auto"
                        regex={/^(?:[1-9]\d*|)$/}
                    />
                    {/* MLW */}
                    <UtilityClass.DefaultInput
                        defaultValue={data.configTwo.mlw}
                        labelBool={true}
                        label="MLW"
                        value={changeMlwInput}
                        width="auto"
                        regex={/^(?:[1-9]\d*|)$/}
                    />
                    {/* OWE */}
                    <UtilityClass.DefaultInput
                        defaultValue={data.configTwo.owe}
                        labelBool={true}
                        label="OWE"
                        value={changeOweInput}
                        width="auto"
                        regex={/^(?:[1-9]\d*|)$/}
                    />
                </div>
                <UtilityClass.BackNextButtons
                    nextButtonText="NEXT"
                    backLink={"/forms/" + packageType + "/AircraftEngine2"}
                    nextLink={"/forms/" + packageType + "/SelectedAircraft"}
                    dispatchInputs={() => dispatchInputs()}
                    validation={validateContinue()}
                />
            </div>
        </motion.div>
    );
};

export default CustomConfigWeights2;
