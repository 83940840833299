import React, { useEffect, useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useDispatch } from "react-redux";
import {
	selectACType,
	selectACDefault,
	chooseFirstPerfWeight,
	selectACList,
	selectConfigRows,
	selectConditions,
} from "../../reducers/performanceDataSlice";
import {
	selectEconACType,
	selectEconACDefault,
	chooseFirstEconWeight,
	selectEconConfigRows,
	selectEconConditions,
} from "../../reducers/econDataSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const AircraftEngine = (props) => {
	// this component needs a redux state that will be dynamic and will have the option at the
	// end to chose default values for ac and engine.
	// if default vals are chosen the "progressWidth" is passed up to parent component and is adjusted by +10
	// if custom is chosen it calls the customValues component and less "progressWidth"% is passed up to parent.
	// redux calls
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let packageType = useSelector((state) => state.packageType.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);

	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}
	let OEMChoice = data.OEMChoice;
	let acData = data.acType;
	let acDefault = data.acDefault;
	let weightUnit = data.config.unit || "Kg";

	const [fetchedData, setFetchedData] = useState(
		data.configRows ? data.configRows : []
	);
	const [fetchedOptions, setFetchedOptions] = useState(
		data.acList ? data.acList : []
	);

	const processData = () => {
		const filteredChoice = data.acList.filter((value, index) => {
			if (value.manufacturer === data.OEMChoice) {
				return value;
			}
		});
		setFetchedOptions(filteredChoice);
	};

	const processChartData = (aircraftType, engineType) => {
		const filteredChoice = fetchedOptions.filter((value, index) => {
			if (
				value.aircraftType === aircraftType &&
				value.engineType === engineType
			) {
				return value;
			}
		});
		setFetchedData(filteredChoice);
	};

	const matchAircraft = () => {
		if (acType && OEMChoice) {
			let acSlice = acType.slice(0, 1);
			let choiceSlice = OEMChoice.slice(0, 1);
			if (OEMChoice == "MHI RJ" && acType != "") {
				return true;
			}
			if (acSlice === choiceSlice) {
				return true;
			} else {
				return false;
			}
		}
	};

	const conversion = (num, iunit) => {
		if (iunit === "Kg") {
			return (num / 2.2046).toFixed(0);
		} else if (iunit === "lbs") {
			return (num * 2.2046).toFixed(0);
		}
	};

	const dispatch = useDispatch();
	const [acType, setACType] = useState(acData); // to save the choice in a state in the parent component

	const chooseAircraft = (type) => {
		setACType(type);
		let tempTypeEngine = type.split(",");
		processChartData(tempTypeEngine[0].trim(), tempTypeEngine[1].trim(), 0);
	};

	const [custom, setCustom] = useState(); // to save the choice in a state in the parent component
	const chooseCustom = (type) => {
		setCustom(type);
	};

	const [defaultAC, setDefaultAC] = useState(
		acData === undefined ? false : true
	);

	const [defaultOEM, setDefaultOEM] = useState(
		OEMChoice === undefined ? false : true
	);

	const [defaultChoice, setDefaultChoice] = useState(
		acDefault === undefined ? false : true
	);

	const generateListOptions = () => {
		let options = [];
		if (fetchedOptions.length > 0) {
			fetchedOptions.map((option) => {
				options.push(`${option.aircraftType}, ${option.engineType}`);
			});
		}
		return options;
	};

	const generateRow = () => {
		let rowData = [];
		if (fetchedData.length > 0) {
			fetchedData.map((info) => {
				rowData.push(info.aircraftType);
				rowData.push(info.engineType);
				rowData.push(info.configTotalSeats);
				rowData.push(
					`${info.maxTakeoffWeight} / ${(
						info.maxTakeoffWeight * 2.2046
					).toFixed(0)}`
				);
				rowData.push(
					`${info.maxLandingWeight} / ${(
						info.maxLandingWeight * 2.2046
					).toFixed(0)}`
				);
				rowData.push(
					`${info.operatingWeight} / ${(info.operatingWeight * 2.2046).toFixed(
						0
					)}`
				);
				rowData.push(
					`${info.maxPayload} / ${(info.maxPayload * 2.2046).toFixed(0)}`
				);
			});
		}
		return [rowData];
	};

	const dispatchInputs = () => {
		let newConditions = { ...data.conditions };
		if (data.acType !== acType) {
			newConditions.divFixedMach = undefined;
			newConditions.fixedMach = undefined;
			newConditions.cruiseSpeed = "Long Range Cruise";
			newConditions.divCruiseSpeed = "Long Range Cruise";
		}

		let inputs = {
			seats: fetchedData[0].configTotalSeats,
			mtow:
				weightUnit === "lbs"
					? parseFloat(conversion(fetchedData[0].maxTakeoffWeight, "lbs"))
					: fetchedData[0].maxTakeoffWeight,
			mlw:
				weightUnit === "lbs"
					? parseFloat(conversion(fetchedData[0].maxLandingWeight, "lbs"))
					: fetchedData[0].maxLandingWeight,
			owe:
				weightUnit === "lbs"
					? parseFloat(conversion(fetchedData[0].operatingWeight, "lbs"))
					: fetchedData[0].operatingWeight,
			payload: fetchedData[0].maxPayload,
			unit: acDefault === "No, use default values." ? "Kg" : weightUnit,
		};

		if (packageType === "Performance") {
			dispatch(selectACType(acType));
			dispatch(selectACDefault(custom));
			dispatch(chooseFirstPerfWeight(inputs));
			dispatch(selectConfigRows(fetchedData));
			dispatch(selectConditions(newConditions));
		} else if (packageType === "Economic") {
			dispatch(selectEconACType(acType));
			dispatch(selectEconACDefault(custom));
			dispatch(chooseFirstEconWeight(inputs));
			dispatch(selectEconConfigRows(fetchedData));
			dispatch(selectEconConditions(newConditions));
		}
	};

	useEffect(() => {
		processData();
	}, []);

	//validation based on inputs
	const validateContinue = () => {
		let validated = false;
		if (acType.length > 0) {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.DropdownMenu
				title="Select aircraft, engine combination to see default characteristics"
				titleBool={true}
				label="Please select an aircraft type"
				options={generateListOptions()}
				internalTitle="Please select an aircraft type"
				includeDefaults={defaultAC}
				value={chooseAircraft}
				defaultVals={matchAircraft() && data.acList.length > 0 && acData}
				showTable={matchAircraft() && data.acList.length > 0}
				headerList={[
					"ACType",
					"Engine",
					"Seats",
					"MTOW (KG/Lbs)",
					"MLW (KG/Lbs)",
					"OWE (KG/Lbs)",
					"Max Payload (KG/Lbs)",
				]}
				rows={generateRow()}
				width="auto"
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="thin"
				color="black"
				content={
					"Default weight unit for analysis is Kg. For Lbs, select custom values below"
				}
			/>
			<UtilityClass.RadioButtons
				title="Would you like to provide custom values?"
				text={["No, use default values.", "Yes, I will provide custom values."]}
				value={chooseCustom}
				width="fit"
				defaultValue={defaultChoice ? acDefault : "No, use default values."}
			/>
			{packageLevel === "Basic" ? (
				custom && custom === "No, use default values." ? (
					<UtilityClass.BackNextButtons
						nextButtonText="NEXT"
						backLink={"/forms/" + packageType}
						nextLink={"/forms/" + packageType + "/RoutesAndDistances"}
						validation={validateContinue()}
						dispatchInputs={dispatchInputs}
					/>
				) : (
					<UtilityClass.BackNextButtons
						nextButtonText="NEXT"
						backLink={"/forms/" + packageType}
						nextLink={"/forms/" + packageType + "/CustomConfigWeights"}
						validation={validateContinue()}
						dispatchInputs={dispatchInputs}
					/>
				)
			) : custom && custom === "No, use default values." ? (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType}
					nextLink={"/forms/" + packageType + "/OEM2"}
					validation={validateContinue()}
					dispatchInputs={dispatchInputs}
				/>
			) : (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType}
					nextLink={"/forms/" + packageType + "/CustomConfigWeights"}
					validation={validateContinue()}
					dispatchInputs={dispatchInputs}
				/>
			)}
		</motion.div>
	);
};

export default AircraftEngine;
