export const getProgressCount = (
    paths,
    location,
    setProgressWidth,
    setCount,
    packageType
) => {
    let newPaths = paths.filter((value) => typeof value !== "boolean");
    let index = newPaths.includes(location.pathname)
        ? newPaths.indexOf(location.pathname) + 1
        : 1;
    setProgressWidth(
        location.pathname !== `/forms/${packageType}/Submission`
            ? (index / (newPaths.length + 1)) * 100
            : ((index - 0.5) / (newPaths.length + 1)) * 100
    );
    setCount([index, newPaths.length + 1]);
};
