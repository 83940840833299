import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { motion } from "framer-motion";
import { selectLogoUpload } from "../../reducers/performanceDataSlice";
import { setDemandLogoUpload } from "../../reducers/demandDataSlice";
import { selectEconLogoUpload } from "../../reducers/econDataSlice";

const LogoUpload = (props) => {
    // this component takes in submission for customer/company logo.
    // Based on whether it is perf or econ it will contain an extra segment of radio buttons.
    // if it is econ (taken from redux value) it will contain extra components if we want to include airfield and route performance results.

    const dispatch = useDispatch();
    let packageType = useSelector((state) => state.packageType.value);
    let econData = useSelector((state) => state.econData);
    let perfData = useSelector((state) => state.performanceData);
    let demandData = useSelector((state) => state.demandDataData);
    let data;
    if (packageType === "Performance") {
        data = perfData;
    } else if (packageType === "Economic") {
        data = econData;
    } else {
        data = demandData;
    }

    const logoData = data ? data.logoUpload.logoChoice : "";
    const logoImageData = data ? data.logoUpload.logoImage : {};
    console.log(logoImageData);
    const resultChoiceData = data ? data.logoUpload.resultChoice : {};

    const [defaultLogoChoice, setDefaultLogoChoice] = useState(
        logoData === undefined || logoData.length === 0 ? false : true
    );
    const [defaultResultsChoice, setDefaultResultsChoice] = useState(
        resultChoiceData === undefined || resultChoiceData.length === 0
            ? false
            : true
    );

    const [logoChoice, setLogoChoice] = useState();
    const [selectedFile, setSelectedFile] = useState(
        logoImageData ? logoImageData.name : ""
    );
    const [imageURL, setImageURL] = useState(
        logoImageData ? logoImageData.imageURL : ""
    );
    const [resultsChoice, setResultsChoice] = useState();

    let inputs = {
        logoChoice: false,
        logoImage: undefined,
        resultChoice: false,
    };

    const dispatchInputs = () => {
        inputs.logoChoice = logoChoice;
        inputs.logoImage = {
            name: selectedFile,
            imageURL: imageURL,
        };
        inputs.resultChoice = resultsChoice;
        if (packageType === "Performance") {
            dispatch(selectLogoUpload(inputs));
        } else if (packageType === "Economic") {
            dispatch(selectEconLogoUpload(inputs));
        } else {
            dispatch(setDemandLogoUpload(inputs));
        }
    };

    return (
        <motion.div>
            <UtilityClass.CustomText
                textSize="title"
                fontWeight="medium"
                bottomMargin={true}
                color="red"
                content={"Logo Upload"}
            />
            <div className="flex justify-center">
                <UtilityClass.RadioButtons
                    title="Would you like to include your company logo on the report?"
                    text={["Yes", "No"]}
                    subTextBool={false}
                    subText={["", ""]}
                    row={true}
                    value={setLogoChoice}
                    defaultValue={defaultLogoChoice ? logoData : "No"}
                    keyIn="first"
                    horizontal={true}
                />
            </div>
            <UtilityClass.Dropzone
                imageURL={imageURL}
                setImageURL={setImageURL}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                logoChoice={logoChoice}
            />
            {packageType === "Economic" && (
                <div className="flex justify-center">
                    <UtilityClass.RadioButtons
                        title="Would you like your report to include the tabulated airfield and route performance results?"
                        text={["Yes", "No"]}
                        subTextBool={false}
                        subText={["", ""]}
                        row={true}
                        value={setResultsChoice}
                        defaultValue={
                            defaultResultsChoice ? resultChoiceData : "No"
                        }
                        keyIn="second"
                        horizontal={true}
                    />
                </div>
            )}
            <UtilityClass.BackNextButtons
                nextButtonText="GO TO SUBMIT"
                backLink={props.backLink}
                nextLink={props.nextLink}
                validation={true}
                dispatchInputs={dispatchInputs}
            />
        </motion.div>
    );
};

export default LogoUpload;
