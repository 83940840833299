import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectOEM2, selectACType2 } from "../../reducers/performanceDataSlice";
import {
	selectEconOEM2,
	selectEconACType2,
} from "../../reducers/econDataSlice";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { motion } from "framer-motion";

const SelectOEM2 = (props) => {
	let packageType = useSelector((state) => state.packageType.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}

	let acType1 = data.acType;
	let OEMChoice = data.OEMChoice2;
	let defaultChoice = data.acDefault;
	let acType = data.acType2;
	let OEMList = data.OEMList;

	const [defaultOEM, setDefaultOEM] = useState(
		OEMChoice === undefined ? false : true
	);

	const dispatch = useDispatch();
	// const OEMs = [
	//     "Airbus",
	//     "Boeing",
	//     "MHI RJ",
	//     "Embraer",
	//     "De Havilland",
	//     "ATR",
	// ];
	// this component needs to assign to redux the selected value for ac1 or ac2 based on the package level
	const [, setOEM2] = useState(); // to save the choice in a state in the parent component
	const chooseOEM2 = (type) => {
		// function called in the prop value
		setOEM2(type);
		if (packageType === "Performance") {
			dispatch(selectOEM2(type));
			if (!acType) {
				dispatch(selectACType2([]));
			}
		} else if (packageType === "Economic") {
			dispatch(selectEconOEM2(type));
			if (!acType) {
				dispatch(selectEconACType2([]));
			}
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="bold"
				color="black"
				content={`Selected Aircraft Type\n Aircraft 1: ${acType1}`}
			/>
			<UtilityClass.Separator />
			{OEMList.length > 0 && (
				<UtilityClass.OEMSelection
					title="Select OEM for aircraft #2"
					OEM={OEMList}
					value={chooseOEM2}
					defaultValue={defaultOEM ? OEMChoice : []}
				/>
			)}
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={
					defaultChoice === "Yes, I will provide custom values."
						? "/forms/" + packageType + "/CustomConfigWeights"
						: "/forms/" + packageType + "/AircraftEngine"
				}
				nextLink={"/forms/" + packageType + "/AircraftEngine2"}
				validation={true}
			/>
		</motion.div>
	);
};

export default SelectOEM2;
