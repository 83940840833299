import { UtilityClass } from "../../utility-class/UtilityClass";

const NotFound = () => {
    console.log("i am rendered");
    return (
        <div className="w-screen h-screen fixed z-40 bg-white top-0 left-0">
            <div className="flex flex-col pt-56">
                <UtilityClass.CustomText
                    content="404"
                    noMargin
                    textSize="title"
                    fontWeight="semibold"
                />
                <UtilityClass.CustomText
                    content="That URL was not found. Please try again or contact support for assistance."
                    textSize="large"
                    fontWeight="semibold"
                />
            </div>
        </div>
    );
};

export default NotFound;
