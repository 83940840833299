import React, { useState, useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import SpeedScheduleImage from "../../images/speed-schedule.png";
import { useSelector, useDispatch } from "react-redux";
import {
	selectConditions,
	selectConditionsTwo,
} from "../../reducers/performanceDataSlice";
import {
	selectEconConditions,
	selectEconConditionsTwo,
} from "../../reducers/econDataSlice";
import { motion } from "framer-motion";

const EnvironmentalConditions = (props) => {
	// this component needs to take in props from package level and if it is premium needs to include
	// AC2 speed at the bottom.

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);

	let data = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData;
		} else if (packageType === "Economic") {
			return state.econData;
		}
	});

	let weightUnit = data.config.unit;
	let acType1 = data.acType;
	let acType2 = data.acType2;

	// redux data for each element
	let tempData = data.conditions.temp;
	let windData = data.conditions.wind;
	let windProbData = data.conditions.windProb;
	let fuelReserveData = data.conditions.fuelReserve;
	let payloadData = data.conditions.payloadCrit;
	let payloadFactorData = data.conditions.payloadFactor;
	let passBagData =
		data.conditions.pbAllowance === 220 && weightUnit === "Kg"
			? 100
			: data.conditions.pbAllowance === 100 && weightUnit === "lbs"
			? 220
			: data.conditions.pbAllowance;
	let aircraftUtilData = data.conditions.aircraftUtil;
	let acTypeData = data.conditions.acType;
	let climbSpeedData = data.conditions.climbSpeed;
	let cruiseSpeedData = data.conditions.cruiseSpeed;
	let descentData = data.conditions.descentSpeed;
	let fixedMachData = data.conditions.fixedMach;
	let divClimbData = data.conditions.divClimbSpeed;
	let divCruiseData = data.conditions.divCruiseSpeed;
	let divDescData = data.conditions.divDescSpeed;
	let divFixedMachData = data.conditions.divFixedMach;

	let aircraftUtilDataTwo = data.conditionsTwo.aircraftUtil2;
	let climbSpeedDataTwo = data.conditionsTwo.climbSpeed;
	let cruiseSpeedDataTwo = data.conditionsTwo.cruiseSpeed;
	let descentDataTwo = data.conditionsTwo.descentSpeed;
	let fixedMachDataTwo = data.conditionsTwo.fixedMach;
	let divClimbDataTwo = data.conditionsTwo.divClimbSpeed;
	let divCruiseDataTwo = data.conditionsTwo.divCruiseSpeed;
	let divDescDataTwo = data.conditionsTwo.divDescSpeed;
	let divFixedMachDataTwo = data.conditionsTwo.divFixedMach;

	let splitAcType1 = acType1.length > 0 ? acType1.split(",") : ["", ""];
	let splitAcType2 = acType2.length > 0 ? acType2.split(",") : ["", ""];

	let normalOperatingData = data.normalSpeedMachData.find(
		(e) =>
			e.aircraftType === splitAcType1[0].trim() &&
			e.engineType === splitAcType1[1].trim()
	);

	let normalOperatingData2 = data.normalSpeedMachData.find(
		(e) =>
			e.aircraftType === splitAcType2[0].trim() &&
			e.engineType === splitAcType2[1].trim()
	);

	const [temp, setTemp] = useState(tempData);
	const chooseTemp = (type) => {
		setTemp(type);
	};

	const [wind, setWind] = useState(windData);
	const chooseWind = (type) => {
		setWind(type);
	};
	const [windProbability, setWindProbability] = useState(windProbData);
	const chooseWindProbability = (v) => {
		setWindProbability(v);
	};
	const [fuelReserve, setfuelReserve] = useState(fuelReserveData);
	const choosefuelReserve = (type) => {
		setfuelReserve(type);
	};
	const [payloadCriteria, setPayloadCriteria] = useState(); // to save the choice in a state in the parent component
	const choosePayloadCriteria = (type) => {
		setPayloadCriteria(type);
	};
	const [payloadFactor, setPayloadFactor] = useState();
	const choosePayloadFactor = (type) => {
		setPayloadFactor(type);
	};
	const [passBagAllowance, setPassBagAllowance] = useState(passBagData);
	const choosePassBagAllowance = (v) => {
		setPassBagAllowance(v);
	};
	const [aircraftUtilization, setAircraftUtilization] =
		useState(aircraftUtilData);
	const chooseAircraftUtilization = (v) => {
		setAircraftUtilization(v);
	};

	const [aircraftUtilTwo, setAircraftUtilTwo] = useState(aircraftUtilDataTwo);
	const chooseAircraftUtilTwo = (v) => {
		setAircraftUtilTwo(v);
	};

	const [ACtype, setACtype] = useState("A318-100,CFM56-5"); // this needs to be changed to read from redux

	const [climbSpeed, setClimbSpeed] = useState(climbSpeedData);
	const chooseClimbSpeed = (type) => {
		setClimbSpeed(type);
	};

	const [cruiseSpeed, setCruiseSpeed] = useState(cruiseSpeedData);
	const chooseCruiseSpeed = (type) => {
		setCruiseSpeed(type);
	};
	const [descentSpeed, setDescentSpeed] = useState(descentData);
	const chooseDescentSpeed = (type) => {
		setDescentSpeed(type);
	};
	const [fixedMach, setFixedMach] = useState(fixedMachData);
	const chooseFixedMach = (type) => {
		setFixedMach(type);
	};
	const [diversionClimbSpeed, setDiversionclimbSpeed] = useState(divClimbData);
	const chooseDiversionclimbSpeed = (type) => {
		setDiversionclimbSpeed(type);
	};
	const [diversionCruiseSpeed, setDiversionCruiseSpeed] =
		useState(divCruiseData);
	const chooseDiversionCruiseSpeed = (type) => {
		setDiversionCruiseSpeed(type);
	};
	const [diversionDescentSpeed, setDiversionDescentSpeed] =
		useState(divDescData);
	const chooseDiversionDescentSpeed = (type) => {
		setDiversionDescentSpeed(type);
	};
	const [diversionFixedMach, setDiversionFixedMach] = useState();
	const chooseDiversionFixedMach = (type) => {
		setDiversionFixedMach(type);
	};

	const [climbSpeedTwo, setClimbSpeedTwo] = useState(climbSpeedDataTwo);
	const chooseClimbSpeedTwo = (type) => {
		setClimbSpeedTwo(type);
	};

	const [cruiseSpeedTwo, setCruiseSpeedTwo] = useState(cruiseSpeedDataTwo);
	const chooseCruiseSpeedTwo = (type) => {
		setCruiseSpeedTwo(type);
	};
	const [descentSpeedTwo, setDescentSpeedTwo] = useState(descentDataTwo);
	const chooseDescentSpeedTwo = (type) => {
		setDescentSpeedTwo(type);
	};
	const [fixedMachTwo, setFixedMachTwo] = useState(fixedMachDataTwo);
	const chooseFixedMachTwo = (type) => {
		setFixedMachTwo(type);
	};
	const [diversionClimbSpeedTwo, setDiversionclimbSpeedTwo] =
		useState(divClimbDataTwo);
	const chooseDiversionclimbSpeedTwo = (type) => {
		setDiversionclimbSpeedTwo(type);
	};
	const [diversionCruiseSpeedTwo, setDiversionCruiseSpeedTwo] =
		useState(divCruiseDataTwo);
	const chooseDiversionCruiseSpeedTwo = (type) => {
		setDiversionCruiseSpeedTwo(type);
	};
	const [diversionDescentSpeedTwo, setDiversionDescentSpeedTwo] =
		useState(divDescDataTwo);
	const chooseDiversionDescentSpeedTwo = (type) => {
		setDiversionDescentSpeedTwo(type);
	};
	const [diversionFixedMachTwo, setDiversionFixedMachTwo] =
		useState(divFixedMachDataTwo);
	const chooseDiversionFixedMachTwo = (type) => {
		setDiversionFixedMachTwo(type);
	};

	// making sure
	const [defaultTemp, setdefaultTemp] = useState(
		tempData === undefined ? false : true
	);

	const [defaultWind, setdefaultWind] = useState(
		windData === undefined ? false : true
	);

	const [defaultWindProb, setdefaultWindProb] = useState(
		windProbData === undefined ? false : true
	);

	const [defaultFuelReserve, setdefaultFuelReserve] = useState(
		fuelReserveData === undefined ? false : true
	);
	const [defaultPayload, setdefaultPayload] = useState(
		payloadData === undefined ? false : true
	);

	const [defaultPayloadFactor, setdefaultPayloadFactor] = useState(
		payloadFactorData === undefined ? false : true
	);

	const [defaultPassBag, setdefaultPassBag] = useState(
		passBagData === undefined ? false : true
	);

	const [defaultUtilData, setdefaultUtilData] = useState(
		aircraftUtilData === undefined ? false : true
	);

	const [defaultUtilDataTwo, setdefaultUtilDataTwo] = useState(
		aircraftUtilDataTwo === undefined ? false : true
	);

	const [defaultACType, setdefaultACType] = useState(
		acTypeData === undefined ? false : true
	);
	const [defaultClimbSpeed, setdefaultClimbSpeed] = useState(
		climbSpeedData === undefined ? false : true
	);
	const [defaultCruiseSpeed, setdefaultCruiseSpeed] = useState(
		cruiseSpeedData === undefined ? false : true
	);
	const [defaultDescentData, setdefaultDescentData] = useState(
		descentData === undefined ? false : true
	);
	const [defaultFixedMach, setdefaultFixMach] = useState(
		fixedMachData === undefined ? false : true
	);
	const [defaultDivClimbData, setdefaultDivClimbData] = useState(
		divClimbData === undefined ? false : true
	);
	const [defaultDivCruise, setdefaultDivCruise] = useState(
		divCruiseData === undefined ? false : true
	);
	const [defaultDivDesc, setdefaultDivDesc] = useState(
		divDescData === undefined ? false : true
	);
	const [defaultDivFixedMach, setdefaultDivFixedMach] = useState(
		windProbData === undefined ? false : true
	);
	const [defaultClimbSpeedTwo, setdefaultClimbSpeedTwo] = useState(
		climbSpeedDataTwo === undefined ? false : true
	);
	const [defaultCruiseSpeedTwo, setdefaultCruiseSpeedTwo] = useState(
		cruiseSpeedDataTwo === undefined ? false : true
	);
	const [defaultDescentDataTwo, setdefaultDescentDataTwo] = useState(
		descentDataTwo === undefined ? false : true
	);
	const [defaultFixedMachTwo, setdefaultFixMachTwo] = useState(
		fixedMachDataTwo === undefined ? false : true
	);
	const [defaultDivClimbTwo, setdefaultDivClimbTwo] = useState(
		divClimbDataTwo === undefined ? false : true
	);
	const [defaultDivCruiseTwo, setdefaultDivCruiseTwo] = useState(
		divCruiseDataTwo === undefined ? false : true
	);
	const [defaultDivDescTwo, setdefaultDivDescTwo] = useState(
		divDescDataTwo === undefined ? false : true
	);
	const [defaultDivFixedMachTwo, setdefaultDivFixedMachTwo] = useState(
		fixedMachDataTwo === undefined ? false : true
	);

	let condInputs = {
		temp: undefined,
		wind: undefined,
		windProb: undefined,
		fuelReserve: undefined,
		payloadCrit: undefined,
		payloadFactor: undefined,
		pbAllowance: undefined,
		aircraftUtil: undefined,
		acType: undefined,
		climbSpeed: undefined,
		cruiseSpeed: undefined,
		descentSpeed: undefined,
		fixedMach: undefined,
		divClimbSpeed: undefined,
		divCruiseSpeed: undefined,
		divDescSpeed: undefined,
		divFixedMach: undefined,
	};

	let condInputsTwo = {
		climbSpeed: undefined,
		cruiseSpeed: undefined,
		descentSpeed: undefined,
		fixedMach: undefined,
		divClimbSpeed: undefined,
		divCruiseSpeed: undefined,
		divDescSpeed: undefined,
		divFixedMach: undefined,
	};

	const dispatch = useDispatch();
	const dispatchInputs = () => {
		condInputs.temp = temp;
		condInputs.wind = wind;
		condInputs.windProb = windProbability;
		condInputs.fuelReserve = fuelReserve;
		condInputs.payloadCrit = payloadCriteria;
		condInputs.payloadFactor = payloadFactor;
		condInputs.pbAllowance = passBagAllowance;
		condInputs.aircraftUtil = aircraftUtilization;
		condInputs.acType = acType1;
		condInputs.climbSpeed = climbSpeed;
		condInputs.cruiseSpeed = cruiseSpeed;
		condInputs.descentSpeed = descentSpeed;
		condInputs.fixedMach = fixedMach;
		condInputs.divClimbSpeed = diversionClimbSpeed;
		condInputs.divCruiseSpeed = diversionCruiseSpeed;
		condInputs.divDescSpeed = diversionDescentSpeed;
		condInputs.divFixedMach = diversionFixedMach;

		condInputsTwo.aircraftUtil2 = aircraftUtilTwo;
		condInputsTwo.climbSpeed = climbSpeedTwo;
		condInputsTwo.cruiseSpeed = cruiseSpeedTwo;
		condInputsTwo.descentSpeed = descentSpeedTwo;
		condInputsTwo.fixedMach = fixedMachTwo;
		condInputsTwo.divClimbSpeed = diversionClimbSpeedTwo;
		condInputsTwo.divCruiseSpeed = diversionCruiseSpeedTwo;
		condInputsTwo.divDescSpeed = diversionDescentSpeedTwo;
		condInputsTwo.divFixedMach = diversionFixedMachTwo;

		if (packageType === "Performance") {
			dispatch(selectConditions(condInputs));
			dispatch(selectConditionsTwo(condInputsTwo));
		} else {
			dispatch(selectEconConditions(condInputs));
			dispatch(selectEconConditionsTwo(condInputsTwo));
		}
	};

	let dropDownNodes = document.querySelectorAll(
		'div[data-type="select"] > select'
	);
	let inputNodes = document.querySelectorAll('div[data-type="input"] > input');
	let inputCruise = Array.from(dropDownNodes)
		.splice(2, 1)
		.concat(Array.from(dropDownNodes).splice(5, 1));
	if (packageLevel === "Premium") {
		let secondSplice = Array.from(dropDownNodes)
			.splice(8, 1)
			.concat(Array.from(dropDownNodes).splice(11, 1));
		inputCruise = inputCruise.concat(secondSplice);
	}
	let inputMach = Array.from(inputNodes);

	function addRouteDistanceEventListeners(machInput, cruiseInput, index) {
		function clearMach() {
			Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			).set.call(machInput, "");
			machInput.dispatchEvent(new Event("input", { bubbles: true }));
		}

		try {
			cruiseInput.addEventListener("change", clearMach);
			//  oatInput.addEventListener("change", clearISA);
		} catch {}
	}

	inputMach.map((item, index) => {
		addRouteDistanceEventListeners(item, inputCruise[index], index);
	});

	//validation based on inputs
	const validateContinue = () => {
		let validated = true;
		if (packageLevel === "Basic") {
			if (
				(cruiseSpeed == "Fixed Mach" && fixedMach == "") ||
				(diversionCruiseSpeed == "Fixed Mach" && diversionFixedMach == "")
			) {
				validated = false;
			} else {
				validated = true;
			}
		} else {
			if (
				(cruiseSpeed == "Fixed Mach" && fixedMach == "") ||
				(diversionCruiseSpeed == "Fixed Mach" && diversionFixedMach == "") ||
				(cruiseSpeedTwo == "Fixed Mach" && fixedMachTwo == "") ||
				(diversionCruiseSpeedTwo == "Fixed Mach" && diversionFixedMachTwo == "")
			) {
				validated = false;
			} else {
				validated = true;
			}
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Environmental Conditions"}
			/>
			<UtilityClass.InputTable
				rowNum={1}
				colNum={1}
				captionBool={true}
				label="En route temperature (°C)"
				placeholder={["Please select"]}
				dropdown={true}
				options={[
					[
						"ISA-10",
						"ISA-5",
						"ISA",
						"ISA+5",
						"ISA+10",
						"ISA+15",
						"ISA+20",
						"ISA+25",
						"ISA+30",
					],
				]}
				internalTitle={["ISA (International Standard Atmosphere)"]}
				width="auto"
				value={chooseTemp}
				includeDefaults={defaultTemp}
				defaultVals={defaultTemp ? tempData : "ISA"}
			/>
			<div className="flex justify-center">
				<UtilityClass.RadioButtons
					title="En route seasonal wind"
					text={["Annual", "Winter", "Summer"]}
					row={true}
					value={chooseWind}
					horizontal={true}
					keyIn={"wind"}
					defaultValue={defaultWind ? windData : "Annual"}
				/>
			</div>
			<UtilityClass.RangeSlider
				numID="1"
				text="En route wind probability"
				min={"50"}
				max={"95"}
				defaultValue={"85"}
				step={"5"}
				unit="%"
				value={chooseWindProbability}
				includeDefaults={defaultWindProb}
				defaultVals={windProbData}
			/>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Operational Conditions"}
			/>
			<UtilityClass.RadioButtons
				title="Fuel reserve policy"
				text={["FAR Domestic", "FAR International", "JAR (5%)", "CAAC", "NBAA"]}
				subTextBool={true}
				subText={[
					"Federal Aviation Regulations (FAR) Domestic = Trip + Diversion + Contingency (45 min normal cruise)",
					"Federal Aviation Regulations (FAR) International = Trip + Diversion (includes 30 min hold) + Contingency (10% of Trip)",
					"Joint Aviation Requirements (JAR) = Trip + Diversion (includes 30 min hold) + Contingency (5% of Trip + 5 min hold at Destination)",
					"Civil Aviation Administration of China = Trip + Diversion (includes 30 min hold) + Contingency (10% of Trip)",
					"NBAA = Trip + Diversion + Contingency (45 min normal cruise)",
				]}
				width="auto"
				keyIn={"fuel"}
				value={choosefuelReserve}
				defaultValue={
					defaultFuelReserve ? fuelReserveData : "FAR International"
				}
			/>
			<div className="flex justify-center">
				<UtilityClass.RadioButtons
					title="Select max payload criteria"
					text={["Max Structural", "Passenger Load"]}
					row={true}
					value={choosePayloadCriteria}
					payload={true}
					keyIn={"payload"}
					defaultValue={defaultPayload ? payloadData : "Passenger Load"}
				/>
			</div>
			<UtilityClass.RangeSlider
				numID="2"
				text="Select passenger load factor"
				min={"50"}
				max={"100"}
				step={"1"}
				defaultValue={"85"}
				unit="%"
				value={choosePayloadFactor}
				defaultVals={payloadFactorData}
				includeDefaults={defaultPayloadFactor}
			/>

			<UtilityClass.RangeSlider
				numID="3"
				text="Select passenger & baggage allowance"
				min={weightUnit === "Kg" ? 70 : 154}
				max={weightUnit === "Kg" ? 125 : 276}
				step={1}
				defaultValue={weightUnit === "Kg" ? 100 : 220}
				unit={weightUnit}
				value={choosePassBagAllowance}
				defaultVals={passBagData}
				includeDefaults={defaultPassBag}
			/>
			{packageType === "Economic" && (
				<>
					<UtilityClass.CustomText
						textSize="medium"
						fontWeight="semibold"
						color="grey"
						content={"Aircraft #1: " + acType1}
					/>
					<UtilityClass.RangeSlider
						numID="4"
						text="Aircraft utilization (block hours/year)"
						min="1000"
						max="6000"
						step="50"
						defaultValue="3000"
						unit="hrs"
						value={chooseAircraftUtilization}
						defaultVals={aircraftUtilData}
						includeDefaults={defaultUtilData}
					/>
					{packageLevel === "Premium" && (
						<>
							<UtilityClass.CustomText
								textSize="medium"
								fontWeight="semibold"
								color="grey"
								content={"Aircraft #2: " + acType2}
							/>
							<UtilityClass.RangeSlider
								numID="5"
								text="Aircraft utilization (block hours/year)"
								min="1000"
								max="6000"
								step="50"
								defaultValue="3000"
								unit="hrs"
								value={chooseAircraftUtilTwo}
								defaultVals={aircraftUtilDataTwo}
								includeDefaults={defaultUtilDataTwo}
							/>
						</>
					)}
				</>
			)}
			<UtilityClass.Separator />
			<UtilityClass.Image
				src={SpeedScheduleImage}
				content="Speed Schedules"
				alt="Speed Schedules Image"
			/>
			{packageLevel === "Basic" ? (
				<UtilityClass.CustomText
					textSize="large"
					fontWeight="semibold"
					color="grey"
					content={`Aircraft: ${acType1}`}
				/>
			) : (
				<UtilityClass.CustomText
					textSize="large"
					fontWeight="semibold"
					color="grey"
					content={`Aircraft #1: ${acType1}`}
				/>
			)}
			<div className="grid justify-center lg:grid-cols-4 md:grid-cols-1 w-6/12 mx-auto">
				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Climb speed"
					placeholder={["Please select"]}
					dropdown={true}
					options={[["Low", "Default", "High"]]}
					internalTitle={["Please select"]}
					width="36"
					value={chooseClimbSpeed}
					defaultVals={defaultClimbSpeed ? climbSpeedData : "Default"}
					includeDefaults={defaultClimbSpeed}
				/>
				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Cruise speed*"
					placeholder={["Please select"]}
					dropdown={true}
					options={
						normalOperatingData &&
						parseFloat(normalOperatingData.normalOperatingSpeed) > 0
							? [
									[
										"Max Range Cruise",
										"Long Range Cruise",
										"Fixed Mach",
										normalOperatingData.normalOperatingSpeed + " KTS",
									],
							  ]
							: [["Max Range Cruise", "Long Range Cruise", "Fixed Mach"]]
					}
					internalTitle={["Please select"]}
					value={chooseCruiseSpeed}
					includeDefaults={defaultCruiseSpeed}
					defaultVals={
						defaultCruiseSpeed ? cruiseSpeedData : "Long Range Cruise"
					}
					width="36"
				/>
				<div className="fixed-mach-box justify-center mx-auto flex align-center">
					<UtilityClass.InputTable
						rowNum={1}
						colNum={1}
						captionBool={true}
						label="Fixed Mach"
						placeholder={["From"]}
						leftSquareLabelBool={[true]}
						leftSquareLabelText={["M0."]}
						includeDefaults={defaultFixedMach}
						isMach={cruiseSpeed === "Fixed Mach"}
						defaultVals={[
							[
								cruiseSpeed === "Fixed Mach"
									? fixedMachData
										? fixedMachData
										: normalOperatingData &&
										  parseFloat(normalOperatingData.normalOperatingMach) >= 0
										? normalOperatingData.normalOperatingMach
										: ""
									: "",
							],
						]}
						value={chooseFixedMach}
						disabledBoolList={[cruiseSpeed !== "Fixed Mach"]}
						width="full"
						regex={/^(?:[5-9]|\d{2})?$/}
					/>
				</div>
				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Descent speed"
					placeholder={["Please select"]}
					dropdown={true}
					options={[["Low", "Default", "High"]]}
					internalTitle={["Please select"]}
					value={chooseDescentSpeed}
					includeDefaults={defaultDescentData}
					defaultVals={defaultDescentData ? descentData : "Default"}
					width="36"
				/>

				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Diversion Climb speed"
					placeholder={["Please select"]}
					dropdown={true}
					options={[["Low", "Default", "High"]]}
					internalTitle={["Please select"]}
					value={chooseDiversionclimbSpeed}
					includeDefaults={defaultDivClimbData}
					defaultVals={defaultDivClimbData ? divClimbData : "Default"}
					width="36"
				/>
				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Diversion Cruise speed*"
					placeholder={["Please select"]}
					dropdown={true}
					options={
						normalOperatingData &&
						parseFloat(normalOperatingData.normalOperatingSpeed) > 0
							? [
									[
										"Max Range Cruise",
										"Long Range Cruise",
										"Fixed Mach",
										normalOperatingData.normalOperatingSpeed + " KTS",
									],
							  ]
							: [["Max Range Cruise", "Long Range Cruise", "Fixed Mach"]]
					}
					internalTitle={["Please select"]}
					value={chooseDiversionCruiseSpeed}
					includeDefaults={defaultDivCruise}
					defaultVals={defaultDivCruise ? divCruiseData : "Long Range Cruise"}
					width="36"
				/>
				<div className="fixed-mach-box justify-center mx-auto flex align-center">
					<UtilityClass.InputTable
						rowNum={1}
						colNum={1}
						captionBool={true}
						label="Fixed Mach"
						placeholder={["From"]}
						leftSquareLabelBool={[true]}
						leftSquareLabelText={["M0."]}
						includeDefaults={defaultDivFixedMach}
						isMach={diversionCruiseSpeed === "Fixed Mach"}
						defaultVals={[
							[
								diversionCruiseSpeed === "Fixed Mach"
									? divFixedMachData
										? divFixedMachData
										: parseFloat(normalOperatingData.normalOperatingMach) >= 0
										? normalOperatingData.normalOperatingMach
										: ""
									: "",
							],
						]}
						value={chooseDiversionFixedMach}
						disabledBoolList={[diversionCruiseSpeed !== "Fixed Mach"]}
						width="full"
						regex={/^(?:[5-9]|\d{2})?$/}
					/>
				</div>
				<UtilityClass.InputTable
					rowNum={1}
					colNum={1}
					captionBool={true}
					label="Diversion Descent speed"
					placeholder={["Please select"]}
					dropdown={true}
					options={[["Low", "Default", "High"]]}
					internalTitle={["Please select"]}
					value={chooseDiversionDescentSpeed}
					includeDefaults={defaultDivDesc}
					defaultVals={defaultDivDesc ? divDescData : "Default"}
					width="36"
				/>
			</div>
			{packageLevel === "Premium" && (
				<>
					<UtilityClass.Separator />
					<UtilityClass.CustomText
						textSize="large"
						fontWeight="semibold"
						color="grey"
						content={`Aircraft #2: ${acType2}`}
					/>
					<div className="grid justify-center lg:grid-cols-4 md:grid-cols-1 w-6/12 mx-auto">
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Climb speed"
							placeholder={["Please select"]}
							dropdown={true}
							options={[["Low", "Default", "High"]]}
							internalTitle={["Please select"]}
							width="36"
							value={chooseClimbSpeedTwo}
							defaultVals={defaultClimbSpeedTwo ? climbSpeedDataTwo : "Default"}
							includeDefaults={defaultClimbSpeedTwo}
						/>
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Cruise speed*"
							placeholder={["Please select"]}
							dropdown={true}
							options={
								parseFloat(normalOperatingData2.normalOperatingSpeed) > 0
									? [
											[
												"Max Range Cruise",
												"Long Range Cruise",
												"Fixed Mach",
												normalOperatingData2.normalOperatingSpeed + " KTS",
											],
									  ]
									: [["Max Range Cruise", "Long Range Cruise", "Fixed Mach"]]
							}
							internalTitle={["Please select"]}
							value={chooseCruiseSpeedTwo}
							includeDefaults={defaultCruiseSpeedTwo}
							defaultVals={
								defaultCruiseSpeedTwo ? cruiseSpeedDataTwo : "Long Range Cruise"
							}
							width="36"
						/>
						<div className="fixed-mach-box justify-center mx-auto flex align-center">
							<UtilityClass.InputTable
								rowNum={1}
								colNum={1}
								captionBool={true}
								label="Fixed Mach"
								placeholder={["From"]}
								leftSquareLabelBool={[true]}
								leftSquareLabelText={["M0."]}
								includeDefaults={defaultFixedMachTwo}
								isMach={cruiseSpeedTwo === "Fixed Mach"}
								defaultVals={[
									[
										cruiseSpeedTwo === "Fixed Mach"
											? fixedMachDataTwo
												? fixedMachDataTwo
												: parseFloat(
														normalOperatingData2.normalOperatingMach
												  ) >= 0
												? normalOperatingData2.normalOperatingMach
												: ""
											: "",
									],
								]}
								value={chooseFixedMachTwo}
								disabledBoolList={[cruiseSpeedTwo !== "Fixed Mach"]}
								width="full"
								regex={/^(?:[5-9]|\d{2})?$/}
							/>
						</div>
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Descent speed"
							placeholder={["Please select"]}
							dropdown={true}
							options={[["Low", "Default", "High"]]}
							internalTitle={["Please select"]}
							value={chooseDescentSpeedTwo}
							includeDefaults={defaultDescentDataTwo}
							defaultVals={defaultDescentDataTwo ? descentDataTwo : "Default"}
							width="36"
						/>
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Diversion Climb speed"
							placeholder={["Please select"]}
							dropdown={true}
							options={[["Low", "Default", "High"]]}
							internalTitle={["Please select"]}
							value={chooseDiversionclimbSpeedTwo}
							includeDefaults={defaultDivClimbTwo}
							defaultVals={defaultDivClimbTwo ? divClimbDataTwo : "Default"}
							width="36"
						/>
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Diversion Cruise speed*"
							placeholder={["Please select"]}
							dropdown={true}
							options={
								parseFloat(normalOperatingData2.normalOperatingSpeed) > 0
									? [
											[
												"Max Range Cruise",
												"Long Range Cruise",
												"Fixed Mach",
												normalOperatingData2.normalOperatingSpeed + " KTS",
											],
									  ]
									: [["Max Range Cruise", "Long Range Cruise", "Fixed Mach"]]
							}
							internalTitle={["Please select"]}
							value={chooseDiversionCruiseSpeedTwo}
							includeDefaults={defaultDivCruiseTwo}
							defaultVals={
								defaultDivCruiseTwo ? divCruiseDataTwo : "Long Range Cruise"
							}
							width="36"
						/>
						<div className="fixed-mach-box justify-center mx-auto flex align-center">
							<UtilityClass.InputTable
								rowNum={1}
								colNum={1}
								captionBool={true}
								label="Fixed Mach"
								placeholder={["From"]}
								leftSquareLabelBool={[true]}
								leftSquareLabelText={["M0."]}
								includeDefaults={defaultDivFixedMach}
								isMach={diversionCruiseSpeedTwo === "Fixed Mach"}
								defaultVals={[
									[
										diversionCruiseSpeedTwo === "Fixed Mach"
											? divFixedMachDataTwo
												? divFixedMachDataTwo
												: parseFloat(
														normalOperatingData2.normalOperatingMach
												  ) >= 0
												? normalOperatingData2.normalOperatingMach
												: ""
											: "",
									],
								]}
								value={chooseDiversionFixedMachTwo}
								disabledBoolList={[diversionCruiseSpeedTwo !== "Fixed Mach"]}
								width="full"
								regex={/^(?:[5-9]|\d{2})?$/}
							/>
						</div>
						<UtilityClass.InputTable
							rowNum={1}
							colNum={1}
							captionBool={true}
							label="Diversion Descent speed"
							placeholder={["Please select"]}
							dropdown={true}
							options={[["Low", "Default", "High"]]}
							internalTitle={["Please select"]}
							value={chooseDiversionDescentSpeedTwo}
							includeDefaults={defaultDivDescTwo}
							defaultVals={defaultDivDescTwo ? divDescDataTwo : "Default"}
							width="36"
						/>
					</div>
				</>
			)}
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={"*When selecting Fixed Mach please enter value"}
			/>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={
					packageLevel === "Basic"
						? "/forms/" + packageType + "/RoutesAndDistances"
						: "/forms/" + packageType + "/RoutesAndDistances2"
				}
				nextLink={"/forms/" + packageType + "/AirportConditions"}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
			/>
		</motion.div>
	);
};

export default EnvironmentalConditions;
