import React, { useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectEconAircraftInsurance,
	selectAircraftOwnershipChoice,
	selectLocation,
	selectAircraftAcquisition2,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import LeaseRate2 from "./LeaseRate2";
import FinanceAssumptions2 from "./FinanceAssumptions2";

const FixedOperatingCost2 = (props) => {
	// This component is a multi step/page component that will render the various forms for economic numbers per AC (follow live forms)

	let location = useLocation();

	let data = useSelector((state) => state.econData);
	let acType2 = data.acType2;
	let aircraftInsuranceSelector = data.aircraftInsurance2;
	let insurance = data.aircraftInsurance2;
	let ownership = data.aircraftOwnership2;
	let acquisitionPrice = data.aircraftAcquisition2;
	let prevOwnership = data.aircraftOwnership1;
	let prevLocation = data.location;

	let insuranceData = data.insuranceData;
	let acquisitionData = data.acquisitionPriceData.find((value) => {
		if (acType2.length > 0) {
			let current = acType2.split(",");
			return (
				value.engineType === current[1].trim() &&
				value.aircraftType === current[0].trim()
			);
		} else {
			return true;
		}
	});

	const [defaultInsurance, setDefaultInsurance] = useState(
		insurance === undefined || insurance.length === 0 ? false : true
	);

	const [defaultOwnership, setDefaultOwnership] = useState(
		ownership === undefined || ownership.length === 0 ? false : true
	);

	const [defaultAcquisitionPrice, setDefaultAcquisitionPrice] = useState(
		acquisitionPrice === undefined || acquisitionPrice.length === 0
			? false
			: true
	);

	const dispatch = useDispatch();
	const [aircraftInsurance, setAircraftInsurance] = useState(insurance);
	const chooseAircraftInsurance = (v) => {
		setAircraftInsurance(v);
	};
	const [aircraftOwnership, setAircraftOwnership] = useState(ownership);
	const chooseAircraftOwnership = (v) => {
		setAircraftOwnership(v);
	};

	const [aircraftAcquisitionPrice, setAircraftAcquisitionPrice] =
		useState(acquisitionPrice);

	useEffect(() => {
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
	}, []);

	const dispatchInputs = () => {
		dispatch(selectLocation(location));
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
		dispatch(selectAircraftOwnershipChoice(handleDispatchOwnership()));
		dispatch(selectAircraftAcquisition2(aircraftAcquisitionPrice));
	};

	const handleDispatchInsurance = () => {
		return { aircraftNbr: 2, aircraftInsurance: aircraftInsurance };
	};

	const handleDispatchOwnership = () => {
		return { aircraftNbr: 2, aircraftOwnership: aircraftOwnership };
	};

	useEffect(() => {
		console.log(aircraftOwnership);
	});

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	// THIS IS CALLING THE PERFORMANCE AC TYPES. NEED TO FIX THIS IN MERGE TO MAIN ========================================

	const validateContinue = () => {
		let validated = false;
		if (aircraftAcquisitionPrice != "" && aircraftInsurance != "") {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="medium"
				fontWeight="semibold"
				color="grey"
				content={`${`Aircraft #2: ${acType2}`}`}
			/>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="black"
				content={"Fixed Operating Cost: Continued"}
			/>
			<UtilityClass.Separator />
			<div className="flex justify-center">
				<UtilityClass.RadioButtons
					title="Aircraft Ownership"
					text={["Lease", "Finance"]}
					row={true}
					value={chooseAircraftOwnership}
					horizontal={true}
					defaultValue={defaultOwnership ? ownership : "Finance"}
				/>
			</div>
			{aircraftOwnership == "Lease" ? (
				<>
					<LeaseRate2 aircraftOwnership={aircraftOwnership} />
				</>
			) : (
				<>
					<FinanceAssumptions2 aircraftOwnership={aircraftOwnership} />
				</>
			)}
		</motion.div>
	);
};

export default FixedOperatingCost2;
