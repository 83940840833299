const Airbus = [
  {
    acID: "A318-100, CFM56-5",
    ACType: "A318-100",
    Engine: "CFM56-5",
    Seats: 110,
    "MTOW (KG/Lbs)": 68000,
    "MLW (KG/Lbs)": 57500,
    "OWE (KG/Lbs)": 39700,
    "Max Payload (KG/Lbs)": 14800,
  },
  {
    acID: "A319-100, CFM56-5A4",
    ACType: "A319-100",
    Engine: "CFM56-5A4",
    Seats: 124,
    "MTOW (KG/Lbs)": 75500,
    "MLW (KG/Lbs)": 62500,
    "OWE (KG/Lbs)": 41000,
    "Max Payload (KG/Lbs)": 17500,
  },
  {
    acID: "A320-200, CFM56-5A1",
    ACType: "A320-200",
    Engine: "CFM56-5A1",
    Seats: 150,
    "MTOW (KG/Lbs)": 73500,
    "MLW (KG/Lbs)": 64500,
    "OWE (KG/Lbs)": 42100,
    "Max Payload (KG/Lbs)": 18900,
  },
  {
    acID: "A320-200, IAE V2527",
    ACType: "A320-200",
    Engine: "IAE V2527",
    Seats: 150,
    "MTOW (KG/Lbs)": 78000,
    "MLW (KG/Lbs)": 66000,
    "OWE (KG/Lbs)": 42900,
    "Max Payload (KG/Lbs)": 19600,
  },
  {
    acID: "A321-200, CFM56-5B3",
    ACType: "A321-200",
    Engine: "CFM56-5B3",
    Seats: 185,
    "MTOW (KG/Lbs)": 89000,
    "MLW (KG/Lbs)": 75500,
    "OWE (KG/Lbs)": 48800,
    "Max Payload (KG/Lbs)": 22700,
  },
  {
    acID: "A321-200, LEAP-1A32",
    ACType: "A321-200",
    Engine: "LEAP-1A32",
    Seats: 185,
    "MTOW (KG/Lbs)": 89000,
    "MLW (KG/Lbs)": 77300,
    "OWE (KG/Lbs)": 50300,
    "Max Payload (KG/Lbs)": 23000,
  },
  {
    acID: "A330-200, CF6-80E1A3",
    ACType: "A330-200",
    Engine: "CF6-80E1A3",
    Seats: 250,
    "MTOW (KG/Lbs)": 233000,
    "MLW (KG/Lbs)": 182000,
    "OWE (KG/Lbs)": 120000,
    "Max Payload (KG/Lbs)": 49000,
  },
  {
    acID: "A330-300, Trent 772B",
    ACType: "A330-300",
    Engine: "Trent 772B",
    Seats: 335,
    "MTOW (KG/Lbs)": 233000,
    "MLW (KG/Lbs)": 187000,
    "OWE (KG/Lbs)": 121870,
    "Max Payload (KG/Lbs)": 36800,
  },
  {
    acID: "A340-300, CFM56-5C4",
    ACType: "A340-300",
    Engine: "CFM56-5C4",
    Seats: 335,
    "MTOW (KG/Lbs)": 276500,
    "MLW (KG/Lbs)": 190000,
    "OWE (KG/Lbs)": 130181,
    "Max Payload (KG/Lbs)": 47819,
  },
  // {
  //     "acID": "A340-600, Trent 556",
  //     "ACType": "A340-600",
  //     "Engine": "Trent 556",
  //     "Seats": 306,
  //     "MTOW (KG/Lbs)": 368000,
  //     "MLW (KG/Lbs)": 259000,
  //     "OWE (KG/Lbs)": 177800,
  //     "Max Payload (KG/Lbs)": 67200
  // },
  {
    acID: "A350-900, Trent XWB",
    ACType: "A350-900",
    Engine: "Trent XWB",
    Seats: 306,
    "MTOW (KG/Lbs)": 268000,
    "MLW (KG/Lbs)": 205000,
    "OWE (KG/Lbs)": 142240,
    "Max Payload (KG/Lbs)": 47760,
  },
];

const Boeing = [
  // {
  //     "acID": "B737-600, CFM56-7B22",
  //     "ACType": "B737-600",
  //     "Engine": "CFM56-7B22",
  //     "Seats":145,
  //     "MTOW (KG/Lbs)":65544,
  //     "MLW (KG/Lbs)":55112,
  //     "OWE (KG/Lbs)":36378,
  //     "Max Payload (KG/Lbs)":15331
  // },
  {
    acID: "B737-700, CFM56-7B24",
    ACType: "B737-700",
    Engine: "CFM56-7B24",
    Seats: 145,
    "MTOW (KG/Lbs)": 70080,
    "MLW (KG/Lbs)": 58604,
    "OWE (KG/Lbs)": 37648,
    "Max Payload (KG/Lbs)": 17554,
  },
  {
    acID: "B737-800, CFM56-7B26",
    ACType: "B737-800",
    Engine: "CFM56-7B26",
    Seats: 186,
    "MTOW (KG/Lbs)": 79016,
    "MLW (KG/Lbs)": 66361,
    "OWE (KG/Lbs)": 41413,
    "Max Payload (KG/Lbs)": 21319,
  },
  {
    acID: "B737-800, CFM56-7B27",
    ACType: "B737-800",
    Engine: "CFM56-7B27",
    Seats: 186,
    "MTOW (KG/Lbs)": 79016,
    "MLW (KG/Lbs)": 66361,
    "OWE (KG/Lbs)": 41413,
    "Max Payload (KG/Lbs)": 21319,
  },
  {
    acID: "B737-900, CFM56-7B26",
    ACType: "B737-900",
    Engine: "CFM56-7B26",
    Seats: 192,
    "MTOW (KG/Lbs)": 85140,
    "MLW (KG/Lbs)": 71350,
    "OWE (KG/Lbs)": 43890,
    "Max Payload (KG/Lbs)": 23830,
  },
  {
    acID: "B747-400, CF6-80C2B1F",
    ACType: "B747-400",
    Engine: "CF6-80C2B1F",
    Seats: 416,
    "MTOW (KG/Lbs)": 362870,
    "MLW (KG/Lbs)": 260360,
    "OWE (KG/Lbs)": 182480,
    "Max Payload (KG/Lbs)": 60190,
  },
  {
    acID: "B757-200, RB211-535E4B",
    ACType: "B757-200",
    Engine: "RB211-535E4B",
    Seats: 200,
    "MTOW (KG/Lbs)": 98880,
    "MLW (KG/Lbs)": 89800,
    "OWE (KG/Lbs)": 58390,
    "Max Payload (KG/Lbs)": 25970,
  },
  // {
  //     "acID": "B747-400, RB211-524G-T",
  //     "ACType": "B747-400",
  //     "Engine": "RB211-524G-T",
  //     "Seats": 524,
  //     "MTOW (KG/Lbs)": 396900,
  //     "MLW (KG/Lbs)": 295740,
  //     "OWE (KG/Lbs)": 181120,
  //     "Max Payload (KG/Lbs)": 70620
  // },
  // {
  //     "acID": "B747-8L, GEnx-2B67",
  //     "ACType": "B747-8L",
  //     "Engine": "GEnx-2B67",
  //     "Seats": 467,
  //     "MTOW (KG/Lbs)": 447696,
  //     "MLW (KG/Lbs)": 312072,
  //     "OWE (KG/Lbs)": 220128,
  //     "Max Payload (KG/Lbs)": 75161
  // },
  // {
  //     "acID": "B767-200, JT9D-7R4",
  //     "ACType": "B767-200",
  //     "Engine": "JT9D-7R4",
  //     "Seats": 224,
  //     "MTOW (KG/Lbs)": 142882,
  //     "MLW (KG/Lbs)": 123377,
  //     "OWE (KG/Lbs)": 80127,
  //     "Max Payload (KG/Lbs)": 33371
  // },
  {
    acID: "B767-300ER, CF6-80C2B7F",
    ACType: "B767-300ER",
    Engine: "CF6-80C2B7F",
    Seats: 269,
    "MTOW (KG/Lbs)": 186880,
    "MLW (KG/Lbs)": 145150,
    "OWE (KG/Lbs)": 90010,
    "Max Payload (KG/Lbs)": 43800,
  },
  // {
  //     "acID": "B767-400ER, CF6-80C2B8F",
  //     "ACType": "B767-400ER",
  //     "Engine": "CF6-80C2B8F",
  //     "Seats": 409,
  //     "MTOW (KG/Lbs)": 204117,
  //     "MLW (KG/Lbs)": 158757,
  //     "OWE (KG/Lbs)": 103870,
  //     "Max Payload (KG/Lbs)": 45815
  // },
  {
    acID: "B777-200ER, GE90-94B",
    ACType: "B777-200ER",
    Engine: "GE90-94B",
    Seats: 301,
    "MTOW (KG/Lbs)": 297550,
    "MLW (KG/Lbs)": 213180,
    "OWE (KG/Lbs)": 145150,
    "Max Payload (KG/Lbs)": 54970,
  },
  {
    acID: "B777-200LR, GE90-115BL",
    ACType: "B777-200LR",
    Engine: "GE90-115BL",
    Seats: 301,
    "MTOW (KG/Lbs)": 347450,
    "MLW (KG/Lbs)": 223160,
    "OWE (KG/Lbs)": 157070,
    "Max Payload (KG/Lbs)": 52030,
  },
  // {
  //     "acID": "B777-200LR, GE90-110B1",
  //     "ACType": "B777-200LR",
  //     "Engine": "GE90-110B1",
  //     "Seats": 400,
  //     "MTOW (KG/Lbs)": 347452,
  //     "MLW (KG/Lbs)": 223167,
  //     "OWE (KG/Lbs)": 145150,
  //     "Max Payload (KG/Lbs)": 63956
  // },
  {
    acID: "B777-300ER, GE90-115BL",
    ACType: "B777-300ER",
    Engine: "GE90-115BL",
    Seats: 365,
    "MTOW (KG/Lbs)": 351530,
    "MLW (KG/Lbs)": 251290,
    "OWE (KG/Lbs)": 168780,
    "Max Payload (KG/Lbs)": 68900,
  },
  {
    acID: "B787-10, GEnx-1B-76A",
    ACType: "B787-10",
    Engine: "GEnx-1B-76A",
    Seats: 302,
    "MTOW (KG/Lbs)": 254011,
    "MLW (KG/Lbs)": 201848,
    "OWE (KG/Lbs)": 135500,
    "Max Payload (KG/Lbs)": 57276,
  },
  {
    acID: "B787-8, GEnx-1B-64",
    ACType: "B787-8",
    Engine: "GEnx-1B-64",
    Seats: 264,
    "MTOW (KG/Lbs)": 227930,
    "MLW (KG/Lbs)": 172365,
    "OWE (KG/Lbs)": 119950,
    "Max Payload (KG/Lbs)": 41075,
  },
  {
    acID: "B787-9, GEnx-1B-74-75",
    ACType: "B787-9",
    Engine: "GEnx-1B-74-75",
    Seats: 302,
    "MTOW (KG/Lbs)": 254000,
    "MLW (KG/Lbs)": 192800,
    "OWE (KG/Lbs)": 128800,
    "Max Payload (KG/Lbs)": 52587,
  },
];

const MHI = [
  {
    acID: "CRJ200, CF34-3B1",
    ACType: "CRJ200",
    Engine: "CF34-3B1",
    Seats: 50,
    "MTOW (KG/Lbs)": 24041,
    "MLW (KG/Lbs)": 21319,
    "OWE (KG/Lbs)": 13835,
    "Max Payload (KG/Lbs)": 6123,
  },
  {
    acID: "CRJ700, CF34-8C5B1",
    ACType: "CRJ700",
    Engine: "CF34-8C5B1",
    Seats: 78,
    "MTOW (KG/Lbs)": 34019,
    "MLW (KG/Lbs)": 30391,
    "OWE (KG/Lbs)": 20069,
    "Max Payload (KG/Lbs)": 8190,
  },
  {
    acID: "CRJ900, CF34-8C5",
    ACType: "CRJ900",
    Engine: "CF34-8C5",
    Seats: 88,
    "MTOW (KG/Lbs)": 38330,
    "MLW (KG/Lbs)": 34029,
    "OWE (KG/Lbs)": 21845,
    "Max Payload (KG/Lbs)": 10247,
  },
  {
    acID: "CRJ1000, CF34-8C5A1",
    ACType: "CRJ1000",
    Engine: "CF34-8C5A1",
    Seats: 104,
    "MTOW (KG/Lbs)": 41640,
    "MLW (KG/Lbs)": 36968,
    "OWE (KG/Lbs)": 23188,
    "Max Payload (KG/Lbs)": 11966,
  },
];

const Embraer = [
  {
    acID: "ERJ145ER, AE3007A",
    ACType: "ERJ145ER",
    Engine: "AE3007A",
    Seats: 50,
    "MTOW (KG/Lbs)": 20600,
    "MLW (KG/Lbs)": 18700,
    "OWE (KG/Lbs)": 11947,
    "Max Payload (KG/Lbs)": 5153,
  },
  {
    acID: "E170, CF34-8E",
    ACType: "E170",
    Engine: "CF34-8E",
    Seats: 80,
    "MTOW (KG/Lbs)": 35990,
    "MLW (KG/Lbs)": 32800,
    "OWE (KG/Lbs)": 21040,
    "Max Payload (KG/Lbs)": 9100,
  },
  {
    acID: "E175-E2, PW1715G",
    ACType: "E175-E2",
    Engine: "PW1715G",
    Seats: 88,
    "MTOW (KG/Lbs)": 44800,
    "MLW (KG/Lbs)": 40000,
    "OWE (KG/Lbs)": 25680,
    "Max Payload (KG/Lbs)": 10600,
  },
  {
    acID: "E190AR, CF34-10E",
    ACType: "E190AR",
    Engine: "CF34-10E",
    Seats: 100,
    "MTOW (KG/Lbs)": 51800,
    "MLW (KG/Lbs)": 44000,
    "OWE (KG/Lbs)": 27837,
    "Max Payload (KG/Lbs)": 13063,
  },
];

const DHC = [
  {
    acID: "DHC 8-400, PW150A",
    ACType: "DHC 8-400",
    Engine: "PW150A",
    Seats: 78,
    "MTOW (KG/Lbs)": 29574,
    "MLW (KG/Lbs)": 28123,
    "OWE (KG/Lbs)": 17790,
    "Max Payload (KG/Lbs)": 8518,
  },
];

const ATR = [
  {
    acID: "ATR 42-500, PW127E",
    ACType: "ATR 42-500",
    Engine: "PW127E",
    Seats: 48,
    "MTOW (KG/Lbs)": 18600,
    "MLW (KG/Lbs)": 18300,
    "OWE (KG/Lbs)": 11250,
    "Max Payload (KG/Lbs)": 5450,
  },
  {
    acID: "ATR 42-320, PW121",
    ACType: "ATR 42-320",
    Engine: "PW121",
    Seats: 48,
    "MTOW (KG/Lbs)": 16900,
    "MLW (KG/Lbs)": 16400,
    "OWE (KG/Lbs)": 10900,
    "Max Payload (KG/Lbs)": 4640,
  },
  {
    acID: "ATR 72-500, PW127F",
    ACType: "ATR 72-500",
    Engine: "PW127F",
    Seats: 68,
    "MTOW (KG/Lbs)": 22500,
    "MLW (KG/Lbs)": 22350,
    "OWE (KG/Lbs)": 12950,
    "Max Payload (KG/Lbs)": 7200,
  },
];

export const AircraftInfo = {
  Airbus: Airbus,
  Boeing: Boeing,
  "MHI RJ": MHI,
  Embraer: Embraer,
  "De Havilland": DHC,
  ATR: ATR,
};
