import React, { useEffect, useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { selectSubmission } from "../../reducers/performanceDataSlice";
import { selectEconSubmission } from "../../reducers/econDataSlice";
import { setDemandSubmission } from "../../reducers/demandDataSlice";

const Submission = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(true);
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let submissionTable;
	let data = useSelector((state) => {
		if (packageType === "Performance") {
			submissionTable = "perforder";
			return state.performanceData;
		} else if (packageType === "Economic") {
			submissionTable = "econorder";
			return state.econData;
		} else {
			submissionTable = "demandorder";
			return state.demandData;
		}
	});

	console.log(data);

	const handleSubmit = (e) => {
		e.preventDefault();

		inputs.firstName = firstName;
		inputs.lastName = lastName;
		inputs.company = company;
		inputs.phone = phoneNumber;
		inputs.email = emailAddress;
		console.log(inputs);

		if (packageType === "Performance") {
			dispatch(selectSubmission(inputs));
		} else if (packageType === "Economic") {
			dispatch(selectEconSubmission(inputs));
		} else {
			dispatch(setDemandSubmission(inputs));
		}

		if (
			
			inputs.firstName !== "" &&
			inputs.firstName !== undefined &&
			inputs.lastName !== "" &&
			inputs.lastName !== undefined &&
			inputs.company !== "" &&
			inputs.company !== undefined &&
			inputs.phone !== "" &&
			inputs.phone !== undefined &&
			inputs.email !== "" &&
			inputs.email !== undefined
			// ======================
			// inputs.firstName == "" ||
			// inputs.firstName == undefined ||
			// inputs.lastName == "" ||
			// inputs.lastName == undefined ||
			// inputs.company == "" ||
			// inputs.company == undefined ||
			// inputs.phoneNumber == "" ||
			// inputs.phoneNumber == undefined ||
			// inputs.email == "" ||
			// inputs.email == undefined
		) {
			let orderNum = data.orderNumber;
			let id = data.orderID.toString();

			const { orderNumber, orderID, ...newData } = data;
			const stringified = Object.assign(
				{ id: id, orderNumber: orderNum },
				Object.fromEntries(
					Object.entries(newData).map((value, index) => {
						if (value[1] === undefined) {
							return [value[0], "undefined"];
						} else {
							return [value[0], JSON.stringify(value[1])];
						}
					})
				)
			);
			console.log(stringified);
			setIsLoading(true);
			fetch(
				`${
					process.env.REACT_APP_STAGE === "production"
						? process.env.REACT_APP_SERVER_URL_PROD
						: process.env.REACT_APP_SERVER_URL_DEV
				}/submitOrder`,
				{
					method: "POST",
					mode: "cors",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						orderID: orderID,
						table: submissionTable,
					}),
				}
			)
				.then((response) => response.json())
				.then((json) => {
					if (json.status) {
						if (json.status === "SUBMITTED") {
							fetch(
								`${
									process.env.REACT_APP_STAGE === "production"
										? process.env.REACT_APP_SERVER_URL_PROD
										: process.env.REACT_APP_SERVER_URL_DEV
								}/prepareInsert`,
								{
									method: "POST",
									mode: "cors",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify({
										orderID: orderID,
										table: submissionTable,
									}),
								}
							)
								.then((response) => response.json())
								.then((json) => {
									if (json.status) {
										if (json.status === "DELETED") {
											fetch(
												`${
													process.env.REACT_APP_STAGE === "production"
														? process.env.REACT_APP_SERVER_URL_PROD
														: process.env.REACT_APP_SERVER_URL_DEV
												}/sendEmailNotification?${"type=" + packageType}`,
												{
													method: "POST",
													mode: "cors",
													headers: {
														"Content-Type": "application/json",
													},
													body: JSON.stringify({
														data: [stringified, packageType, packageLevel],
													}),
													// JSON.stringify({
													// 	data: [
													// 		stringified,
													// 		packageType,
													// 		packageLevel,
													// 		// JSON.stringify(stringified),
													// 		// JSON.stringify(packageType),
													// 		// JSON.stringify(packageLevel),
													// 		// JSON.stringify(htmlDOMSubmission),
													// 	],
													// }),
												}
											);
											fetch(
												`${
													process.env.REACT_APP_STAGE === "production"
														? process.env.REACT_APP_SERVER_URL_PROD
														: process.env.REACT_APP_SERVER_URL_DEV
												}/submissionInsert?${"table=" + submissionTable}`,
												{
													method: "POST",
													mode: "cors",
													headers: {
														"Content-Type": "application/json",
													},
													body: JSON.stringify(stringified),
												}
											)
												.then((response) => response.json())
												.then((json) => {
													if (json.status === "Error") {
														console.log(data);
														setIsSubmitted(false);
														console.log("ERROR WHILE SUBMITTING");
													} else {
														setIsSubmitted(true);
														navigate(`/forms/${packageType}/Thanks`, {
															replace: true,
														});
													}
													setIsLoading(false);
												});
										}
									}
								});
						}
					}
				});
		} else {
			alert("Please fill in all fields!");
		}
	};

	useEffect(() => {
		console.log(isSubmitted);
	}, [isSubmitted]);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [company, setCompany] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [emailAddress, setEmailAddress] = useState("");

	const firstNameData = data.submission?.firstName;
	const lastNameData = data.submission?.lastName;
	const companyData = data.submission?.company;
	const phoneNumberData = data.submission?.phone;
	const emailData = data.submission?.email;

	const [defaultFirstName, setDefaultFirstName] = useState(
		firstNameData === undefined || firstNameData.length === 0 ? false : true
	);
	const [defaultLastName, setDefaultLastName] = useState(
		lastNameData === undefined || lastNameData.length === 0 ? false : true
	);
	const [defaultCompany, setDefaultCompany] = useState(
		companyData === undefined || companyData.length === 0 ? false : true
	);
	const [defaultPhone, setDefaultPhone] = useState(
		phoneNumberData === undefined || phoneNumberData.length === 0 ? false : true
	);
	const [defaultEmail, setDefaultEmail] = useState(
		emailData === undefined || emailData.length === 0 ? false : true
	);

	let inputs = {
		firstName: undefined,
		lastName: undefined,
		company: undefined,
		phone: undefined,
		email: undefined,
	};

	const changeFirstName = (v) => {
		setFirstName(v);
		// inputs.firstName = v;
	};
	const changeLastName = (v) => {
		setLastName(v);
		// inputs.lastName = v;
	};
	const changeCompany = (v) => {
		setCompany(v);
		// inputs.company = v;
	};
	const changePhone = (v) => {
		setPhoneNumber(v);
		// inputs.phone = v;
	};
	const changeEmail = (v) => {
		setEmailAddress(v);
		// inputs.email = v;
	};

	// need to add dispatch inputs here as well

	useEffect(() => {
		dispatchSubmissionUpdates(firstName);
	}, [firstName, lastName, company, phoneNumber, emailAddress]);

	const dispatchSubmissionUpdates = (field) => {
		console.log(field);
		inputs.firstName = firstName;
		inputs.lastName = lastName;
		inputs.company = company;
		inputs.phone = phoneNumber;
		inputs.email = emailAddress;
		console.log(inputs);
		if (packageType === "Performance") {
			dispatch(selectSubmission(inputs));
		} else if (packageType === "Economic") {
			dispatch(selectEconSubmission(inputs));
		} else {
			dispatch(setDemandSubmission(inputs));
		}
	};
	// dispatchSubmissionUpdates(firstName);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				bottomMargin={true}
				color="red"
				content={"Ready to submit"}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				bottomMargin={true}
				color="black"
				content={"Please confirm your contact details before submitting"}
			/>
			<div className="flex justify-center">
				<div className="flex flex-col w-full justify-center">
					<UtilityClass.SubmissionForm
						firstName={changeFirstName}
						lastName={changeLastName}
						company={changeCompany}
						phone={changePhone}
						email={changeEmail}
						defaultFirstName={defaultFirstName ? firstNameData : undefined}
						defaultLastName={defaultLastName ? lastNameData : undefined}
						defaultCompany={defaultCompany ? companyData : undefined}
						defaultPhone={defaultPhone ? phoneNumberData : undefined}
						defaultEmail={defaultEmail ? emailData : undefined}
						package={packageType}
						handleSubmit={handleSubmit}
						orderNumber={data.orderNumber}
						isLoading={isLoading}
						validation={true}
					/>
					<div
						className={`flex w-full mt-5 justify-center transition-all ${
							isSubmitted ? "opacity-0" : "opacity-100"
						}`}
					>
						{!isSubmitted && (
							<div
								className={`w-1/2 h-full text-center transition font-medium`}
							>
								An error occurred while submitting the data. Please try again,
								or if the issue persists contact support immediately.
							</div>
						)}
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default Submission;
