import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: "Default",
};

export const packageTypeSlice = createSlice({
    name: "packageType",
    initialState,
    reducers: {
        chosePerformance: (state) => {
            state.value = "Performance";
            // console.log(state.value);
        },
        choseEconomic: (state) => {
            state.value = "Economic";
            // console.log(state.value);
        },
        choseDemand: (state) => {
            state.value = "Demand";
            // console.log(state.value);
        },
        resetType: (state) => {
            state.value = "Default";
            // console.log(state.value);
        },
    },
});

export const { chosePerformance, choseEconomic, choseDemand, resetType } =
    packageTypeSlice.actions;
export default packageTypeSlice.reducer;
