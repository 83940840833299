import React, { useEffect } from "react";
import PackageSelection from "../components/PackageSelection";
import { resetLevel } from "../reducers/packageLevelSlice";
import { resetType } from "../reducers/packageTypeSlice";
import { resetValidation } from "../reducers/inputReviewValidationSlice";
import { resetPerf } from "../reducers/performanceDataSlice";
import { resetEcon } from "../reducers/econDataSlice";
import { resetDemand } from "../reducers/demandDataSlice";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
// import { useParams } from "react-router-dom";

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(resetLevel());
        dispatch(resetType());
        dispatch(resetValidation());
        dispatch(resetPerf());
        dispatch(resetEcon());
        dispatch(resetDemand());
    }, []);

    return (
        <motion.div>
            <PackageSelection />
        </motion.div>
    );
};
export default Home;
