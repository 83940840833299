import React, { useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const InputReviewEcon = (props) => {
	// This is a summary of all the inputs. The data will be taken from the redux states.
	// Rendering of information will be based on package level that is passed in from parent.
	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let econData = useSelector((state) => state.econData);
	let routeData = useSelector((state) => state.econData.RnD.iataAirportCode);

	const [validationChecked, setValidationChecked] = useState(false);
	let tempValidationChecked = false;
	const tempValidationFunction = (type) => {
		tempValidationChecked = type;
		setValidationChecked(type);
	};

	const allEqual = (arr) => arr.every((val) => val === arr[0]);

	const decimalPlaces = (num) => {
		if (num) {
			switch (num.toString().split(".")[0].length) {
				case 1:
					return num.toString().slice(0, 4);
				case 2:
					return num.toString().slice(0, 4);
				case 3:
					return num.toString().slice(0, 6);
				case 4:
					return num.toString().slice(0, 6);
				default:
					return num.toString().slice(0, 4);
			}
		}
	};

	const generateNavRows = () => {
		const fillRows = (arr) => {
			if (
				arr.aircraftHandlingRate &&
				arr.aircraftHandlingUnit &&
				arr.landingRate &&
				arr.landingUnit &&
				arr.passengerHandling &&
				arr.routeNavigationUnit &&
				arr.routeNavigationRate &&
				arr.termNavigationRate &&
				arr.termNavigationUnit
			) {
				let filledLandingUnit = Array(60).fill("-");
				arr.landingUnit.forEach((value) => {
					filledLandingUnit[value[1][0]] = value[0];
				});

				let filledAircraftHandlingUnit = Array(60).fill("-");
				arr.aircraftHandlingUnit.forEach((value) => {
					filledAircraftHandlingUnit[value[1][0]] = value[0];
				});
				let filledTerminalNavUnit = Array(60).fill("-");
				arr.termNavigationUnit.forEach((value) => {
					filledTerminalNavUnit[value[1][0]] = value[0];
				});

				let filledRouteNavUnit = Array(60).fill("-");
				arr.routeNavigationUnit.forEach((value) => {
					filledRouteNavUnit[value[1][0]] = value[0];
				});

				let rows = Array(10).fill(Array(6).fill("-"));

				for (let i = 0; i < arr.landingRate.length; i++) {
					let routeRow = [
						routeData[i][0] !== "" || routeData[i][2] !== ""
							? (routeData[i][0] + " - " + routeData[i][2]).toUpperCase()
							: "-",
					];

					let landingRateRow = [arr.landingRate[i]];
					let landingUnitRow = [filledLandingUnit[i]];
					let terminalRateRow = [arr.termNavigationRate[i]];
					let terminalUnitRow = [filledTerminalNavUnit[i]];
					let routeNavUnitRow = [arr.routeNavigationRate[i]];
					let routeNavRateRow = [filledRouteNavUnit[i]];
					let passHandlingRow = [arr.passengerHandling[i]];
					let aircraftRateRow = [arr.aircraftHandlingRate[i]];
					let aircraftUnitRow = [filledAircraftHandlingUnit[i]];
					rows[i] = routeRow
						.concat(landingUnitRow)
						.concat(landingRateRow)
						.concat(terminalUnitRow)
						.concat(terminalRateRow)
						.concat(routeNavRateRow)
						.concat(routeNavUnitRow)
						.concat(passHandlingRow)
						.concat(aircraftRateRow)
						.concat(aircraftUnitRow);
				}
				let filteredRows = [];
				rows.forEach((value) => {
					value.forEach((innerValue, index) => {
						if (innerValue === "") {
							value[index] = "-";
						}
					});
					if (!allEqual(value)) {
						filteredRows.push(value);
					}
				});
				return filteredRows;
			} else {
				return [];
			}
		};
		let finalArr = fillRows(econData.airportNavigationCharges).concat(
			fillRows(econData.airportNavigationChargesTwo)
		);
		return finalArr;
	};
	console.log(generateNavRows());

	console.log(econData.cashOperatingCosts);

	const prepareEconDataPt1 = () => {
		// console.log(econData.financeAssumptions1.aircraftPrice)
		let output = [
			[
				// [
				// 	"Aircraft insurance cost:",
				// 	`${econData.aircraftInsurance1 || "None"} USD per BH`,
				// ],
				["Aircraft ownership type:", `${econData.aircraftOwnership1}`],
				econData.leaseRate1
					? [
							"Aircraft lease rate:",
							`${econData.leaseRate1 || "None"} USD per month`,
					  ]
					: [],
				econData.financeAssumptions1.aircraftPrice
					? [
							"Finance assumption:",
							`${
								econData.financeAssumptions1.aircraftPrice || "None"
							} USD million, ${
								econData.financeAssumptions1.downPayment || "None"
							}% of DP`,
					  ]
					: [],
				econData.financeAssumptions1.interestRate
					? [
							"Finance interest rate:",
							`${econData.financeAssumptions1.interestRate || "None"}% for ${
								econData.financeAssumptions1.paymentTerm || "None"
							} Years`,
					  ]
					: [],
				econData.aircraftOwnership1 == "Finance"
					? [
							"Insurance assumptions:",
							`${
								econData.financeAssumptions1.marketValue || "None"
							} USD million, ${econData.aircraftInsurance1}% of Market Value`,
					  ]
					: [
							"Insurance assumptions:",
							`${econData.aircraftAcquisition || "None"} USD million, ${
								econData.aircraftInsurance1
							}% of Market Value`,
					  ],
			],
		];
		let output2 = [
			[
				[
					"Flight crew loading:",
					`${
						econData.crewLoading1.flightCrewLoading.number || "None"
					} Per aircraft`,
				],
				[
					"Pilot salary:",
					`${
						econData.crewLoading1.flightCrewLoading.pilotSalary || "None"
					} USD`,
				],
				[
					"Pilot benefits:",
					`${
						econData.crewLoading1.flightCrewLoading.pilotYearlyBenefit || "None"
					}%`,
				],
				[
					"Co-Pilot salary:",
					`${
						econData.crewLoading1.flightCrewLoading.coPilotSalary || "None"
					}	 USD`,
				],
				[
					"Co-Pilot benefits:",
					`${
						econData.crewLoading1.flightCrewLoading.coPilotYearlyBenefit ||
						"None"
					}%`,
				],
			],
		];
		let output3 = [
			[
				[
					"Cabin crew loading:",
					`${
						econData.crewLoading1.cabinCrewLoading.number || "None"
					} Per aircraft`,
				],
				[
					"Flight attendant salary:",
					`${
						econData.crewLoading1.cabinCrewLoading.faSalaryPerYear || "None"
					} USD`,
				],
				[
					"Flight attendant benefits:",
					`${
						econData.crewLoading1.cabinCrewLoading.faYearlyBenefit || "None"
					}%`,
				],
				[
					"F/As per aircraft:",
					`${econData.crewLoading1.cabinCrewLoading.faPerAircraft || "None"}`,
				],
			],
		];
		let output4 = [
			[
				[
					"Pilot cost:",
					`${econData.crewLoading1.bhCosts.pilotBH || "None"} USD per BH`,
				],
				[
					"Co-Pilot cost:",
					`${econData.crewLoading1.bhCosts.coPilotBH || "None"} USD per BH`,
				],
				[
					"F/A cost:",
					`${econData.crewLoading1.bhCosts.faBH || "None"} USD per BH`,
				],
			],
		];
		return (
			<>
				<UtilityClass.InputReview title={true} bottomMargin={false} />
				<UtilityClass.CustomText
					textSize="medium"
					fontWeight="medium"
					color="grey"
					content={`Include fixed operating costs? ${econData.fixedOperatingCostChoice}`}
				/>
				<div className="-mb-4 mt-1">
					<UtilityClass.CustomText
						textSize="medium"
						fontWeight="semibold"
						color="black"
						content={`Aircraft #1 cost assumptions`}
						noMargin
					/>
				</div>
				<UtilityClass.InputReview sections={output} />
				<UtilityClass.InputReview sections={output2} />
				<UtilityClass.InputReview sections={output3} />
				<UtilityClass.InputReview sections={output4} />
			</>
		);
	};

	const prepareEconDataPt2 = () => {
		let output = [
			[
				// [
				// 	"Aircraft insurance cost:",
				// 	`${econData.aircraftInsurance2 || "None"} USD per BH`,
				// ],
				[
					"Aircraft ownership type:",
					`${econData.aircraftOwnership2 || "None"}`,
				],
				econData.leaseRate2
					? [
							"Aircraft lease rate:",
							`${econData.leaseRate2 || "None"} USD Per Month`,
					  ]
					: [],
				econData.financeAssumptions2.aircraftPrice
					? [
							"Finance assumption:",
							`${
								econData.financeAssumptions2.aircraftPrice || "None"
							} USD million, ${
								econData.financeAssumptions2.downPayment || "None"
							}% of DP`,
					  ]
					: [],
				econData.financeAssumptions2.interestRate
					? [
							"Finance interest rate:",
							`${econData.financeAssumptions2.interestRate || "None"}% for ${
								econData.financeAssumptions2.paymentTerm || "None"
							} Years`,
					  ]
					: [],
				econData.aircraftOwnership2 == "Finance"
					? [
							"Insurance assumptions:",
							`${
								econData.financeAssumptions2.marketValue || "None"
							} USD million, ${econData.aircraftInsurance2}% of Market Value`,
					  ]
					: [
							"Insurance assumptions:",
							`${econData.aircraftAcquisition2 || "None"} USD million, ${
								econData.aircraftInsurance2
							}% of Market Value`,
					  ],
			],
		];
		let output2 = [
			[
				[
					"Flight crew loading:",
					`${
						econData.crewLoading2.flightCrewLoading.number || "None"
					} Per aircraft`,
				],
				[
					"Pilot salary:",
					`${
						econData.crewLoading2.flightCrewLoading.pilotSalary || "None"
					} USD`,
				],
				[
					"Pilot benefits:",
					`${
						econData.crewLoading2.flightCrewLoading.pilotYearlyBenefit || "None"
					}%`,
				],
				[
					"Co-Pilot salary:",
					`${
						econData.crewLoading2.flightCrewLoading.coPilotSalary || "None"
					}	 USD`,
				],
				[
					"Co-Pilot benefits:",
					`${
						econData.crewLoading2.flightCrewLoading.coPilotYearlyBenefit ||
						"None"
					}%`,
				],
			],
		];
		let output3 = [
			[
				[
					"Cabin crew loading:",
					`${
						econData.crewLoading2.cabinCrewLoading.number || "None"
					} Per aircraft`,
				],
				[
					"Flight attendant salary:",
					`${
						econData.crewLoading2.cabinCrewLoading.faSalaryPerYear || "None"
					} USD`,
				],
				[
					"Flight attendant benefits:",
					`${
						econData.crewLoading2.cabinCrewLoading.faYearlyBenefit || "None"
					}%`,
				],
				[
					"F/As per aircraft:",
					`${econData.crewLoading2.cabinCrewLoading.faPerAircraft || "None"}`,
				],
			],
		];
		let output4 = [
			[
				[
					"Pilot cost:",
					`${econData.crewLoading2.bhCosts.pilotBH || "None"} USD per BH`,
				],
				[
					"Co-Pilot cost:",
					`${econData.crewLoading2.bhCosts.coPilotBH || "None"} USD per BH`,
				],
				[
					"F/A cost:",
					`${econData.crewLoading2.bhCosts.faBH || "None"} USD per BH`,
				],
			],
		];
		return (
			<>
				<div className="-mb-4 mt-6">
					{packageLevel === "Premium" && (
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="semibold"
							color="gray"
							content={`Aircraft #2 cost assumptions:`}
							noMargin
						/>
					)}
				</div>
				<UtilityClass.InputReview sections={output} />
				<UtilityClass.InputReview sections={output2} />
				<UtilityClass.InputReview sections={output3} />
				<UtilityClass.InputReview sections={output4} />
			</>
		);
	};

	const prepareEconDataPt3 = () => {
		return (
			<>
				{/* <UtilityClass.InputReview sections={output} />
				<UtilityClass.InputReview sections={output2} />
				<UtilityClass.InputReview sections={output3} /> */}
			</>
		);
	};

	const prepareEconDataPt4 = () => {
		let output = [
			[
				["Selected Region:", `${econData.region || "None"}`],
				[
					"Fuel cost:",
					`${
						decimalPlaces(econData.cashOperatingCosts.fuelPrice) || "None"
					} USD ${econData.cashOperatingCosts.fuelPriceUnit || "None"}`,
				],
			],
		];
		return (
			<>
				<div className="mt-2" />
				<UtilityClass.InputReview sections={output} bottomMargin={false} />
			</>
		);
	};

	const navRows = generateNavRows();
	const prepareEconDataPt5 = () => {
		let output = [
			[
				[
					"IATA charges manual option:",
					`${econData.airportNavigationChoice || "None"}`,
				],
			],
		];
		return (
			navRows &&
			navRows.length > 0 && (
				<>
					<UtilityClass.InputReview sections={output} bottomMargin={false} />
					<div className="flex justify-center">
						<div className="w-9/12">
							<br />
							<UtilityClass.CustomText
								content="Summary of Custom Inputs"
								textSize="large"
								fontWeight="semibold"
							/>
							<UtilityClass.DefaultTable
								headerList={[
									"Route",
									"Airport Landing Formula",
									"Airport Landing Rate (USD)",
									"Terminal Navigation Formula",
									"Terminal Navigation Rate (USD)",
									"En Route Navigation Formula",
									"En Route Navigation Rate (USD)",
									"Per PAX Handling Cost (USD)",
									"Aircraft Handling Unit Cost (USD)",
									"Aircraft Handling Units",
								]}
								rows={navRows}
							/>
						</div>
					</div>
				</>
			)
		);
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			{prepareEconDataPt1()}
			{packageLevel === "Premium" && prepareEconDataPt2()}
			{prepareEconDataPt3()}
			{prepareEconDataPt4()}
			{prepareEconDataPt5()}

			<UtilityClass.BackNextButtons
				hideTextBox={true}
				nextButtonText="VALIDATION COMPLETE"
				backLink={"/forms/" + packageType + "/InputReview"}
				nextLink={"/forms/" + packageType + "/LogoUpload"}
				validation={validationChecked}
				validationButton={true}
				value={tempValidationFunction}
				required={true}
			/>
		</motion.div>
	);
};

export default InputReviewEcon;
