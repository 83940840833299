import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import { Pages } from "../constants/Pages";
import DefaultLayout from "../layout/DefaultLayout";
import { EconomicForm } from "../constants/EconomicForm";
import NotFound from "../components/commonAll/NotFound";
import { PerformanceForm } from "../constants/PerformanceForm";
import { DemandForm } from "../constants/DemandForm";
import Error from "../components/commonAll/Error";

const router = (validSession, packageType) => {
    switch (packageType) {
        case "Performance":
            return createBrowserRouter(
                createRoutesFromElements(
                    <>
                        <Route
                            key={"perfRoute"}
                            path=""
                            element={<DefaultLayout />}
                            errorElement={<Error />}
                        >
                            <Route errorElement={<NotFound />} />

                            <Route
                                path="/forms/"
                                key={"homeRoute"}
                                element={<Pages.Home />}
                            />
                            {/* PERF ROUTES */}
                            <Route
                                key={"perfRoute"}
                                path="/forms/Performance/"
                                element={<Pages.Performance />}
                            >
                                <Route
                                    path=""
                                    element={
                                        validSession ? (
                                            <PerformanceForm.SelectOEM />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* this is for premium */}
                                <Route
                                    path="OEM2"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.SelectOEM2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="AircraftEngine"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.AircraftEngine />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* this is for premium */}
                                <Route
                                    path="AircraftEngine2"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.AircraftEngine2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* this is for premium */}
                                <Route
                                    path="SelectedAircraft"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.SelectedAircraft />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="RoutesAndDistances"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.RoutesAndDistances />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* this is for premium */}
                                {/* <Route
                                    path="RoutesAndDistances2"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.RoutesAndDistances2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="CustomConfigWeights"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.CustomConfigWeights />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* this is for premium */}
                                <Route
                                    path="CustomConfigWeights2"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.CustomConfigWeights2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="EnvironmentalConditions"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.EnvironmentalConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="AirportConditions"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.AirportConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CustomAirportConditions"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.CustomAirportConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* <Route
                                    path="CustomAirportConditions2"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.CustomAirportConditions2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                {/* this is for premium */}
                                {/* <Route
                                    path="CustomAirportConditions3"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.CustomAirportConditions3 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="InputReview"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.InputReview
                                                nextLink={`/forms/Performance/LogoUpload`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="LogoUpload"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.LogoUpload
                                                nextLink={`/forms/Performance/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Submission"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.Submission
                                                backLink={`/forms/Performance/LogoUpload`}
                                                nextLink={`/forms/Performance/Thanks`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Thanks"
                                    element={
                                        validSession ? (
                                            <PerformanceForm.Thanks
                                                backLink={`/forms/Performance/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="/*" element={<NotFound />} />
                    </>
                )
            );

        case "Economic":
            return createBrowserRouter(
                createRoutesFromElements(
                    <>
                        <Route
                            key={"econRoute"}
                            path=""
                            element={<DefaultLayout />}
                            errorElement={<Error />}
                        >
                            <Route errorElement={<NotFound />} />

                            <Route
                                path="/forms/"
                                key={"homeRoute"}
                                element={<Pages.Home />}
                            />

                            {/* ECON ROUTES */}
                            <Route
                                key={"econRoute"}
                                path="/forms/Economic/"
                                element={<Pages.Economic />}
                            >
                                <Route
                                    key={"selectOEM"}
                                    path=""
                                    element={
                                        validSession ? (
                                            <EconomicForm.SelectOEM />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                <Route
                                    key={"selectOEM2"}
                                    path="OEM2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.SelectOEM2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="AircraftEngine"
                                    element={
                                        validSession ? (
                                            <EconomicForm.AircraftEngine />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                <Route
                                    path="AircraftEngine2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.AircraftEngine2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                <Route
                                    path="SelectedAircraft"
                                    element={
                                        validSession ? (
                                            <EconomicForm.SelectedAircraft />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="RoutesAndDistances"
                                    element={
                                        validSession ? (
                                            <EconomicForm.RoutesAndDistances />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                {/* <Route
                                    path="RoutesAndDistances2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.RoutesAndDistances2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="CustomConfigWeights"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomConfigWeights />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                <Route
                                    path="CustomConfigWeights2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomConfigWeights2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="EnvironmentalConditions"
                                    element={
                                        validSession ? (
                                            <EconomicForm.EnvironmentalConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="AirportConditions"
                                    element={
                                        validSession ? (
                                            <EconomicForm.AirportConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CustomAirportConditions"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomAirportConditions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* <Route
                                    path="CustomAirportConditions2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomAirportConditions2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                {/* this is for premium */}
                                {/* <Route
                                    path="CustomAirportConditions3"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomAirportConditions3 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="CostAssumptions"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CostAssumptions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="FixedOperatingCost"
                                    element={
                                        validSession ? (
                                            <EconomicForm.FixedOperatingCost />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />

                                <Route
                                    path="FixedOperatingCost2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.FixedOperatingCost2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="LeaseRate"
                                    element={
                                        validSession ? (
                                            <EconomicForm.LeaseRate />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="LeaseRate2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.LeaseRate2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="FinanceAssumptions"
                                    element={
                                        validSession ? (
                                            <EconomicForm.FinanceAssumptions />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="FinanceAssumptions2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.FinanceAssumptions2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CashOperatingCosts"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CashOperatingCosts />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CrewLoading"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CrewLoading />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CrewLoading2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CrewLoading2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="AirportAndNavigationCharges"
                                    element={
                                        validSession ? (
                                            <EconomicForm.AirportAndNavigationCharges />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="CustomAirportNavigationCharges"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomAirportNavigationCharges />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                {/* <Route
                                    path="CustomAirportNavigationCharges2"
                                    element={
                                        validSession ? (
                                            <EconomicForm.CustomAirportNavigationCharges2 />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                /> */}
                                <Route
                                    path="InputReview"
                                    element={
                                        validSession ? (
                                            <EconomicForm.InputReview
                                                nextLink={`/forms/Economic/InputReview`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="InputReviewEcon"
                                    element={
                                        validSession ? (
                                            <EconomicForm.InputReviewEcon />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="LogoUpload"
                                    element={
                                        validSession ? (
                                            <EconomicForm.LogoUpload
                                                nextLink={`/forms/Economic/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Submission"
                                    element={
                                        validSession ? (
                                            <EconomicForm.Submission
                                                backLink={`/forms/Economic/LogoUpload`}
                                                nextLink={`/forms/Economic/Thanks`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Thanks"
                                    element={
                                        validSession ? (
                                            <EconomicForm.Thanks
                                                backLink={`/forms/Economic/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="/*" element={<NotFound />} />
                    </>
                )
            );
        case "Demand":
            return createBrowserRouter(
                createRoutesFromElements(
                    <>
                        <Route
                            key={"demandRoute"}
                            path=""
                            element={<DefaultLayout />}
                            errorElement={<Error />}
                        >
                            <Route errorElement={<NotFound />} />

                            <Route
                                path="/forms/"
                                key={"homeRoute"}
                                element={<Pages.Home />}
                            />

                            {/* DEMAND ROUTES */}
                            <Route
                                key={"demandRoute"}
                                path="forms/Demand/"
                                element={<Pages.Demand />}
                            >
                                <Route
                                    path=""
                                    element={
                                        validSession ? (
                                            <DemandForm.Markets />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Schedule"
                                    element={
                                        validSession ? (
                                            <DemandForm.Schedule />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="InputReview"
                                    element={
                                        validSession ? (
                                            <DemandForm.InputReviewDemand />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="LogoUpload"
                                    element={
                                        validSession ? (
                                            <DemandForm.LogoUpload
                                                backLink={`/forms/Demand/InputReview`}
                                                nextLink={`/forms/Demand/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Submission"
                                    element={
                                        validSession ? (
                                            <DemandForm.Submission
                                                backLink={`/forms/Demand/LogoUpload`}
                                                nextLink={`/forms/Demand/Thanks`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                                <Route
                                    path="Thanks"
                                    element={
                                        validSession ? (
                                            <DemandForm.Thanks
                                                backLink={`/forms/Demand/Submission`}
                                            />
                                        ) : (
                                            <NotFound />
                                        )
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="/*" element={<NotFound />} />
                    </>
                )
            );
        default:
            return createBrowserRouter(
                createRoutesFromElements(
                    <>
                        <Route
                            key={"defaultRoute"}
                            path="/"
                            element={<DefaultLayout />}
                            errorElement={<Error />}
                        >
                            <Route errorElement={<NotFound />} />
                            <Route
                                path="/forms/"
                                key={"homeRoute"}
                                element={<Pages.Home />}
                            />
                        </Route>
                        <Route path="/*" element={<NotFound />} />
                    </>
                )
            );
    }
};

export default router;
