import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setValidSession } from "./reducers/validSessionSlice";
import router from "./routes/router";

const App = () => {
    const validSession = useSelector(
        (state) => state.validSession.validSession
    );
    const packageType = useSelector((state) => state.packageType.value);

    return (
        <>
            <div>
                <RouterProvider router={router(validSession, packageType)} />
            </div>
        </>
    );
};

export default App;
