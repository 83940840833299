import {
    selectFaPerAircraftData,
    selectEconOrderNumber,
    selectPilotsPerAircraftData,
    selectCabinCrewData,
    selectPilotCrewData,
    selectRegion,
    selectRegionData,
    selectMarketData,
    selectDownPaymentData,
    selectInterestRateData,
    selectPaymentTermData,
    selectFuelPriceData,
    selectEconFetched,
    selectEconOEMList,
    selectLocation,
    selectEconOEM,
    selectEconOEM2,
    selectEconACType,
    selectEconACType2,
    selectEconACList,
    selectEconACDefault,
    selectEconACDefault2,
    selectEconRnD,
    selectEconRnD2,
    selectEconConditions,
    selectEconConditionsTwo,
    selectEconConfigRowsTwo,
    selectEconConfigRows,
    chooseEconConfig,
    chooseFirstEconWeight,
    chooseSecondEconWeight,
    selectFixedOperatingCostChoice,
    selectEconAircraftInsurance,
    selectAircraftOwnershipChoice,
    selectEconLeaseRate,
    selectLeaseRateData,
    selectFinanceAssumptions,
    selectCashOperatingCosts,
    selectCustomEconAirportConditions,
    selectCustomEconAirportConditions2,
    selectCustomEconAirportConditions3,
    selectAirportNavigationChoice,
    selectAirportNavigationCharges,
    selectAirportNavigationChargesTwo,
    selectCrewLoading1,
    selectCrewLoading2,
    selectEconAirportConditions,
    resetEcon,
    selectEconLogoUpload,
    selectEconSubmission,
} from "./econDataSlice";
import {
    selectOrderNumber,
    selectAirports,
    selectFetched,
    selectOEMList,
    selectOEM,
    selectOEM2,
    selectACType,
    selectACType2,
    selectACList,
    selectACDefault,
    selectACDefault2,
    selectRnD,
    selectRnD2,
    selectConditions,
    selectConditionsTwo,
    chooseFirstPerfWeight,
    chooseSecondPerfWeight,
    selectConfigRows,
    selectConfigRowsTwo,
    selectCustomAirportConditions,
    selectCustomAirportConditions2,
    selectCustomAirportConditions3,
    selectAirportConditions,
    selectSubmission,
    selectLogoUpload,
} from "./performanceDataSlice";
import {
    setAirline,
    selectDemandOrderNumber,
    setScheduledStartDate,
    setMarkets,
    setSchedule,
    setDemandLogoUpload,
    setDemandSubmission,
} from "./demandDataSlice";

const EconDispatchers = {
    OEMChoice: selectEconOEM,
    OEMChoice2: selectEconOEM2,
    OEMList: selectEconOEMList,
    RnD: selectEconRnD,
    RnD2: selectEconRnD2,
    acDefault: selectEconACDefault,
    acDefault2: selectEconACDefault2,
    acList: selectEconACList,
    acType: selectEconACType,
    acType2: selectEconACType2,
    aircraftInsurance1: selectEconAircraftInsurance,
    aircraftInsurance2: selectEconAircraftInsurance,
    aircraftOwnership1: selectAircraftOwnershipChoice,
    aircraftOwnership2: selectAircraftOwnershipChoice,
    airportConditions: selectEconAirportConditions,
    airportNavigationCharges: selectAirportNavigationCharges,
    airportNavigationChargesTwo: selectAirportNavigationChargesTwo,
    airportNavigationChoice: selectAirportNavigationChoice,
    cabinCrewData: selectCabinCrewData,
    cashOperatingCosts: selectCashOperatingCosts,
    crewLoading1: selectCrewLoading1,
    crewLoading2: selectCrewLoading2,
    conditions: selectEconConditions,
    conditionsTwo: selectEconConditionsTwo,
    config: chooseFirstEconWeight,
    configRows: selectEconConfigRows,
    configRowsTwo: selectEconConfigRowsTwo,
    configTwo: chooseSecondEconWeight,
    customAirportConditions: selectCustomEconAirportConditions,
    customAirportConditions2: selectCustomEconAirportConditions2,
    customAirportConditions3: selectCustomEconAirportConditions3,
    downPaymentData: selectDownPaymentData,
    faPerAircraftData: selectFaPerAircraftData,
    fetched: selectEconFetched,
    financeAssumptions1: selectFinanceAssumptions,
    financeAssumptions2: selectFinanceAssumptions,
    fixedOperatingCostChoice: selectFixedOperatingCostChoice,
    fuelPriceData: selectFuelPriceData,
    id: undefined,
    interestRateData: selectInterestRateData,
    leaseRate1: selectEconLeaseRate,
    leaseRate2: selectEconLeaseRate,
    leaseRateData: selectLeaseRateData,
    location: selectLocation,
    marketData: selectMarketData,
    paymentTermData: selectPaymentTermData,
    pilotCrewData: selectPilotCrewData,
    pilotsPerAircraftData: selectPilotsPerAircraftData,
    region: selectRegion,
    regionData: selectRegionData,
    submission: selectEconSubmission,
    logoUpload: selectEconLogoUpload,
};

const PerfDispatchers = {
    airports: selectAirports,
    fetched: selectFetched,
    OEMList: selectOEMList,
    OEMChoice: selectOEM,
    OEMChoice2: selectOEM2,
    acType: selectACType,
    acType2: selectACType2,
    acList: selectACList,
    acDefault: selectACDefault,
    acDefault2: selectACDefault2,
    RnD: selectRnD,
    RnD2: selectRnD2,
    conditions: selectConditions,
    conditionsTwo: selectConditionsTwo,
    config: chooseFirstPerfWeight,
    configTwo: chooseSecondPerfWeight,
    configRows: selectConfigRows,
    configRowsTwo: selectConfigRowsTwo,
    customAirportConditions: selectCustomAirportConditions,
    customAirportConditions2: selectCustomAirportConditions2,
    customAirportConditions3: selectCustomAirportConditions3,
    airportConditions: selectAirportConditions,
    submission: selectSubmission,
    logoUpload: selectLogoUpload,
};

const DemandDispatchers = {
    airline: setAirline,
    orderNumber: selectDemandOrderNumber,
    scheduledStartDate: setScheduledStartDate,
    markets: setMarkets,
    schedule: setSchedule,
    submission: setDemandSubmission,
    logoUpload: setDemandLogoUpload,
};

export default { EconDispatchers, PerfDispatchers, DemandDispatchers };
