import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
};

export const inputReviewValidationSlice = createSlice({
    name: "inputReviewValidation",
    initialState,
    reducers: {
        checkedValidation: (state) => {
            state.value = false;
            // console.log(state.value);
        },
        resetValidation: (state) => {
            state.value = false;
            // console.log(state.value);
        },
    },
});

export const { checkedValidation, resetValidation } =
    inputReviewValidationSlice.actions;
export default inputReviewValidationSlice.reducer;
