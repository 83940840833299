import React, { useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector, useDispatch } from "react-redux";
import { selectAirportConditions } from "../../reducers/performanceDataSlice";
import { selectEconAirportConditions } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";

const AirportConditions = (props) => {
    // based on the package it will either contain 2 or 4 extra pages of airport overrides.
    // These pages need to be rendered here and pass the appropriate "progressWidth" to parent if custom is selected.

    let packageType = useSelector((state) => state.packageType.value);
    // let packageLevel = useSelector((state) => state.packageLevel.value);

    let perfData = useSelector((state) => state.performanceData);
    let econData = useSelector((state) => state.econData);

    let data;
    if (packageType === "Performance") {
        data = perfData;
    } else if (packageType === "Economic") {
        data = econData;
    }

    let isaData = data.airportConditions.ISA;
    let runwayData = data.airportConditions.rwyWind;
    let taxiInData = data.airportConditions.taxiIn;
    let taxiOutData = data.airportConditions.taxiOut;
    let customInput = data.airportConditions.input;

    const [defaultISA, setdefaultISA] = useState(
        isaData === undefined ? false : true
    );
    const [defaultTaxiIn, setDefaultTaxiIn] = useState(
        taxiInData === undefined ? false : true
    );
    const [defaultTaxiOut, setDefaultTaxiOut] = useState(
        taxiOutData === undefined ? false : true
    );

    const [defaultRunway, setDefaultRunway] = useState(
        runwayData === undefined ? false : true
    );

    const [defaultInput, setDefaultInput] = useState(
        customInput === undefined ? false : true
    );

    const [isa, setISA] = useState(isaData); // to save the choice in a state in the parent component
    const chooseISA = (type) => {
        // function called in the prop value
        setISA(type);
    };
    const [runwayWind, setRunwayWind] = useState(runwayData); // to save the choice in a state in the parent component
    const chooseRunwayWind = (type) => {
        // function called in the prop value
        setRunwayWind(type);
    };

    const [taxiIn, setTaxiIn] = useState(taxiInData); // to save the choice in a state in the parent component
    const chooseTaxiIn = (type) => {
        // function called in the prop value
        setTaxiIn(type);
    };
    const [taxiOut, setTaxiOut] = useState(taxiOutData); // to save the choice in a state in the parent component
    const chooseTaxiOut = (type) => {
        // function called in the prop value
        setTaxiOut(type);
    };

    const [custom, setCustom] = useState(); // to save the choice in a state in the parent component
    const chooseCustom = (type) => {
        // function called in the prop value
        setCustom(type);
    };

    let acInputs = {
        ISA: undefined,
        rwyWind: undefined,
        taxiOut: undefined,
        taxiIn: undefined,
        input: "",
    };

    const dispatch = useDispatch();
    const dispatchInputs = () => {
        acInputs.ISA = isa;
        acInputs.rwyWind = runwayWind;
        acInputs.taxiOut = taxiOut;
        acInputs.taxiIn = taxiIn;
        acInputs.input = custom;
        // dispatch(selectRnD(RnDInputs));
        if (packageType === "Performance") {
            dispatch(selectAirportConditions(acInputs));
        } else if (packageType === "Economic") {
            dispatch(selectEconAirportConditions(acInputs));
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
        >
            <UtilityClass.CustomText
                textSize="subtitle"
                fontWeight="semibold"
                color="black"
                content={"Airport Conditions"}
            />
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="semibold"
                color="black"
                content={
                    "For each airport, you have options to:\n- select globally applied assumptions\nand\n- provide custom overrides for select locations"
                }
            />
            <UtilityClass.Separator />
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="semibold"
                color="black"
                content={
                    "Global Default Applications: Temperature, Winds, Taxi Times"
                }
            />
            <UtilityClass.DropdownMenu
                title="Default airport temperature  (°C)"
                titleBool={true}
                defaultVals={defaultISA ? isaData : "ISA"}
                options={[
                    "ISA-10",
                    "ISA-5",
                    "ISA",
                    "ISA+5",
                    "ISA+10",
                    "ISA+15",
                    "ISA+20",
                    "ISA+25",
                    "ISA+30",
                ]}
                internalTitle="ISA (International Standard Atmosphere)"
                value={chooseISA}
                width="auto"
                includeDefaults={defaultISA}
            />
            <UtilityClass.DropdownMenu
                title="Default airport runway wind  (kts)"
                titleBool={true}
                options={["5 TW", "0", "10 HW"]}
                internalTitle={"Please select"}
                value={chooseRunwayWind}
                width="auto"
                includeDefaults={defaultRunway}
                defaultVals={defaultRunway ? runwayData : "0"}
            />
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="semibold"
                color="black"
                content={"Default airport taxi times (minutes)"}
            />
            <div className="flex justify-center gap-2">
                <UtilityClass.DropdownMenu
                    title="Taxi out"
                    titleBool={true}
                    options={["5", "7", "10", "12", "15", "18", "20"]}
                    internalTitle={"Please select"}
                    value={chooseTaxiOut}
                    defaultVals={defaultTaxiOut ? taxiOutData : "10"}
                    width="auto"
                    includeDefaults={defaultTaxiOut}
                />
                <UtilityClass.DropdownMenu
                    title="Taxi in"
                    titleBool={true}
                    options={["5", "7", "10", "12", "15", "18", "20"]}
                    internalTitle={"Please select"}
                    value={chooseTaxiIn}
                    defaultVals={defaultTaxiIn ? taxiInData : "10"}
                    width="auto"
                    includeDefaults={defaultTaxiIn}
                />
            </div>
            <UtilityClass.Separator />
            <UtilityClass.RadioButtons
                title="Do you want to provide custom overrides for select locations?"
                text={[
                    "Yes, add custom inputs.",
                    "No, use global assumptions.",
                ]}
                value={chooseCustom}
                width="fit"
                defaultValue={
                    defaultInput ? customInput : "No, use global assumptions."
                }
            />
            {custom && custom === "Yes, add custom inputs." ? (
                <UtilityClass.BackNextButtons
                    nextButtonText="NEXT"
                    backLink={
                        "/forms/" + packageType + "/EnvironmentalConditions"
                    }
                    nextLink={
                        "/forms/" + packageType + "/CustomAirportConditions"
                    }
                    dispatchInputs={dispatchInputs}
                    validation={true}
                />
            ) : (
                <UtilityClass.BackNextButtons
                    nextButtonText="NEXT"
                    backLink={
                        "/forms/" + packageType + "/EnvironmentalConditions"
                    }
                    dispatchInputs={dispatchInputs}
                    nextLink={
                        "/forms/" +
                        packageType +
                        `${
                            packageType === "Performance"
                                ? "/InputReview"
                                : "/CostAssumptions"
                        }`
                    }
                    validation={true}
                />
            )}
        </motion.div>
    );
};

export default AirportConditions;
