import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAirportNavigationChoice, selectLocation } from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const AirportAndNavigationCharges = (props) => {
    // This component is a simple radio button selection with 2 options.
    // The component will have a child of CustomAirportNavigationCharges that needs to be called based on the selected radio option.
    let location = useLocation()
    let navChoice = useSelector((state) => state.econData.airportNavigationChoice);

    const [defaultNavChoice, setDefaultNavChoice] = useState(
        navChoice === undefined || navChoice.length === 0 ? false : true
    );

    const dispatch = useDispatch();

    const [airportNavigationCharges, setAirportNavigationCharges] = useState();
    const chooseAirportNavigationCharges = (v) => {
        setAirportNavigationCharges(v);
    };

    const dispatchInputs = () => {
        dispatch(selectLocation(location))
        dispatch(selectAirportNavigationChoice(handleDispatch()));
    };

    const handleDispatch = () => {
        return airportNavigationCharges;
    };

    //redux calls
    let packageType = useSelector((state) => state.packageType.value);

    let orderNumber = useSelector((state) => state.econData.orderNumber);

    return (
        <motion.div
        initial={{ opacity:0 } }
        animate={{ opacity: 1}}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
            <UtilityClass.RadioButtons
                title="Airport and Navigation Charges"
                text={[
                    "Use only IATA Aviation Charges",
                    "Use combination IATA Aviation Charges + custom inputs",
                ]}
                subTextBool={true}
                subText={[
                    "-Aviation Charges Intelligence Center (ACIC) by IATA",
                    "-Aviation Charges Intelligence Center (ACIC) by IATA",
                ]}
                value={chooseAirportNavigationCharges}
                width="fit"
                defaultValue={defaultNavChoice ? navChoice : "Use only IATA Aviation Charges"}
            />
            <UtilityClass.BackNextButtons
                nextButtonText="NEXT"
                backLink={"/forms/" + packageType + "/CashOperatingCosts"}
                nextLink={
                    "/forms/" +
                    packageType +
                    "/" +
                    `${
                        airportNavigationCharges ===
                        "Use only IATA Aviation Charges"
                            ? "InputReview"
                            : "CustomAirportNavigationCharges"
                    }`
                }
                dispatchInputs={dispatchInputs}
                validation={true}
            />
        </motion.div>
    );
};

export default AirportAndNavigationCharges;
