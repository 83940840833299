import React, { useState } from "react";
import { useLocation, useOutlet, ScrollRestoration } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const AnimatedOutlet = () => {
    const outlet = useOutlet();
    const [outletState] = useState(outlet);
    return <>{outletState}</>;
};

const Economic = () => {
    const location = useLocation();

    return (
        <>
            <form
                className="pb-20" // for the footer
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <AnimatePresence mode="wait">
                    <motion.div>
                        <ScrollRestoration />
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            key={location.pathname}
                        >
                            <AnimatedOutlet />
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            </form>
            {/* <NavMenu paths={paths} /> */}
        </>
    );
};

export default Economic;
