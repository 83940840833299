// import { useEffect, useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
import EmbeddedForm from "./EmbeddedForm";

const Payment = () => {
	// const [stripePromise, setStripePromise] = useState(null);
	// const [clientSecret, setClientSecret] = useState("");

	// useEffect(() => {
	// 	fetch(
	// 		`${
	// 			process.env.REACT_APP_STAGE === "production"
	// 				? process.env.REACT_APP_SERVER_URL_PROD
	// 				: process.env.REACT_APP_SERVER_URL_DEV
	// 		}/stripeConfig`
	// 	).then(async (res) => {
	// 		const { publishableKey } = await res.json();
	// 		setStripePromise(loadStripe(publishableKey));
	// 		// console.log(publishableKey);
	// 	});
	// }, []);

	// useEffect(() => {
	// 	fetch(
	// 		`${
	// 			process.env.REACT_APP_STAGE === "production"
	// 				? process.env.REACT_APP_SERVER_URL_PROD
	// 				: process.env.REACT_APP_SERVER_URL_DEV
	// 		}/create-payment-intent`,
	// 		{
	// 			method: "POST",
	// 			mode: "cors",
	// 			body: JSON.stringify({}),
	// 		}
	// 	)
	// 		.then((res) => res.json())
	// 		.then(async (data) => {
	// 			// const { clientSecret } = await res.json();
	// 			setClientSecret(data.clientSecret);
	// 		});
	// }, []);

	// const options = {
	// 	// passing the client secret obtained in step 3
	// 	clientSecret,

	// 	// Fully customizable with appearance API.
	// 	appearance: {
	// 		theme: "stripe",
	// 	},
	// };

	return (
		<>
			{/* {stripePromise && clientSecret && (
				<Elements stripe={stripePromise} options={options}>
					<CheckoutForm />
				</Elements>
			)} */}
			<EmbeddedForm />
		</>
	);
};

export default Payment;
