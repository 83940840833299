import Markets from "../components/demand/Markets";
import Schedule from "../components/demand/Schedule";
import InputReview from "../components/commonAll/InputReview";
import LogoUpload from "../components/commonAll/LogoUpload";
import Submission from "../components/commonAll/Submission";
import Thanks from "../components/commonAll/Thanks";
import InputReviewDemand from "../components/demand/InputReviewDemand";

export const DemandForm = {
    Markets,
    Schedule,
    InputReview,
    LogoUpload,
    Submission,
    Thanks,
    InputReviewDemand,
};
