import React, { useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector, useDispatch } from "react-redux";
import {
	setAirline,
	setScheduledStartDate,
	setMarkets,
	setSchedule,
} from "../../reducers/demandDataSlice";
import { motion } from "framer-motion";

const Markets = (props) => {
	const dispatch = useDispatch();

	// redux calls
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let airline = useSelector((state) => state.demandData.airline);
	let scheduledStartDate = useSelector(
		(state) => state.demandData.scheduledStartDate
	);
	let markets = useSelector((state) => state.demandData.markets);
	let schedule = useSelector((state) => state.demandData.schedule);

	const [marketInputs, setMarketInputs] = useState();
	let temp = [];
	const marketsTableFunction = (type) => {
		temp.push(type.toUpperCase());
		setMarketInputs(type);
	};
	const breakArray = (arr, chunkSize) => {
		const out = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			const chunk = arr.slice(i, i + chunkSize);
			out.push(chunk);
		}
		return out;
	};

	// functions to save inputs to redux
	const changeAirline = (i) => {
		dispatch(setAirline(i));
		// console.log(airline);
	};
	const changeSchedStartDate = (i) => {
		dispatch(setScheduledStartDate(i));
		// console.log(scheduledStartDate);
	};
	const changeMarkets = () => {
		const arr = breakArray(temp, packageLevel === "Basic" ? 4 : 5);
		dispatch(setMarkets(arr));

		// update schedule component, disabled data from markets table
		let tempSchedule = [
			[
				[arr[0][0], arr[0][1], schedule[0][0][2]],
				[arr[1][0], arr[1][1], schedule[0][1][2]],
				[arr[2][0], arr[2][1], schedule[0][2][2]],
				[arr[3][0], arr[3][1], schedule[0][3][2]],
				[arr[4][0], arr[4][1], schedule[0][4][2]],
			],
			schedule[1],
			schedule[2],
		];
		dispatch(setSchedule(tempSchedule));
	};

	const [defaultMarkets, setDefaultMarkets] = useState(
		markets === undefined || markets.length === 0 ? false : true
	);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="medium"
				bottomMargin={false}
				color="black"
				content={`${packageLevel} Level Analysis`}
			></UtilityClass.CustomText>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="medium"
				bottomMargin={true}
				color="black"
				content={`Forecast O&D traffic  +  market share based on ${
					packageLevel === "Basic" ? "your provided" : "an optimized"
				} schedule`}
			></UtilityClass.CustomText>
			<div className="flex lg:w-1/3 md:w-1/2 w-3/4 justify-center mx-auto gap-x-2">
				<UtilityClass.DefaultInput
					labelBool={true}
					label="Operator"
					placeholder={"IATA code / name"}
					defaultValue={airline}
					value={changeAirline}
					width="full"
					capitalize={true}
					regex={/^[a-zA-Z0-9\s]*$/}
				></UtilityClass.DefaultInput>
				<UtilityClass.DefaultInput
					labelBool={true}
					label="Scheduled Start Date"
					leftSquareLabelBool={false}
					leftSquareLabelText={"Date"}
					placeholder={"dd-mm-yyyy"}
					defaultValue={scheduledStartDate}
					value={changeSchedStartDate}
					width="full"
				></UtilityClass.DefaultInput>
			</div>
			<UtilityClass.Separator />
			<div className="w-full">
				<div className="flex w-full justify-center mx-auto gap-x-1">
					<UtilityClass.InputTable
						captionBool={true}
						label={
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="medium"
								bottomMargin={true}
								color="black"
								content={
									"Input up to 5 markets using valid IATA codes\nFor each market, bi-directional route data will be provided"
								}
							></UtilityClass.CustomText>
						}
						indexBool={true}
						indexBlack={true}
						rowNum={5}
						colNum={packageLevel === "Basic" ? 4 : 5}
						rowBottomMarginBool={false}
						rowConditionalMarginBoolList={false}
						headerLabelsBool={true}
						labelGray={true}
						bottomla
						redTextInLabelList={
							packageLevel === "Basic"
								? [false, true, true, false, false]
								: [false, true, true, false, false, true]
						}
						autoComplete={true}
						autoCompleteCheck={[true, true, false, false]}
						conditionalLabelsList={
							packageLevel === "Basic"
								? [
										"Market",
										"From\nIATA code",
										"To\nIATA code",
										"Aircraft\nType",
										"Aircraft\nCapacity",
								  ]
								: [
										"Market",
										"From\nIATA code",
										"To\nIATA code",
										"Aircraft\nType",
										"Aircraft\nCapacity",
										"Partner Airlines\nIATA code",
								  ]
						}
						placeholder={
							packageLevel === "Basic"
								? ["", "", "AC Type", "Seats"]
								: ["", "", "AC Type", "Seats", ""]
						}
						rightMarginBoolList={
							packageLevel === "Basic"
								? [false, false, false, false]
								: [false, true, false, true, false]
						}
						disabledBoolList={false}
						showIndex={Array(5).fill(true)}
						labelBottomMarginBoolList={false}
						includeDefaults={defaultMarkets}
						defaultVals={markets}
						value={marketsTableFunction}
						width="full"
						capitalize={true}
						regex={
							packageLevel === "Basic"
								? [
										/^[a-zA-Z0-9]*$/,
										/^[a-zA-Z0-9]*$/,
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
										/^(0?|[1-9]\d{0,2})$/,
								  ]
								: [
										/^[a-zA-Z0-9]*$/,
										/^[a-zA-Z0-9]*$/,
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
										/^(0?|[1-9]\d{0,2})$/,
										/^[a-zA-Z0-9]*$/,
								  ]
						}
						multiRegex={true}
					/>
					{/* this is for ac type */}
					{/* <UtilityClass.InputTable
						captionBool={false}
						label={
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="medium"
								bottomMargin={true}
								color="black"
								content={
									"nbsp\nFor each market, bi-directional route data will be provided"
								}
							></UtilityClass.CustomText>
						}
						// indexBool={true}
						// indexBlack={true}
						rowNum={5}
						colNum={packageLevel === "Basic" ? 1 : 1}
						rowBottomMarginBool={false}
						rowConditionalMarginBoolList={false}
						headerLabelsBool={true}
						labelGray={true}
						bottomla
						redTextInLabelList={packageLevel === "Basic" ? [false] : [false]}
						// autoComplete={true}
						conditionalLabelsList={
							packageLevel === "Basic"
								? ["Aircraft\nType", "Aircraft\nType"]
								: ["Aircraft\nType", "Aircraft\nType"]
						}
						placeholder={packageLevel === "Basic" ? ["AC Type"] : ["AC Type"]}
						rightMarginBoolList={packageLevel === "Basic" ? [false] : [false]}
						disabledBoolList={false}
						showIndex={Array(5).fill(true)}
						labelBottomMarginBoolList={false}
						includeDefaults={defaultMarkets}
						defaultVals={markets}
						value={marketsTableFunction}
						// width="full"
						capitalize={true}
						regex={
							packageLevel === "Basic"
								? [
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
								  ]
								: [
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
								  ]
						}
						// multiRegex={true}
					/> */}
				</div>
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				nextLink={`${
					packageLevel === "Basic"
						? "/forms/Demand/Schedule"
						: "/forms/Demand/InputReview"
				}`}
				backButtonBool={false}
				dispatchInputs={changeMarkets}
				validation={true}
			></UtilityClass.BackNextButtons>
		</motion.div>
	);
};

export default Markets;
