export const processTable = (nodes, chunk, checked) => {
    let result = [];
    let currentRow = [];
    nodes.forEach((element, index) => {
        if (checked) {
            console.log(element.checked);
            currentRow.push(element.checked);
        } else {
            currentRow.push(element.value);
        }
        if ((index + 1) % chunk === 0) {
            result.push(currentRow);
            currentRow = [];
        }
        return element.value;
    });
    return result;
};
