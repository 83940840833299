import { UtilityClass } from "../../utility-class/UtilityClass";

const Error = () => {
    return (
        <div className="w-screen h-screen fixed z-40 bg-white top-0 left-0">
            <div className="flex flex-col pt-56">
                <UtilityClass.CustomText
                    content="401"
                    noMargin
                    textSize="title"
                    fontWeight="semibold"
                />
                <UtilityClass.CustomText
                    content="The application has unfortunately crashed. Please contact support immediately."
                    textSize="large"
                    fontWeight="semibold"
                />
            </div>
        </div>
    );
};

export default Error;
