import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectFixedOperatingCostChoice, selectLocation } from "../../reducers/econDataSlice";
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion";

const CostAssumptions = (props) => {
    // This component only serves as what the user will enter (static text).
    // The component will also include the optional rendering of FixedOperatingCost (based on yes or no user selection)
    // The progressWidth setter function must be passed down to FixedOperatingCost as a prop


    const dispatch = useDispatch();
    const [fixedOperatingCost, setFixedOperatingCost] = useState();
    const chooseFixedOperatingCost = (v) => {
        setFixedOperatingCost(v);
    };

    let location = useLocation()
    const dispatchInputs = () => {
        dispatch(selectLocation(location))
        dispatch(selectFixedOperatingCostChoice(handleDispatch()));
    };

    const handleDispatch = () => {
        return fixedOperatingCost;
    };

    let fixedDefault = useSelector((state) => state.econData.fixedOperatingCostChoice);
   
    const [defaultFixed, setDefaultFixed] = useState(
        fixedDefault === undefined || fixedDefault.length === 0 ? false : true
    );

    // redux calls


    
    let packageType = useSelector((state) => state.packageType.value);
    let packageLevel = useSelector((state) => state.packageLevel.value);

    let econInput = useSelector((state) => state.econData.airportConditions.input);
    let perfInput = useSelector((state) => state.performanceData.airportConditions.input);

    let orderNumber = useSelector((state) => state.econData.orderNumber);

    let input;
    if (packageType === "Performance") {
        input = perfInput;
    } else if (packageType === "Economic") {
        input = econInput;
    }
    
    
    return (
        <motion.div
        initial={{ opacity:0 } }
        animate={{ opacity: 1}}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
            <UtilityClass.CustomText
                textSize="title"
                fontWeight="semibold"
                color="black"
                content={"Cost Assumptions"}
            />
            <UtilityClass.CustomText
                textSize="subtitle"
                fontWeight="semibold"
                color="black"
                content={"Fixed Operating Cost"}
            />
            <UtilityClass.CustomText
                textSize="medium"
                fontWeight="semibold"
                color="grey"
                content={"- Aircraft Insurance\n- Aircraft Ownership"}
            />
            <UtilityClass.CustomText
                textSize="subtitle"
                fontWeight="semibold"
                color="black"
                content={"Cash Operating Cost"}
            />
            <UtilityClass.CustomText
                textSize="medium"
                fontWeight="semibold"
                color="grey"
                content={
                    "- Fuel\n- Crew\n- Landing\n- Terminal Navigation\n- En-route Navigation\n- Passenger Handling\n- Aircraft Handling"
                }
            />
            <UtilityClass.Separator />
            <div className="flex justify-center">
                <UtilityClass.RadioButtons
                    title="Include fixed operating cost?"
                    text={["Yes", "No"]}
                    row={true}
                    value={chooseFixedOperatingCost}
                    horizontal={true}
                    defaultValue={defaultFixed ? fixedDefault : "No"}
                />
            </div>
            <UtilityClass.BackNextButtons
                nextButtonText="NEXT"
                backLink={
                        input === 'No, use global assumptions.'
                            ? "/forms/" + packageType + "/AirportConditions" 
                        : packageLevel === 'Basic' ?
                            "/forms/" + packageType + "/CustomAirportConditions2" 
                            : "/forms/" + packageType + "/CustomAirportConditions3"
                    }
                nextLink={
                    "/forms/" +
                    packageType +
                    "/" +
                    `${
                        fixedOperatingCost === "Yes"
                            ? "FixedOperatingCost"
                            : "CashOperatingCosts"
                    }`
                }
                dispatchInputs={dispatchInputs}
                validation={true}
            />
        </motion.div>
    );
};

export default CostAssumptions;
