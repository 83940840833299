// import storage from "redux-persist/lib/storage";
import localforage from "localforage";
import { combineReducers } from "redux";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import packageLevelSlice from "../reducers/packageLevelSlice";
import packageTypeSlice from "../reducers/packageTypeSlice";
import performanceDataSlice from "../reducers/performanceDataSlice";
import econDataSlice from "../reducers/econDataSlice";
import demandDataSlice from "../reducers/demandDataSlice";
import inputReviewValidationSlice from "../reducers/inputReviewValidationSlice";
import validSessionSlice from "../reducers/validSessionSlice";

const persistConfig = {
    key: "counter",
    storage: localforage,
};

const reducers = combineReducers({
    packageLevel: packageLevelSlice,
    packageType: packageTypeSlice,
    performanceData: performanceDataSlice,
    econData: econDataSlice,
    demandData: demandDataSlice,
    inputReviewValidation: inputReviewValidationSlice,
    validSession: validSessionSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
            immutableCheck: false,
        }),
});
