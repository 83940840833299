import { useState, useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Payment from "../payment/Payment";

const Thanks = () => {
	// stripe return session
	const [status, setStatus] = useState(null);
	const [orderData, setOrderData] = useState(null);
	const [paymentStatus, setPaymentStatus] = useState(null);

	// Thanks for using AviAnalysis product.
	// The only difference this has is the number of business days it will take, perf & econ take 1 day, demand takes 2 days

	let packageType = useSelector((state) => state.packageType.value);
	let data = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData;
		} else if (packageType === "Economic") {
			return state.econData;
		} else {
			return state.demandData;
		}
	});

	let orderID = data.orderID;

	useEffect(() => {
		fetch(
			`${
				process.env.REACT_APP_STAGE === "production"
					? process.env.REACT_APP_SERVER_URL_PROD
					: process.env.REACT_APP_SERVER_URL_DEV
			}/getOrdersTableByID?orderID=${orderID}`
		)
			.then((res) => res.json())
			.then((data) => {
				if (data) {
					setOrderData(data[0]);
					setPaymentStatus(data[0].paymentStatus);
				} else {
					console.error("unexpected data format: ", data);
				}
			});
	}, []);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionId = urlParams.get("session_id");

		if (sessionId) {
			fetch(
				`${
					process.env.REACT_APP_STAGE === "production"
						? process.env.REACT_APP_SERVER_URL_PROD
						: process.env.REACT_APP_SERVER_URL_DEV
				}/session-status?session_id=${sessionId}`
			)
				.then((res) => res.json())
				.then((data) => {
					if (data && data.status) {
						console.log(data.status);
						setStatus(data.status);
					} else {
						console.error("unexpected data format: ", data);
					}
				})
				.catch((err) => {
					console.log("error fetching session status: ", err);
				});
		}
	}, []);

	useEffect(() => {
		if (status == "succeeded") {
			fetch(
				`${
					process.env.REACT_APP_STAGE === "production"
						? process.env.REACT_APP_SERVER_URL_PROD
						: process.env.REACT_APP_SERVER_URL_DEV
				}/payOrder`,
				{
					method: "POST",
					mode: "cors",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({
						orderID: orderID,
					}),
				}
			);
		}
	}, [status]);

	const renderingLogic = () => {
		if (
			(paymentStatus && paymentStatus == "PAID") ||
			(status && status === "complete") ||
			status == "succeeded"
		) {
			return (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					<div className="flex-col mt-40">
						<UtilityClass.CustomText
							textSize="title"
							fontWeight="bold"
							color="red"
							content={"Thank You For Using AviAnalysis!"}
						/>
						<br />
						<UtilityClass.CustomText
							textSize="subtitle"
							fontWeight="semibold"
							color="red"
							content={`Your report will be delivered within ${
								packageType === "Performance" || packageType === "Economic"
									? "1 business day."
									: "2 business days."
							}`}
						/>
						<UtilityClass.CustomText
							textSize="subtitle"
							fontWeight="semibold"
							color="red"
							content={
								"Please check your confirmation email for the summary of inputs."
							}
						/>
						<br />
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="semibold"
							color="grey"
							content={
								"If you do not receive the email, please check your spam folder."
							}
						/>
					</div>
				</motion.div>
			);
		} else {
			return (
				<div>
					<Payment />
				</div>
			);
		}
	};

	return <>{renderingLogic()}</>;
};

export default Thanks;
