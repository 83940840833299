import React, { useEffect, useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectCustomAirportConditions } from "../../reducers/performanceDataSlice";
import { selectCustomEconAirportConditions } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";
import { processTable } from "../../util/helpers";

const CustomAirportConditions = () => {
	const dispatch = useDispatch();

	let customAirportConditions = {
		override: undefined,
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	};

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	console.log(perfData);

	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else if (packageType === "Economic") {
		data = econData;
	}

	let routeData = data.RnD.iataAirportCode;
	let overrideInputs = data.customAirportConditions.override;
	let airportInputs = data.customAirportConditions.airports;
	let isaInputs = data.customAirportConditions.ISA;

	let oatInputs = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData.customAirportConditions.OAT;
		} else if (packageType === "Economic") {
			return state.econData.customAirportConditions.OAT;
		}
	});

	let rwyInputs = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData.customAirportConditions.rwyWind;
		} else if (packageType === "Economic") {
			return state.econData.customAirportConditions.rwyWind;
		}
	});

	let taxiOutInputs = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData.customAirportConditions.taxiOut;
		} else if (packageType === "Economic") {
			return state.econData.customAirportConditions.taxiOut;
		}
	});

	let taxiInInputs = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData.customAirportConditions.taxiIn;
		} else if (packageType === "Economic") {
			return state.econData.customAirportConditions.taxiIn;
		}
	});

	const [defaultOverride, setDefaultOverride] = useState(
		overrideInputs === undefined || overrideInputs.length === 0 ? false : true
	);

	const [defaultAirport, setDefaultAirport] = useState(
		airportInputs === undefined || airportInputs.length === 0 ? false : true
	);

	const [defaultISA, setDefaultISA] = useState(
		isaInputs === undefined || isaInputs.length === 0 ? false : true
	);

	const [defaultOAT, setDefaultOAT] = useState(
		oatInputs === undefined || oatInputs.length === 0 ? false : true
	);

	const [defaultRwy, setDefaultRwy] = useState(
		rwyInputs === undefined || rwyInputs.length === 0 ? false : true
	);

	const [defaultTaxiOut, setDefaultTaxiOut] = useState(
		taxiOutInputs === undefined || taxiOutInputs.length === 0 ? false : true
	);

	const [defaultTaxiIn, setDefaultTaxiIn] = useState(
		taxiInInputs === undefined || taxiInInputs.length === 0 ? false : true
	);

	const [, setFirst] = useState();
	const [, setSecond] = useState();
	const [, setThird] = useState();
	const [, setFourth] = useState();
	const [, setFifth] = useState();
	const [, setSixth] = useState();

	const [secondArr, setSecondArr] = useState([]);
	const secondTable = (type, index) => {
		secondArr.push([type, index]);
		setSecond(type);
	};

	const [fourthArr, setFourthArr] = useState([]);
	const fourthTable = (type, index) => {
		fourthArr.push([type, index]);
		setFourth(type);
	};

	useEffect(() => {
		if (defaultISA) {
			isaInputs.forEach((value) => {
				secondArr.push(value);
			});
		}
		if (defaultRwy) {
			rwyInputs.forEach((value) => {
				fourthArr.push(value);
			});
		}
	}, []);

	// check screen width
	var windowWidth = window.innerWidth;
	const [windowLarge, setWindowLarge] = useState(windowWidth >= 1024);
	window.onresize = handleResize;
	function handleResize() {
		windowWidth = window.innerWidth;
		if (windowWidth < 1024) {
			setWindowLarge(false);
		} else if (windowWidth <= 1441) {
			setWindowLarge(true);
		} else {
			setWindowLarge(true);
		}
	}

	let checkboxNodes = document.querySelectorAll('input[type="checkbox"]');
	let dropDownNodes = document.querySelectorAll(
		'div[data-type="select"] > select'
	);
	let inputNodes = document.querySelectorAll(
		'div[data-type="input"] > div > div > div > input'
	);
	let dropDownISA = Array.from(dropDownNodes).slice(
		0,
		packageLevel === "Basic" ? 30 : 60
	);
	let inputOAT = Array.from(inputNodes).slice(
		packageLevel === "Basic" ? 30 : 60,
		packageLevel === "Basic" ? 60 : 120
	);

	function addRouteDistanceEventListeners(isaInput, oatInput, index) {
		function clearISA() {
			isaInput.value = "Please select";
			cleanIsaArr([index, 0]);
		}
		function clearOAT() {
			Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			).set.call(oatInput, "");
			oatInput.dispatchEvent(new Event("input", { bubbles: true }));
		}
		function cleanIsaArr(index) {
			secondArr.forEach((value, innerIndex) => {
				if (value[1][0] === index[0] && value[1][1] === index[1]) {
					secondArr.splice(innerIndex, 1);
				}
			});
		}

		try {
			isaInput.addEventListener("change", clearOAT);
			oatInput.addEventListener("change", clearISA);
		} catch {}
	}

	dropDownISA.map((item, index) => {
		addRouteDistanceEventListeners(item, inputOAT[index], index);
	});

	const dispatchInputs = () => {
		customAirportConditions.override = processTable(
			Array.from([...checkboxNodes]).slice(
				0,
				packageLevel === "Basic" ? 30 : 60
			),
			1,
			true
		);

		customAirportConditions.airports = processTable(
			Array.from([...inputNodes]).slice(0, packageLevel === "Basic" ? 30 : 60),
			1
		);

		customAirportConditions.ISA = secondArr;
		customAirportConditions.OAT = processTable(
			Array.from([...inputNodes]).slice(
				packageLevel === "Basic" ? 30 : 60,
				packageLevel === "Basic" ? 60 : 120
			),
			1
		);

		customAirportConditions.taxiOut = processTable(
			Array.from([...inputNodes]).slice(
				packageLevel === "Basic" ? 60 : 120,
				packageLevel === "Basic" ? 120 : 180
			),
			1
		);
		customAirportConditions.rwyWind = fourthArr;
		console.log(customAirportConditions);
		customAirportConditions.taxiIn = processTable(
			Array.from([...inputNodes]).slice(
				packageLevel === "Basic" ? 90 : 180,
				packageLevel === "Basic" ? 150 : 240
			),
			1
		);

		if (packageType === "Performance") {
			dispatch(selectCustomAirportConditions(customAirportConditions));
		} else if (packageType === "Economic") {
			dispatch(selectCustomEconAirportConditions(customAirportConditions));
		}
	};

	const validateContinue = () => {
		let validated = true;
		let iataInputs = Array.from([...inputNodes]).slice(
			0,
			packageLevel === "Basic" ? 30 : 60
		);
		// let isaInputs = Array.from([...dropDownNodes]).slice(
		//     0,
		//     packageLevel === "Basic" ? 15 : 20
		// );
		const invalidInputs = [];
		const validMatrix = [];
		iataInputs.forEach((input, index) => {
			// if (
			//     input.value.trim().length > 0 &&
			//     isaInputs[index].value.trim() === "Please select"
			// ) {
			//     validated = false;
			// }

			// if (
			//     input.value.trim().length === 0 &&
			//     isaInputs[index].value.trim() !== "Please select"
			// ) {
			//     validated = false;
			// }

			if (input.value.length !== 3 && input.value !== "") {
				invalidInputs.push(input.value.toUpperCase());
			}
		});
		if (invalidInputs.length > 0) {
			validated = false;
		}
		if (validMatrix.includes(false)) {
			validated = false;
		}
		return [validated, invalidInputs];
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="black"
				content={"Airport Conditions"}
			/>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={"Custom Values (Optional)"}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={
					"Apply any combination of temperatures, runway winds and taxi times."
				}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={"Custom Airport Temperatures can be either:"}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={
					"- A selection of ISA deviations\nor\n- Custom Outside Air Temperature (OAT) inputs"
				}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={
					"For each airport requiring custom inputs, select/enter custom values"
				}
			/>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={`${
					packageLevel === "Basic" ? "Airports 1 to 30" : "Airports 1 to 60"
				}`}
			/>
			<div className="lg:flex justify-center">
				<div className="flex justify-center">
					<UtilityClass.InputTable
						checkboxSmallerBottomMarginBool={true}
						checkboxLabelBottomMarginBool={false}
						indexBool={true}
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						checkboxes={true}
						label={"Override?"}
						captionBool={true}
						separateCheckboxes={true}
						showIndex={Array(packageLevel === "Basic" ? 30 : 60).fill(true)}
						value={setFirst}
						width="full"
						includeDefaults={defaultOverride}
						defaultVals={overrideInputs}
					/>
					<UtilityClass.InputTable
						checkboxLabelBottomMarginBool={false}
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						label="IATA Airport Codes"
						captionBool={true}
						separateCheckboxes={false}
						width="full"
						value={setFirst}
						includeDefaults={defaultAirport}
						defaultVals={
							defaultAirport
								? airportInputs
								: packageLevel === "Premium"
								? routeData
									? routeData.flat().slice(0, 60)
									: 0
								: routeData
								? routeData.flat().slice(0, 30)
								: 0
						}
						capitalize={true}
						regex={/^[A-Za-z]{0,3}$/}
					/>
					<UtilityClass.InputTable
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						captionBool={true}
						label="ISA (°C)"
						dropdown={true}
						internalTitle={["Please select"]}
						options={[
							[
								"ISA-10",
								"ISA-5",
								"ISA",
								"ISA+5",
								"ISA+10",
								"ISA+15",
								"ISA+20",
								"ISA+25",
								"ISA+30",
							],
						]}
						width="auto"
						value={secondTable}
						includeDefaults={defaultISA}
						defaultVals={isaInputs}
						dropdownKey="isa"
					/>
				</div>
				<div className={`${!windowLarge ? "ml-24" : ""}`}>
					<UtilityClass.InputTable captionBool={true} label={"OR"} />
				</div>

				<div className="flex justify-center">
					<UtilityClass.InputTable
						indexBool={!windowLarge}
						checkboxSmallerBottomMarginBool={true}
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						captionBool={true}
						label="OAT (°C)"
						width="full"
						value={setThird}
						includeDefaults={defaultOAT}
						defaultVals={oatInputs}
						regex={/^-?\d{0,3}$/}
					/>

					<UtilityClass.InputTable
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						captionBool={true}
						label="Rwy Wind (kts)"
						dropdown={true}
						options={[["5 TW", "0", "10 HW"]]}
						internalTitle={["Please select"]}
						width="auto"
						value={fourthTable}
						includeDefaults={defaultRwy}
						defaultVals={rwyInputs}
					/>
				</div>
				<div className="flex justify-center">
					<UtilityClass.InputTable
						indexBool={!windowLarge}
						checkboxSmallerBottomMarginBool={true}
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						captionBool={true}
						label="Taxi Out (min)"
						width="full"
						value={setFifth}
						includeDefaults={defaultTaxiOut}
						defaultVals={taxiOutInputs}
						regex={/^(|[1-9]\d{0,2})$/}
					/>
					<UtilityClass.InputTable
						indexBool={false}
						rowNum={packageLevel === "Basic" ? 30 : 60}
						colNum={1}
						captionBool={true}
						label="Taxi In (min)"
						width="full"
						value={setSixth}
						includeDefaults={defaultTaxiIn}
						defaultVals={taxiInInputs}
						regex={/^(|[1-9]\d{0,2})$/}
					/>
				</div>
			</div>
			{packageType === "Economic" ? (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType + "/AirportConditions"}
					// nextLink={"/forms/" + packageType + "/CostAssumptions"}
					nextLink={"/forms/" + packageType + "/CostAssumptions"}
					dispatchInputs={dispatchInputs}
					validationData
					validation={validateContinue()}
				/>
			) : (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType + "/AirportConditions"}
					nextLink={"/forms/" + packageType + "/InputReview"}
					dispatchInputs={dispatchInputs}
					validationData
					validation={validateContinue()}
				/>
			)}
		</motion.div>
	);
};

export default CustomAirportConditions;
