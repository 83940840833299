import React, { useEffect } from "react";
import { useState } from "react";
import Fetcher from "./commonAll/Fetcher";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { UtilityClass } from "../utility-class/UtilityClass";
import { selectOrderID } from "../reducers/performanceDataSlice";
import { selectEconOrderID } from "../reducers/econDataSlice";
import { selectDemandOrderID } from "../reducers/demandDataSlice";

const PackageSelection = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState("Initializing content...");
    let packageType = useSelector((state) => state.packageType.value);

    let econData = useSelector((state) => state.econData);
    let perfData = useSelector((state) => state.performanceData);
    let demandData = useSelector((state) => state.performanceData);

    let data;
    if (packageType === "Performance") {
        data = perfData;
    } else if (packageType === "Economic") {
        data = econData;
    } else {
        data = demandData;
    }

    let isFetched = data.fetched;
    const [isLoaded, setIsLoaded] = useState(isFetched);
    const [selected, setSelected] = useState({
        id: 0,
        orderNumber: 0,
        package: "Default",
        packageType: "Default",
        status: "Default",
    });
    const [secondarySelected, setSecondarySelected] = useState({
        id: 0,
        orderNumber: 0,
        package: "Default",
        packageType: "Default",
        status: "Default",
    });

    const splitStatus = status.split(" ")[0];

    const handleClick = () => {
        switch (selected.package) {
            case "Performance":
                dispatch(selectOrderID(selected.id));
                break;
            case "Economic":
                dispatch(selectEconOrderID(selected.id));
                break;
            case "Demand":
                dispatch(selectDemandOrderID(selected.id));
                break;
            default:
                break;
        }
        setStatus("Initializing using the selected order...");
    };

    return (
        <div className="flex flex-col justify-center w-full gap-8 h-screen items-center">
            <div
                className={
                    "flex max-h-[40em] overflow-auto w-full justify-center"
                }
            >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <Fetcher
                        status={status}
                        setStatus={setStatus}
                        selected={selected}
                        setSelected={setSelected}
                        isLoaded={isLoaded}
                        setIsLoaded={setIsLoaded}
                        secondarySelected={secondarySelected}
                        setSecondarySelected={setSecondarySelected}
                    />
                </motion.div>
            </div>
            {splitStatus !== "Initializing" &&
                splitStatus !== "ERROR" &&
                splitStatus !== "No" &&
                splitStatus !== "Finished" && (
                    <div className="flex justify-center w-full">
                        <div className="flex justify-center">
                            <div className="w-[15em]">
                                <UtilityClass.Button
                                    text="CONFIRM"
                                    width={"full"}
                                    colorRed={true}
                                    noLink
                                    marginBool={true}
                                    onClickCommand={handleClick}
                                />
                            </div>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default PackageSelection;
