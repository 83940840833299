import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	orderNumber: 0,
	orderID: 0,
	faPerAircraftData: [],
	pilotsPerAircraftData: [],
	fetched: false,
	OEMList: [],
	location: "",
	OEMChoice: "Default",
	acType: [],
	acList: [],
	acDefault: "No, use default values.",
	OEMChoice2: "Default",
	acType2: [],
	pilotCrewData: [],
	cabinCrewData: [],
	normalSpeedMachData: [],
	acDefault2: "No, use default values.",
	logoUpload: {
		logoChoice: "No",
		logoImage: undefined,
		resultChoice: "No",
	},
	submission: {
		firstName: "",
		lastName: "",
		company: "",
		phone: "",
		email: "",
	},
	RnD: {
		iataAirportCode: undefined,
		gcd: undefined,
		customDist: undefined,
		distanceUnit: "Nautical Miles",
	},
	RnD2: {
		iataAirportCode: undefined,
		gcd: undefined,
		customDist: undefined,
		distanceUnit: "Nautical Miles",
	},
	config: {
		seats: undefined,
		mtow: undefined,
		mlw: undefined,
		owe: undefined,
		payload: undefined,
		unit: "Kg",
	},
	configRows: undefined,
	configTwo: {
		seats: undefined,
		mtow: undefined,
		mlw: undefined,
		owe: undefined,
		payload: undefined,
		unit: undefined,
	},
	configRowsTwo: undefined,
	conditions: {
		temp: "ISA",
		wind: "Annual",
		windProb: undefined,
		fuelReserve: "FAR International",
		payloadCrit: "Passenger Load",
		payloadFactor: undefined,
		pbAllowance: undefined,
		aircraftUtil: undefined,
		acType: undefined,
		climbSpeed: "Default",
		cruiseSpeed: "Long Range Cruise",
		descentSpeed: "Default",
		fixedMach: undefined,
		divClimbSpeed: "Default",
		divCruiseSpeed: "Long Range Cruise",
		divDescSpeed: "Default",
		divFixedMach: undefined,
		leaseMarketValue1: undefined,
	},
	conditionsTwo: {
		aircraftUtil2: undefined,
		climbSpeed: "Default",
		cruiseSpeed: "Long Range Cruise",
		descentSpeed: "Default",
		fixedMach: undefined,
		divClimbSpeed: "Default",
		divCruiseSpeed: "Long Range Cruise",
		divDescSpeed: "Default",
		divFixedMach: undefined,
		leaseMarketValue2: undefined,
	},
	airportConditions: {
		ISA: [],
		rwyWind: [],
		taxiOut: [],
		taxiIn: [],
		input: "No, use global assumptions.",
	},
	customAirportConditions: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	customAirportConditions2: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	customAirportConditions3: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	fixedOperatingCostChoice: "No",
	aircraftInsurance1: "",
	aircraftAcquisition: "",
	aircraftInsurance2: "",
	aircraftAcquisition2: "",
	aircraftOwnership1: "Finance",
	aircraftOwnership2: "Finance",
	leaseRate1: undefined,
	leaseRate2: undefined,
	leaseRateData: [],
	marketData: [],
	downPaymentData: [],
	interestRateData: [],
	paymentTermData: [],
	acquisitionPriceData: [],
	fuelPriceData: {},
	financeAssumptions1: {
		aircraftPrice: undefined,
		downPayment: undefined,
		interestRate: undefined,
		paymentTerm: undefined,
		marketValue: undefined,
	},
	financeAssumptions2: {
		aircraftPrice: undefined,
		downPayment: undefined,
		interestRate: undefined,
		paymentTerm: undefined,
		marketValue: undefined,
	},
	region: "",
	regionData: [],
	cashOperatingCosts: {
		fuelPrice: undefined,
		fuelPriceUnit: "Per US Gallon",
	},
	crewLoading1: {
		flightCrewLoading: {
			number: undefined,
			pilotSalary: undefined,
			pilotYearlyBenefit: undefined,
			coPilotSalary: undefined,
			coPilotYearlyBenefit: undefined,
		},
		cabinCrewLoading: {
			number: undefined,
			faPerAircraft: undefined,
			faSalaryPerYear: undefined,
			faYearlyBenefit: undefined,
		},
		bhCosts: {
			pilotBH: undefined,
			coPilotBH: undefined,
			faBH: undefined,
		},
	},
	crewLoading2: {
		flightCrewLoading: {
			number: undefined,
			pilotSalary: undefined,
			pilotYearlyBenefit: undefined,
			coPilotSalary: undefined,
			coPilotYearlyBenefit: undefined,
		},
		cabinCrewLoading: {
			number: undefined,
			faPerAircraft: undefined,
			faSalaryPerYear: undefined,
			faYearlyBenefit: undefined,
		},
		bhCosts: {
			pilotBH: undefined,
			coPilotBH: undefined,
			faBH: undefined,
		},
	},
	airportNavigationChoice: undefined,
	airportNavigationCharges: {
		landingUnit: undefined,
		landingRate: undefined,
		termNavigationRate: undefined,
		termNavigationUnit: undefined,
		routeNavigationRate: undefined,
		routeNavigationUnit: undefined,
		passengerHandling: undefined,
		aircraftHandlingRate: undefined,
		aircraftHandlingUnit: undefined,
	},
	airportNavigationChargesTwo: {
		landingUnit: undefined,
		landingRate: undefined,
		termNavigationRate: undefined,
		termNavigationUnit: undefined,
		routeNavigationRate: undefined,
		routeNavigationUnit: undefined,
		passengerHandling: undefined,
		aircraftHandlingRate: undefined,
		aircraftHandlingUnit: undefined,
	},
	insuranceData: [],
};

export const econDataSlice = createSlice({
	name: "econData",
	initialState,
	reducers: {
		selectEconOrderNumber: (state, arrayData) => {
			state.orderNumber = arrayData.payload;
			// console.log(state.fetched);
		},
		selectEconOrderID: (state, arrayData) => {
			state.orderID = arrayData.payload;
		},
		selectEconSubmission: (state, arrayData) => {
			state.submission = arrayData.payload;
			//console.log(state.submission);
		},
		selectEconLogoUpload: (state, arrayData) => {
			state.logoUpload = arrayData.payload;
			// console.log(state.fetched);
		},
		selectRegionData: (state, arrayData) => {
			state.regionData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectFaPerAircraftData: (state, arrayData) => {
			state.faPerAircraftData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectPilotsPerAircraftData: (state, arrayData) => {
			state.pilotsPerAircraftData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectCabinCrewData: (state, arrayData) => {
			state.cabinCrewData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectPilotCrewData: (state, arrayData) => {
			state.pilotCrewData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectRegion: (state, arrayData) => {
			state.region = arrayData.payload;
			// console.log(state.region);
		},
		selectEconNormalSpeedMachData: (state, arrayData) => {
			state.normalSpeedMachData = arrayData.payload;
		},
		selectMarketData: (state, arrayData) => {
			state.marketData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectDownPaymentData: (state, arrayData) => {
			state.downPaymentData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectInterestRateData: (state, arrayData) => {
			state.interestRateData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectPaymentTermData: (state, arrayData) => {
			state.paymentTermData = arrayData.payload;
			// console.log(state.fetched);
		},
		selectFuelPriceData: (state, arrayData) => {
			state.fuelPriceData = arrayData.payload;
			// console.log(state.fetched);
		},

		selectEconFetched: (state, arrayData) => {
			state.fetched = arrayData.payload;
			// console.log(state.fetched);
		},
		selectLocation: (state, arrayData) => {
			state.location = arrayData.payload;
			console.log(state.OEMChoice);
		},
		selectEconOEMList: (state, arrayData) => {
			state.OEMList = arrayData.payload;
			// console.log(state.OEMList);
		},
		selectEconOEM: (state, arrayData) => {
			state.OEMChoice = arrayData.payload;
			// console.log(state.OEMChoice);
		},
		selectEconOEM2: (state, arrayData) => {
			state.OEMChoice2 = arrayData.payload;
			// console.log(state.OEMChoice2);
		},
		selectEconACType: (state, arrayData) => {
			state.acType = arrayData.payload;
			// console.log(state.acType);
		},
		selectEconACList: (state, arrayData) => {
			state.acList = arrayData.payload;
			// console.log(state.acType);
		},
		selectEconACType2: (state, arrayData) => {
			state.acType2 = arrayData.payload;
			// console.log(state.acType2);
		},
		selectEconACDefault: (state, arrayData) => {
			state.acDefault = arrayData.payload;
			console.log(state.acDefault);
		},
		selectEconACDefault2: (state, arrayData) => {
			state.acDefault2 = arrayData.payload;
			// console.log(state.acDefault2);
		},
		selectEconRnD: (state, arrayData) => {
			state.RnD = arrayData.payload;
			// console.log(state.RnD);
		},
		selectEconRnD2: (state, arrayData) => {
			state.RnD2 = arrayData.payload;
			// console.log(state.RnD2);
		},
		chooseFirstEconWeight: (state, arrayData) => {
			state.config = arrayData.payload;
			// console.log(state.config);
		},
		selectEconConfigRows: (state, arrayData) => {
			state.configRows = arrayData.payload;
			// console.log(state.config);
		},
		chooseSecondEconWeight: (state, arrayData) => {
			state.configTwo = arrayData.payload;
			// console.log(state.configTwo);
		},
		selectEconConfigRowsTwo: (state, arrayData) => {
			state.configRowsTwo = arrayData.payload;
			// console.log(state.config);
		},
		selectEconConditions: (state, arrayData) => {
			state.conditions = arrayData.payload;
			// console.log(state.conditions);
		},
		selectEconConditionsLeaseMarket: (state, arrayData) => {
			state.conditions.leaseMarketValue1 = arrayData.payload;
		},
		selectEconConditionsTwo: (state, arrayData) => {
			state.conditionsTwo = arrayData.payload;
			console.log(state.conditionsTwo);
		},
		selectEconConditionsLeaseMarketTwo: (state, arrayData) => {
			state.conditionsTwo.leaseMarketValue2 = arrayData.payload;
		},
		selectFixedOperatingCostChoice: (state, arrayData) => {
			state.fixedOperatingCostChoice = arrayData.payload;
			// console.log(state.fixedOperatingCostChoice);
		},
		selectAircraftAcquisition: (state, arrayData) => {
			state.aircraftAcquisition = arrayData.payload;
			// console.log(state.fixedOperatingCostChoice);
		},
		selectAircraftAcquisition2: (state, arrayData) => {
			state.aircraftAcquisition2 = arrayData.payload;
			// console.log(state.fixedOperatingCostChoice);
		},
		selectAcquisitionPriceData: (state, arrayData) => {
			state.acquisitionPriceData = arrayData.payload;
			// console.log(state.fixedOperatingCostChoice);
		},
		selectEconAircraftInsurance: (state, arrayData) => {
			if (arrayData.payload.aircraftNbr === 1) {
				state.aircraftInsurance1 = arrayData.payload.aircraftInsurance;
			} else if (arrayData.payload.aircraftNbr === 2) {
				state.aircraftInsurance2 = arrayData.payload.aircraftInsurance;
			}
		},
		selectAircraftOwnershipChoice: (state, arrayData) => {
			if (arrayData.payload.aircraftNbr === 1) {
				state.aircraftOwnership1 = arrayData.payload.aircraftOwnership;
			} else if (arrayData.payload.aircraftNbr === 2) {
				state.aircraftOwnership2 = arrayData.payload.aircraftOwnership;
			}
		},
		selectEconLeaseRate: (state, arrayData) => {
			if (arrayData.payload.aircraftNbr === 1) {
				state.leaseRate1 = arrayData.payload.leaseRate;
			} else if (arrayData.payload.aircraftNbr === 2) {
				state.leaseRate2 = arrayData.payload.leaseRate;
			}
		},

		selectLeaseRateData: (state, arrayData) => {
			state.leaseRateData = arrayData.payload;
			//console.log(state.leaseRateData)
		},
		selectFinanceAssumptions: (state, arrayData) => {
			if (arrayData.payload.aircraftNbr === 1) {
				state.financeAssumptions1 = arrayData.payload.data;
			} else if (arrayData.payload.aircraftNbr === 2) {
				state.financeAssumptions2 = arrayData.payload.data;
			}
		},
		selectCashOperatingCosts: (state, arrayData) => {
			state.cashOperatingCosts = arrayData.payload;
			// console.log(state.cashOperatingCosts);
		},
		selectCrewLoading1: (state, arrayData) => {
			state.crewLoading1 = arrayData.payload;
			console.log("dispatched here");
			// console.log(state.cashOperatingCosts);
		},
		selectCrewLoading2: (state, arrayData) => {
			state.crewLoading2 = arrayData.payload;
			// console.log(state.cashOperatingCosts);
		},
		selectCustomEconAirportConditions: (state, arrayData) => {
			state.customAirportConditions = arrayData.payload;
		},
		selectCustomEconAirportConditions2: (state, arrayData) => {
			state.customAirportConditions2 = arrayData.payload;
		},
		selectCustomEconAirportConditions3: (state, arrayData) => {
			state.customAirportConditions3 = arrayData.payload;
		},
		selectEconAirportConditions: (state, arrayData) => {
			state.airportConditions = arrayData.payload;
		},
		selectAirportNavigationChoice: (state, arrayData) => {
			state.airportNavigationChoice = arrayData.payload;
			// console.log(state.airportNavigationChoice);
		},
		selectAirportNavigationCharges: (state, arrayData) => {
			state.airportNavigationCharges = arrayData.payload;
			// console.log(state.airportNavigationCharges);
		},
		selectAirportNavigationChargesTwo: (state, arrayData) => {
			state.airportNavigationChargesTwo = arrayData.payload;
			// console.log(state.airportNavigationChargesTwo);
		},
		selectInsuranceData: (state, arrayData) => {
			state.insuranceData = arrayData.payload;
		},
		resetEcon: (state) => {
			state.orderNumber = 0;
			state.orderID = 0;
			state.pilotsPerAircraftData = [];
			state.faPerAircraftData = [];
			state.region = "";
			state.regionData = [];
			state.marketData = [];
			state.downPaymentData = [];
			state.interestRateData = [];
			state.paymentTermData = [];
			state.cabinCrewData = [];
			state.normalSpeedMachData = [];
			state.pilotCrewData = [];
			state.fuelPriceData = {};
			state.fetched = false;
			state.OEMList = [];
			state.location = "";
			state.OEMChoice = "Default";
			state.acType = [];
			state.acList = [];
			state.OEMChoice2 = "Default";
			state.acType2 = [];
			state.acDefault = "No, use default values.";
			state.acDefault2 = "No, use default values.";
			state.logoUpload = {
				logoChoice: "No",
				logoImage: undefined,
				resultChoice: "No",
			};
			state.RnD = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
				distanceUnit: "Nautical Miles",
			};
			state.RnD2 = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
				distanceUnit: "Nautical Miles",
			};
			state.RnD2 = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
			};
			state.config = {
				seats: undefined,
				mtow: undefined,
				mlw: undefined,
				owe: undefined,
				payload: undefined,
				unit: "Kg",
			};
			state.configRows = undefined;
			state.configTwo = {
				seats: undefined,
				mtow: undefined,
				mlw: undefined,
				owe: undefined,
				payload: undefined,
				unit: "Kg",
			};
			state.configRowsTwo = undefined;
			state.conditions = {
				temp: "ISA",
				wind: "Annual",
				windProb: undefined,
				fuelReserve: "FAR International",
				payloadCrit: "Passenger Load",
				payloadFactor: undefined,
				pbAllowance: undefined,
				aircraftUtil: undefined,
				acType: undefined,
				climbSpeed: "Default",
				cruiseSpeed: "Long Range Cruise",
				descentSpeed: "Default",
				fixedMach: undefined,
				divClimbSpeed: "Default",
				divCruiseSpeed: "Long Range Cruise",
				divDescSpeed: "Default",
				divFixedMach: undefined,
				leaseMarketValue1: undefined,
			};
			state.conditionsTwo = {
				aircraftUtil2: undefined,
				climbSpeed: "Default",
				cruiseSpeed: "Long Range Cruise",
				descentSpeed: "Default",
				fixedMach: undefined,
				divClimbSpeed: "Default",
				divCruiseSpeed: "Long Range Cruise",
				divDescSpeed: "Default",
				divFixedMach: undefined,
				leaseMarketValue2: undefined,
			};
			state.customAirportConditions = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
			state.airportConditions = {
				ISA: "ISA",
				rwyWind: "0",
				taxiOut: "10",
				taxiIn: "10",
				input: "No, use global assumptions.",
			};
			state.customAirportConditions2 = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
			state.submission = {
				firstName: "",
				lastName: "",
				company: "",
				phone: "",
				email: "",
			};
			state.customAirportConditions3 = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
			state.fixedOperatingCostChoice = "No";
			state.aircraftInsurance1 = "";
			state.aircraftAcquisition = "";
			state.aircraftInsurance2 = "";
			state.insuranceData = [];
			state.aircraftAcquisition2 = "";
			state.aircraftOwnership1 = "Finance";
			state.aircraftOwnership2 = "Finance";
			state.leaseRate1 = undefined;
			state.leaseRate2 = undefined;
			state.leaseRateData = [];
			state.financeAssumptions1 = {
				aircraftPrice: undefined,
				downPayment: undefined,
				interestRate: undefined,
				paymentTerm: undefined,
				marketValue: undefined,
			};
			state.financeAssumptions2 = {
				aircraftPrice: undefined,
				downPayment: undefined,
				interestRate: undefined,
				paymentTerm: undefined,
				marketValue: undefined,
			};
			state.crewLoading1 = {
				flightCrewLoading: {
					number: undefined,
					pilotSalary: undefined,
					pilotYearlyBenefit: undefined,
					coPilotSalary: undefined,
					coPilotYearlyBenefit: undefined,
				},
				cabinCrewLoading: {
					number: undefined,
					faPerAircraft: undefined,
					faSalaryPerYear: undefined,
					faYearlyBenefit: undefined,
				},
				bhCosts: {
					pilotBH: undefined,
					coPilotBH: undefined,
					faBH: undefined,
				},
			};
			state.crewLoading2 = {
				flightCrewLoading: {
					number: undefined,
					pilotSalary: undefined,
					pilotYearlyBenefit: undefined,
					coPilotSalary: undefined,
					coPilotYearlyBenefit: undefined,
				},
				cabinCrewLoading: {
					number: undefined,
					faPerAircraft: undefined,
					faSalaryPerYear: undefined,
					faYearlyBenefit: undefined,
				},
			};
			state.cashOperatingCosts = {
				fuelPrice: undefined,
				fuelPriceUnit: "Per US Gallon",
			};
			state.acquisitionPriceData = [];
			state.airportNavigationChoice = undefined;
			state.airportNavigationCharges = {
				landingUnit: undefined,
				landingRate: undefined,
				termNavigationRate: undefined,
				termNavigationUnit: undefined,
				routeNavigationRate: undefined,
				routeNavigationUnit: undefined,
				passengerHandling: undefined,
				aircraftHandlingRate: undefined,
				aircraftHandlingUnit: undefined,
			};
			state.airportNavigationChargesTwo = {
				landingUnit: undefined,
				landingRate: undefined,
				termNavigationRate: undefined,
				termNavigationUnit: undefined,
				routeNavigationRate: undefined,
				routeNavigationUnit: undefined,
				passengerHandling: undefined,
				aircraftHandlingRate: undefined,
				aircraftHandlingUnit: undefined,
			};
		},
	},
});

export const {
	selectFaPerAircraftData,
	selectEconOrderID,
	selectEconOrderNumber,
	selectEconSubmission,
	selectPilotsPerAircraftData,
	selectCabinCrewData,
	selectPilotCrewData,
	selectRegion,
	selectEconNormalSpeedMachData,
	selectRegionData,
	selectMarketData,
	selectDownPaymentData,
	selectInterestRateData,
	selectPaymentTermData,
	selectFuelPriceData,
	selectEconFetched,
	selectEconOEMList,
	selectLocation,
	selectEconOEM,
	selectEconLogoUpload,
	selectEconOEM2,
	selectEconACType,
	selectEconACType2,
	selectEconACList,
	selectEconACDefault,
	selectEconACDefault2,
	selectEconRnD,
	selectEconRnD2,
	selectEconConditions,
	selectEconConditionsTwo,
	selectEconConditionsLeaseMarket,
	selectEconConditionsLeaseMarketTwo,
	selectAircraftAcquisition,
	selectAircraftAcquisition2,
	selectAcquisitionPriceData,
	selectEconConfigRowsTwo,
	selectInsuranceData,
	selectEconConfigRows,
	chooseEconConfig,
	chooseFirstEconWeight,
	chooseSecondEconWeight,
	selectFixedOperatingCostChoice,
	selectEconAircraftInsurance,
	selectAircraftOwnershipChoice,
	selectEconLeaseRate,
	selectLeaseRateData,
	selectFinanceAssumptions,
	selectCashOperatingCosts,
	selectCrewLoading1,
	selectCrewLoading2,
	selectCustomEconAirportConditions,
	selectCustomEconAirportConditions2,
	selectCustomEconAirportConditions3,
	selectAirportNavigationChoice,
	selectAirportNavigationCharges,
	selectAirportNavigationChargesTwo,
	selectEconAirportConditions,
	resetEcon,
} = econDataSlice.actions;
export default econDataSlice.reducer;
