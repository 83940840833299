import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UtilityClass } from "../utility-class/UtilityClass";
import { useLocation, useNavigate } from "react-router-dom";
import { resetLevel } from "../reducers/packageLevelSlice";
import { resetType } from "../reducers/packageTypeSlice";
import { resetValidation } from "../reducers/inputReviewValidationSlice";
import { resetPerf } from "../reducers/performanceDataSlice";
import { resetEcon } from "../reducers/econDataSlice";
import { resetDemand } from "../reducers/demandDataSlice";
import { motion } from "framer-motion";
import { resetSession } from "../reducers/validSessionSlice";

const Header = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [x, setX] = useState();
	const [modal, setModal] = useState();
	// const [footer, setFooter] = useState();

	let packageType = useSelector((state) => state.packageType.value);

	let orderNumber = useSelector((state) => {
		if (packageType === "Performance") {
			return state.performanceData.orderNumber;
		} else if (packageType === "Economic") {
			return state.econData.orderNumber;
		} else {
			return state.demandData.orderNumber;
		}
	});

	useEffect(() => {
		setX(document.getElementsByTagName("BODY")[0]); // Select body tag because of disable scroll when modal is active
		setModal(document.getElementById("modal-cancel")); // modal
		// setFooter(document.getElementsByTagName("footer")[0]); // Select footer
	}, [x, modal]);

	const openCancelModal = () => {
		modal.style.display = "flex"; // Show modal
		x.style.overflow = "hidden"; // Disable scroll on body
		// footer.style.display = "none"; // Hide footer
	};

	const closeCancelModal = () => {
		modal.style.display = "none"; // Hide modal
		x.style.overflow = "auto"; // Active scroll on body
		// footer.style.display = "flex"; // Show footer
	};

	// each time we add a reducer, we need to reset it to default in this function!
	const confirmCancelModal = () => {
		dispatch(resetLevel());
		dispatch(resetType());
		dispatch(resetValidation());
		dispatch(resetPerf());
		dispatch(resetEcon());
		dispatch(resetDemand());
		dispatch(resetSession());
		navigate(`/forms/?order=${orderNumber}`, { replace: true });
		modal.style.display = "none"; // Hide modal
		x.style.overflow = "auto"; // Active scroll on body
		// footer.style.display = "flex"; // Show footer
	};

	return (
		<>
			<nav
				id="sticky-header"
				className="bg-white relative z-20 pt-2 sticky w-full top-0 left-0 w-full"
			>
				<div className="absolute top-0 right-0 pt-4 z-40">
					{location.pathname !== "/forms/" && (
						<UtilityClass.Button
							icon={"cancel"}
							noLink
							onClickCommand={() => {
								openCancelModal();
							}}
						></UtilityClass.Button>
					)}
				</div>
				<div className="flex flex-col gap-12 w-full">
					<div className="flex w-full">
						<div className="text-center w-full absolute z-0">
							<h1
								className="text-3xl font-bold select-none cursor-default"
								style={{ color: "#a50005" }}
							>
								{props.title}
							</h1>
							<h1 className="text-xl font-semibold select-none cursor-default">
								{props.description}
							</h1>
						</div>
					</div>

					<div>
						{props.count !== undefined && (
							<div className="select-none cursor-default mt-2 sticky top-10">
								{/* Page {props.count[0]} / {props.count[1]} */}
								{"\u00A0"}
							</div>
						)}
						<div className="w-full bg-gray-200 h-1 mt-1 select-none">
							<div
								className="h-1 rounded-full"
								style={{
									width: `${props.progressWidth}`,
									backgroundColor: "#a50005",
								}}
							/>
						</div>
					</div>
				</div>
			</nav>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.1 }}
			>
				<div
					id="modal-cancel"
					className="hidden items-center justify-center h-screen w-screen fixed top-0 left-0 bg-black/50 cursor-default z-50"
				>
					<div className="bg-white max-w-md px-6 py-4 w-full rounded-md">
						<div className="px-3 pt-1 flex justify-center">
							<h3 className="font-semibold text-xl select-none">Restart?</h3>
						</div>
						<div className="p-3">
							<p className="text-center select-none">
								Pressing confirm will restart the current session.
								<br />
								This action cannot be undone.
							</p>
						</div>
						<div className="p-3 flex flex-col gap-2">
							<UtilityClass.Button
								text="CONFIRM"
								width={"full"}
								noLink
								colorRed={true}
								marginBool={true}
								onClickCommand={() => {
									confirmCancelModal();
								}}
							></UtilityClass.Button>
							<UtilityClass.Button
								text="BACK"
								width={"full"}
								icon={"back"}
								noLink
								marginBool={true}
								onClickCommand={() => closeCancelModal()}
							></UtilityClass.Button>
						</div>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default Header;
