import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    validSession: true,
};

export const validSessionSlice = createSlice({
    name: "inputReviewValidation",
    initialState,
    reducers: {
        setValidSession: (state, action) => {
            state.validSession = action.payload;
            // console.log(state.value);
        },
        resetSession: (state) => {
            state.validSession = true;
            // console.log(state.value);
        },
    },
});

export const { setValidSession, resetSession } = validSessionSlice.actions;
export default validSessionSlice.reducer;
