import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const SelectedAircraft = () => {
    let packageType = useSelector((state) => state.packageType.value);
    let packageLevel = useSelector((state) => state.packageLevel.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}

    let acType1 = "";
    let acType2 = "";
    if (packageType === "Performance") {
        if (perfData.acType.length > 0){ 
            acType1 = data.acType;
        } else { 
            acType1 = "Default"
        }
        if (perfData.acType2.length > 0){ 
            acType2 = data.acType2;
        } else { 
            acType2 = "Default"
        }
    } else {
        if (econData.acType.length > 0){ 
            acType1 = data.acType;
        } else { 
            acType1 = "Default"
        }
        if (econData.acType2.length > 0){ 
            acType2 = data.acType2;
        } else { 
            acType2 = "Default"
        }
    }

    let inputChoice = data.acDefault2;

    return (
        <motion.div
        initial={{ opacity:0 } }
        animate={{ opacity: 1}}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="bold"
                color="black"
                content={`Selected Aircraft Type`}
            />
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="semibold"
                color="black"
                content={
                    packageLevel === "Premium"
                        ? `Aircraft 1: ${acType1}\n Aircraft 2: ${acType2}`
                        : `Aircraft 1: ${acType1}`
                }
            />
            <br />
            <br />
            <br />
            <br />
            <UtilityClass.CustomText
                textSize="title"
                fontWeight="semibold"
                color="red"
                content={`The following routes and Operating assumptions will be applied to all aircraft types`}
            />
            <br />
            <UtilityClass.BackNextButtons
                nextButtonText="NEXT"
                backLink={
                    packageLevel === "Premium"
                        && inputChoice === 'No, use default values.'
                        ?"/forms/" + packageType + "/AircraftEngine2"
                        :"/forms/" + packageType + "/CustomConfigWeights2"
                }
                nextLink={"/forms/" + packageType + "/RoutesAndDistances"}
                validation={true}
            />
        </motion.div>
    );
};

export default SelectedAircraft;
