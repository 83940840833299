import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectOEM, selectACType } from "../../reducers/performanceDataSlice";
import { selectEconOEM, selectEconACType } from "../../reducers/econDataSlice";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { motion } from "framer-motion";

const SelectOEM = (props) => {
    let packageLevel = useSelector((state) => state.packageLevel.value);
    let packageType = useSelector((state) => state.packageType.value);

    let econData = useSelector((state) => state.econData);
    let perfData = useSelector((state) => state.performanceData);
    let data;
    if (packageType === "Performance") {
        data = perfData;
    } else {
        data = econData;
    }

    let OEMChoice = data.OEMChoice;
    let acType = data.acType;
    let OEMList = data.OEMList;

    const [defaultOEM, setDefaultOEM] = useState(
        OEMChoice === undefined ? false : true
    );

    const dispatch = useDispatch();

    const [, setOEM] = useState(); // to save the choice in a state in the parent component
    const chooseOEM = (type) => {
        // function called in the prop value
        setOEM(type);
        if (packageType === "Performance") {
            dispatch(selectOEM(type));
            if (!acType) {
                dispatch(selectACType([]));
            }
        } else if (packageType === "Economic") {
            dispatch(selectEconOEM(type));
            if (!acType) {
                dispatch(selectEconACType([]));
            }
        }
    };

    return (
        <>
            {/* <div
            class='flex w-full h-full justify-center'>
                <div
                className={!isLoaded ? `w-full h-full relative z-0 transition-opacity ease-in duration-300 opacity-0` 
                : 'w-full h-full relative z-10 transition-opacity ease-in duration-300 delay-500 opacity-100'}> */}
            <motion.div>
                {OEMList.length > 0 && (
                    <UtilityClass.OEMSelection
                        title={`${
                            packageLevel === "Basic"
                                ? "Select OEM"
                                : "Select OEM for aircraft #1"
                        }`}
                        OEM={OEMList}
                        link={"AircraftEngine"}
                        value={chooseOEM}
                        defaultValue={defaultOEM ? OEMChoice : []}
                    />
                )}
            </motion.div>

            {/* </div>
            </div>
            <div
               className={!isLoaded
                    ? `absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 transition-opacity ease-in duration-400 opacity-100 z-0`
                    : `absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 transition-opacity ease-in duration-400 opacity-0 z-0`}>
                <Fetcher 
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    />
            </div> */}
        </>
    );
};

export default SelectOEM;
