import React, { useState, useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectRnD2 } from "../../reducers/performanceDataSlice";
import { selectEconRnD2, selectLocation } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";
import { processTable } from "../../util/helpers";

const RoutesAndDistances2 = () => {
    const dispatch = useDispatch();

    // redux calls
    let packageType = useSelector((state) => state.packageType.value);
    let packageLevel = useSelector((state) => state.packageLevel.value);

    let econData = useSelector((state) => state.econData);
    let perfData = useSelector((state) => state.performanceData);
    let data;
    if (packageType === "Performance") {
        data = perfData;
    } else {
        data = econData;
    }

    let apCode = data.RnD2.iataAirportCode;
    let gcdInputs = data.RnD2.gcd;
    let cDistance = data.RnD2.customDist;
    let distanceUnit = data.RnD2.distanceUnit;

    let RnDInputs = {
        distanceUnit: "",
        iataAirportCode: [[], []],
        gcd: [],
        customDist: [[], []],
    };

    const [custom, setCustom] = useState(); // to save the choice in a state in the parent component

    const [, setFirst] = useState();
    const [, setSecond] = useState();
    const [, setThird] = useState();

    const [defaultapCodes, setdefaultapCodes] = useState(
        apCode === undefined || apCode.length === 0 ? false : true
    );
    const [defaultDistance, setDefaultDistance] = useState(
        cDistance === undefined || cDistance.length === 0 ? false : true
    );
    const [defaultGCD, setDefaultGCD] = useState(
        gcdInputs === undefined || gcdInputs.length === 0 ? false : true
    );

    const [defaultUnit, setDefaultUnit] = useState(
        distanceUnit === undefined ? false : true
    );

    const [gcdArr, setGcdArr] = useState([]);
    const secondTable = (type, index) => {
        setGcdArr([...gcdArr, [type, index]]);
        setSecond(type);
    };

    // preload the values
    useEffect(() => {
        if (defaultGCD) {
            gcdInputs.forEach((value) => {
                gcdArr.push(value);
            });
        }
    }, []);

    const breakArray = (arr, chunkSize) => {
        const out = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            out.push(chunk);
        }
        return out;
    };

    // check screen width
    var windowWidth = window.innerWidth;
    const [windowLarge, setWindowLarge] = useState(windowWidth >= 1024);
    window.onresize = handleResize;
    function handleResize() {
        windowWidth = window.innerWidth;
        if (windowWidth < 1024) {
            setWindowLarge(false);
        } else if (windowWidth <= 1441) {
            setWindowLarge(true);
        } else {
            setWindowLarge(true);
        }
    }

    let dropDownNodes = document.querySelectorAll(
        'div[data-type="select"] > select'
    );
    let inputNodes = document.querySelectorAll(
        'div[data-type="input"] > div > div > div > input'
    );
    let dropDownRoutes = Array.from(dropDownNodes).filter((elem, index) => {
        return index % 2 == 0;
    });
    let dropDownDiversion = Array.from(dropDownNodes).filter((elem, index) => {
        return (index + 1) % 2 == 0;
    });
    let inputRoutes = Array.from([...inputNodes])
        .slice(30)
        .filter((elem, index) => {
            return index % 2 == 0;
        });
    let inputDiversion = Array.from([...inputNodes])
        .slice(30)
        .filter((elem, index) => {
            return (index + 1) % 2 == 0;
        });

    function addRouteDistanceEventListeners(
        gcdRoute,
        gcdDiversion,
        distanceRoute,
        distanceDiversion,
        index
    ) {
        function clearGcdRoute() {
            gcdRoute.value = "Route";
            cleanGcdArr([index, 0]);
        }
        function clearGcdDiversion() {
            gcdDiversion.value = "Diversion";
            cleanGcdArr([index, 1]);
        }
        // need to call it this way bc of how react handles controlled vs uncontrolled
        // forms -> it forces a rerender of the input value
        function clearDistanceRoute() {
            Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            ).set.call(distanceRoute, "");
            distanceRoute.dispatchEvent(new Event("input", { bubbles: true }));
        }
        function clearDistanceDiversion() {
            Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            ).set.call(distanceDiversion, "");
            distanceDiversion.dispatchEvent(
                new Event("input", { bubbles: true })
            );
        }
        function cleanGcdArr(index) {
            gcdArr.forEach((value, innerIndex) => {
                if (value[1][0] === index[0] && value[1][1] === index[1]) {
                    gcdArr.splice(innerIndex, 1);
                }
            });
        }

        try {
            gcdRoute.addEventListener("change", clearDistanceRoute);
            gcdDiversion.addEventListener("change", clearDistanceDiversion);
            distanceRoute.addEventListener("change", clearGcdRoute);
            distanceDiversion.addEventListener("change", clearGcdDiversion);
        } catch {}
    }

    dropDownRoutes.map((item, index) => {
        addRouteDistanceEventListeners(
            item,
            dropDownDiversion[index],
            inputRoutes[index],
            inputDiversion[index],
            index
        );
    });

    const dispatchInputs = () => {
        const apArr = processTable(Array.from([...inputNodes]).slice(0, 30), 3);
        const cdArr = processTable(Array.from([...inputNodes]).slice(30), 2);
        RnDInputs.iataAirportCode = apArr;
        RnDInputs.gcd = gcdArr;
        RnDInputs.customDist = cdArr;
        RnDInputs.distanceUnit = custom;
        if (packageType === "Performance") {
            dispatch(selectRnD2(RnDInputs));
        } else if (packageType === "Economic") {
            dispatch(selectEconRnD2(RnDInputs));
        }
    };

    const validateContinue = () => {
        let validated = true;
        let iataInputs = Array.from([...inputNodes]).slice(0, 30);

        let distanceInputs = Array.from([...inputNodes]).slice(30, 60);
        let dropdownInputs = Array.from([...dropDownNodes]);

        let increment = 0;
        const invalidInputs = [];
        const validMatrix = [];
        let currentRow = [];
        iataInputs.forEach((input, index) => {
            const rowIndex = Math.floor(index / 3);
            // this checks for invalid iata codes
            if (input.value.length !== 3 && input.value !== "") {
                invalidInputs.push(input.value.toUpperCase());
            }
            currentRow.push(input.value);
            increment++;
            if (increment === 3) {
                const currentDropdownRow = dropdownInputs.slice(
                    rowIndex * 2,
                    rowIndex * 2 + 2
                );
                const currentDistanceRow = distanceInputs.slice(
                    rowIndex * 2,
                    rowIndex * 2 + 2
                );

                if (
                    currentRow.every((element) => element.trim().length === 3)
                ) {
                    if (
                        (currentDropdownRow[0].value.trim() !== "Route" ||
                            currentDistanceRow[0].value.trim() !== "") &&
                        (currentDropdownRow[1].value.trim() !== "Diversion" ||
                            currentDistanceRow[1].value.trim() !== "")
                    ) {
                        validMatrix.push(true);
                    } else {
                        validMatrix.push(false);
                    }
                } else if (!currentRow.every((element) => element === "")) {
                    validMatrix.push(false);
                } else if (currentRow.every((element) => element === "")) {
                    if (
                        currentDropdownRow[0].value.trim() !== "Route" ||
                        currentDistanceRow[0].value.trim() !== "" ||
                        currentDropdownRow[1].value.trim() !== "Diversion" ||
                        currentDistanceRow[1].value.trim() !== ""
                    ) {
                        validMatrix.push(false);
                    }
                }
                currentRow = [];
                increment = 0;
            }
        });
        if (invalidInputs.length > 0) {
            console.log("true 2");
            validated = false;
        }
        if (validMatrix.includes(false)) {
            console.log("true 3 ");
            validated = false;
        }
        console.log(validated, "validated");
        return [validated, invalidInputs];
    };

    return (
        <motion.div>
            <UtilityClass.CustomText
                textSize="subtitle"
                fontWeight="semibold"
                color="black"
                content={"Routes and Distances"}
            />
            <UtilityClass.CustomText
                textSize="large"
                fontWeight="semibold"
                color="black"
                content={"11 to 20 Routes (Optional)"}
            />
            <div className="lg:flex justify-center w-full">
                <div className="flex justify-center lg:w-1/4 md:1/3 sm:full">
                    <UtilityClass.InputTable
                        autoComplete={true}
                        indexBool={true}
                        rowNum={10}
                        colNum={3}
                        checkboxSmallerBottomMarginBool={true}
                        checkboxLabelBottomMarginBool={true}
                        captionBool={true}
                        label="IATA Airport Codes"
                        placeholder={["From", "To", "Diversion"]}
                        showIndex={Array(10).fill(true)}
                        addIndex={[true, 11]}
                        value={setFirst}
                        regex={/^[a-zA-Z0-9\s]*$/}
                        toolTip={true}
                        toolTipDesc={"Find IATA codes by entering a city name."}
                        includeDefaults={defaultapCodes}
                        capitalize={true}
                        defaultVals={apCode}
                        width="full"
                    />
                </div>
                <div className="lg:flex lg:pl-10 align-center">
                    <UtilityClass.InputTable
                        indexBool={!windowLarge}
                        showIndex={Array(10).fill(true)}
                        checkboxSmallerBottomMarginBool={true}
                        rowNum={10}
                        colNum={2}
                        captionBool={true}
                        label="GCD+X%"
                        placeholder={["Route", "Diversion"]}
                        dropdown={true}
                        options={[
                            [
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                            ],
                            [
                                "0",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                            ],
                        ]}
                        internalTitle={["Route", "Diversion"]}
                        value={secondTable}
                        includeDefaults={defaultGCD}
                        defaultVals={gcdInputs}
                        toolTip={true}
                        toolTipDesc={
                            "The great-circle distance or orthodromic distance is the shortest distance between two points on the surface of a sphere, measured along the surface of the sphere."
                        }
                        width="auto"
                    />
                    <div className={`${!windowLarge ? "ml-24" : ""}`}>
                        <UtilityClass.InputTable
                            captionBool={true}
                            label={"OR"}
                        />
                    </div>
                    <UtilityClass.InputTable
                        indexBool={!windowLarge}
                        showIndex={Array(10).fill(true)}
                        checkboxSmallerBottomMarginBool={true}
                        rowNum={10}
                        colNum={2}
                        captionBool={true}
                        label={"Custom Distance"}
                        placeholder={["Route", "Diversion"]}
                        value={setThird}
                        includeDefaults={defaultDistance}
                        defaultVals={cDistance}
                        width="full"
                        regex={/^(?!0+$)\d*$/}
                    />
                </div>
            </div>
            <UtilityClass.BackNextButtons
                nextButtonText="NEXT"
                backLink={"/forms/" + packageType + "/RoutesAndDistances"}
                nextLink={"/forms/" + packageType + "/EnvironmentalConditions"}
                dispatchInputs={dispatchInputs}
                validation={validateContinue()}
                validationData
            />
        </motion.div>
    );
};

export default RoutesAndDistances2;
